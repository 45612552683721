import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import { FiChevronUp } from "react-icons/fi";
import { FiChevronDown } from "react-icons/fi";
import EditIcon from "../images/editicon.svg";
import Table from "react-bootstrap/Table";
import Icon1 from "../images/cardicon1.svg";
import Icon2 from "../images/cardicon2.svg";
import Icon3 from "../images/cardicon3.svg";
import IconPlaceholder from "../images/cardiconplaceholder.svg";
import CheckIicon from "../images/checkii.svg";
import LogoNew from "../images/logonew.png";
import LogoNew1 from "../images/logonew1.png";
import LogoNew2 from "../images/logonew2.png";
import * as API from "../../services/env";
import * as PIC from "../../services/env";
import moment from "moment";
import { logDOM } from "@testing-library/react";

import { useParams } from "react-router-dom";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ScreeningOverview = () => {
  const isPageReloadedRef = useRef(false);
  const { id } = useParams();

  const [ShipperData, setShipperData] = useState("");
  const [consineeData, setconsineeData] = useState("");

  const [EndUserData, setEndUserData] = useState("");

  const [Screeningdata, setScreeningdata] = useState([]);
  const [hscodeData, sethscodeData] = useState("");
  const [DangerousGoods, setDangerousGoods] = useState([]);

  const [screeningType, setscreeningType] = useState("");
  const [isPageReloaded, setIsPageReloaded] = useState(false);

  const getHsCodeSearch = async (req, res) => {
    const userData = localStorage.getItem("userDetail");
    const reference = localStorage.getItem("reference");
    const hscodeVerfication = localStorage.getItem("hsCodeVerficationData");
    const screeningIds = localStorage.getItem("screeningId");


    if (userData) {
      var data = JSON.parse(userData);
      var dataReference = JSON.parse(reference);
      var hsCodeverfication = JSON.parse(hscodeVerfication);
    const screeningIdss = JSON.parse(screeningIds);


      getHsCodesearchDetail(hsCodeverfication?  hsCodeverfication.screeningId:screeningIdss);

    }
  };

  const getHsCodesearchDetail = async (index) => {
    let payload = {
      ScreningId: id ? id : index,
    };

    await localStorage.setItem("screeningId", JSON.stringify(id != "2" && id != "1" ?id: index ));

    await fetch(`${API.BASE_URL}getScreenigTotalAALdeatil`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        ScreningId: id != "2" && id != "1" ?id: index ,
      }),
    })
      .then((response) => response.json())
      .then(async (res) => {

        if (res.status == true && index) {
          const endUserItems = [];
          res.data.forEach((item) => {
            if (item.userType == "End User") {
              endUserItems.push(item);
            }
          });

          const consigneeItems = [];
          res.data.forEach((item) => {
            if (item.userType == "Consignee") {
              consigneeItems.push(item);
            }
          });
          const shipperItem = [];
          res.data.forEach((item) => {
            if (item.userType == "Shipper") {
              shipperItem.push(item);
            }
          });
          sethscodeData(res.hazchemDetails[0].hscodeType);
          setScreeningdata(res.screening[0]);
          setDangerousGoods(res.hscodeData);

          // setShipperData(res.data[0].userType == "Shipper" ? res.data[0] : "");
          setShipperData(shipperItem[0]);

          // setconsineeData(
          //   res.data[1].userType == "Consignee" ? res.data[1] : ""
          // );
          setconsineeData(consigneeItems[0]);
          // setEndUserData(res.data[2].userType == "End User" ? res.data[2] : "");

          setEndUserData(endUserItems);
          setscreeningType(res.screening[0].screeningType);
          localStorage.setItem("screeningType", res.screening[0].screeningType);
          // sethscodetype(res.hscodeData?res.hscodeData.hscodeType:'')
        } else {
          setShipperData(res.data[0].userType == "Shipper" ? res.data[0] : "");
          setconsineeData(
            res.data[1].userType == "Consignee" ? res.data[1] : ""
          );
          // setEndUserData(res.data[2].userType == "End User" ? res.data[2] : "");
          const endUserItems = [];
          res.data.forEach((item) => {
            if (item.userType == "End User") {
              endUserItems.push(item);
            }
          });
          sethscodeData(res.hazchemDetails[0].hscodeType);
          setScreeningdata(res.screening[0]);
          setDangerousGoods(res.hscodeData);
          setscreeningType(res.screening[0].screeningType);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    localStorage.setItem("viewCount", JSON.stringify("0"));

    // getECCNcodesearch();
    getHsCodeSearch();
  }, []);


  useEffect(() => {
    localStorage.removeItem("PerShipmentShipper");
    localStorage.removeItem("PerShipmentConsignee");
    localStorage.removeItem("PerShipmentEndUser");
    // localStorage.removeItem("hsCodeVerficationData");
    localStorage.removeItem("Sanctions");
    localStorage.removeItem("BtachScreeningEnduser");
    localStorage.removeItem("BtachScreeningEnduserRiskData");
    localStorage.removeItem("BtachScreeningConsignee");

    localStorage.removeItem("BtachScreeningConsigneeRiskDatas");

    localStorage.removeItem("BtachScreeningShipper");

    localStorage.removeItem("BtachScreeningShipperRiskDatas");
    localStorage.removeItem("BtachScreeningShipperRiskData");

    localStorage.removeItem("BtachScreeningEndusernonRisk")
  });






  if (consineeData&&consineeData.repound && Array.isArray(consineeData.repound)) {
    var filteredConsigneeRepound = consineeData.repound.filter(
      (item) => item.ShipperName !== "" && item.risk !== "" && item. status !== "No"
    );
  } else {
    console.log("repound array not found or is not an array.");
  }





  if (ShipperData&&ShipperData.repound && Array.isArray(ShipperData.repound)) {
    var filteredShipperDataRepound = ShipperData.repound.filter(
      (item) => item.ShipperName !== "" && item.risk !== ""&& item. status !== "No"
    );
  } else {
    console.log("repound array not found or is not an array.");
  }
if(EndUserData.length>0){

var filteredEndUserRepound =[]


  if (EndUserData.length>0&&EndUserData[0].repound && Array.isArray(EndUserData[0].repound)) {
    var filteredEndUserRepound = EndUserData[0].repound.filter(
      (item) => item.ShipperName !== "" && item.risk !== ""&& item. status !== "No"
    );
  } else {
    console.log("repound array not found or is not an array.");
  }
}

  

  return (
    <>
      <Helmet bodyAttributes={{ style: "background-color : #efefef" }} />
      <section className="py-3 overview_sec">
        <Container fluid className="widthSmall">
          <Row className="mb-lg-3 mb-md-3 mb-3">
            <Col md={12}>
              <div className="requestFlex">
                <h2 className="scheading">Screening Overview</h2>

                <div className="mtt20">
                  <div className="ms-auto updownBox wmedbox mr-0">
                    <Link>
                      <FiChevronUp />
                    </Link>
                    <Link>
                      <FiChevronDown />
                    </Link>
                  </div>
                  {/* <Button
                    as={Link}
                    variant="warning"
                    size="md"
                    className="btnPad wmed"
                    to="/add-screening"
                  >
                    <Image src={PlusIcon} alt="" className="me-2" /> Add New
                    Screening
                  </Button> */}
                </div>
              </div>
            </Col>
          </Row>

          <Row className="mb-lg-5 mb-md-5 mb-4">
            <Col md={12} className="tablebox">
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th>Screening ID</th>
                    <th style={{ minWidth: "180px" }}>Screening Name</th>
                    {/* <th style={{ minWidth: "130px" }}>Description</th> */}
                    {/* <th>User</th> */}
                    <th style={{ minWidth: "120px" }}>Date</th>
                    {/* <th style={{ minWidth: "100px", width: "70px" }}>Action</th>  */}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-primary">
                      {Screeningdata && Screeningdata.screeningId}
                    </td>
                    <td>{Screeningdata && Screeningdata.referenceNo}</td>
                    {/* <td>{ShipperData&&ShipperData.name[0]||consineeData&&consineeData.name[0]||}</td> */}
                    {/* <td>{ShipperData&&ShipperData.name[0]}</td> */}
                    <td>
                      {Screeningdata &&
                        moment(Screeningdata.createdAt).format("ll")}
                    </td>
                    {/* <td>
                      <div className="d-flex justify-content-between">
                        <Link>
                          <Image src={EditIcon} />
                          <span className="ms-2">Edit</span>
                        </Link>
                      </div>
                    </td> */}
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>

          <Row>
            <Col md={12} className="tab_link">
              {/* {id != "2" && screeningType != "Batch Screening" ? (
                <Link to="/screeningoverview-RPS">RPS</Link>
              ) : (
                <></>
              )} */}
                <Link to="/screeningoverview-RPS">RPS</Link>


              <Link to="/screeningoverview-HSCodeVerification">
                HS Code Verification
              </Link>
              <Link to="/screeningoverview-HSCodeSanctions">
                HS Code Sanctions
              </Link>
              <Link to="/screeningoverview-DualUseGoods">Dual-Use Goods</Link>
              <Link to="/screeningoverview-DualUseExport">
                Re-export Country Control
              </Link>
              <Link to="/screeningoverview-DangerousGoods">
                Dangerous Goods
              </Link>
              <Link to="/screeningoverview-PrecursorChemicals">
                Precursor Chemicals
              </Link>
            </Col>
          </Row>

          <Row>
            {ShipperData ? (
              <Col lg={4} md={6}>
                <Card className="mb-3 mb-lg-4 mb-md-4 card_Block">
                  <Card.Header>RPS (Shipper)</Card.Header>
                  <Card.Body>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th></th>

                          <th style={{ width: "8.9em" }} align="right">
                            <span>No Risk</span>
                            <span>Alert</span>
                            <span>Risk</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {/* <td>
                            <span>
                              {ShipperData &&   ShipperData.userType === "Shipper"&&ShipperData.name[0].name? ShipperData.name[0].name :ShipperData.name }
                            </span>
                          </td> */}

                          {ShipperData &&
                          ShipperData.name.some((item) =>
                            item.hasOwnProperty("name")
                          ) ? (
                            <>
                              {(() => {
                                const nameCounts = {};
                                let commonNames = [];

                                // Count occurrences of each name
                                ShipperData.name.forEach((item) => {
                                  const name = item.name;
                                  nameCounts[name] =
                                    (nameCounts[name] || 0) + 1;
                                });

                                // Find the two most common names
                                Object.keys(nameCounts).forEach((name) => {
                                  if (nameCounts[name] > 1) {
                                    if (commonNames.length === 0) {
                                      commonNames.push(name);
                                    } else if (
                                      commonNames.length === 1 &&
                                      name !== commonNames[0]
                                    ) {
                                      commonNames.push(name);
                                    }
                                  }
                                });

                                // Render the common names
                                return (
                                  <>
                                    {commonNames.length > 0 ? (
                                      commonNames.map((name, index) => (
                                        <td key={index}>
                                          <span>{name}</span>
                                        </td>
                                      ))
                                    ) : (
                                      <td>
                                        <span>
                                          {ShipperData.name.map(
                                            (item, index) => (
                                              <>
                                              <span key={index}>
                                                {item.name}
                                              </span> <br />
                                              </>
                                            )
                                          )}
                                        </span>
                                      </td>
                                    )}
                                  </>
                                );
                              })()}
                            </>
                          ) : (
                            <>
                              {ShipperData &&
                                typeof ShipperData.name[0] == "string" && (
                                  <>{ShipperData ? ShipperData.name : <></>}</>
                                )}
                            </>
                          )}

                          {/* <td>
                            <span>
                              {ShipperData ? ShipperData.name : <></>}
                            </span>
                          </td> */}

                          <td style={{ width: "8.9em" }} align="right">


                          {filteredShipperDataRepound &&
                            filteredShipperDataRepound.length == 0 &&
                            ShipperData.userType === "Shipper" ? (
                              <Image src={Icon1} alt="" />
                            ) : (
                              <Image src={IconPlaceholder} alt="" />
                            )}

                            {filteredShipperDataRepound &&
                            filteredShipperDataRepound.length > 0 &&
                            ShipperData.userType === "Shipper" ? (
                              <Image src={IconPlaceholder} alt="" />
                            ) : (
                              <Image src={IconPlaceholder} alt="" />
                            )}

                            {filteredShipperDataRepound &&
                            filteredShipperDataRepound.length > 0 &&
                            ShipperData.userType === "Shipper" ? (
                              <Image src={Icon3} alt="" />
                            ) : (
                              <Image src={IconPlaceholder} alt="" />
                            )}
                            {/* {ShipperData &&
                              ShipperData.userType === "Shipper" &&
                              ShipperData.repound.length > 0 &&
                              ShipperData.repound[0].risk == "" &&
                              ShipperData.repound.length > 1 &&
                              ShipperData.repound[1].length > 1 && (
                                <Image src={IconPlaceholder} alt="" />
                              )}

                            {ShipperData &&
                              ShipperData.userType === "Shipper" &&
                              ShipperData.repound.length > 0 &&
                              ShipperData.repound[0].risk == "" && (
                                <Image src={Icon1} alt="" />
                              )}

                            <Image src={IconPlaceholder} alt="" />
                            {ShipperData &&
                            ShipperData.userType === "Shipper" &&
                            ShipperData.repound.length > 0 &&
                            (ShipperData.repound[0].risk !== "" ||
                              (ShipperData.repound.length > 1 &&
                                ShipperData.repound[1].risk !== "")) ? (
                              <Image src={Icon3} alt="" />
                            ) : (
                              <Image src={IconPlaceholder} alt="" />
                            )} */}
                          </td>

                          {/* {ShipperData &&
                              ShipperData.userType === "Shipper" &&
                              ShipperData.repound.length > 0 &&
                              (ShipperData.repound[0].risk !== "" ? (
                                <Image src={Icon3} alt="" />
                              ) : ShipperData.repound.length > 1 &&
                                ShipperData.repound[1].risk !== "" ? (
                                <Image src={Icon3} alt="" />
                              ) : (
                                <Image src={Icon1} alt="" />
                              ))}
                          </td> */}
                          {/* <td style={{ width: "8.9em" }} align="right">
  {ShipperData.repound[0].risk !== '' ? (
    <Image src={Icon1} alt="" />
  ) : ShipperData.repound[1].risk !== '' ? (
    <Image src={Icon3} alt="" />
  ) : (
    <Image src={IconPlaceholder} alt="" />
  )}
</td> */}
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            ) : (
              ""
            )}
            {consineeData ? (
              <Col lg={4} md={6}>
                <Card className="mb-3 mb-lg-4 mb-md-4 card_Block">
                  <Card.Header>RPS (Consignee)</Card.Header>
                  <Card.Body>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th></th>

                          <th style={{ width: "8.9em" }} align="right">
                            <span>No Risk</span>
                            <span>Alert</span>
                            <span>Risk</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {consineeData &&
                          consineeData.name.length > 0 &&
                          consineeData.name.some((item) =>
                            item.hasOwnProperty("name")
                          ) ? (
                            <>
                              {(() => {
                                const nameCounts = {};
                                const distinctNames = [];
                                const maxDistinctNames = 2;

                                // Count occurrences of each name and store distinct names
                                consineeData.name.forEach((item) => {
                                  const name = item.name;
                                  if (!nameCounts[name]) {
                                    nameCounts[name] = 1;
                                    distinctNames.push(name);
                                  } else {
                                    nameCounts[name]++;
                                  }
                                });

                                // Return at least two distinct names
                                const renderedNames = distinctNames.slice(
                                  0,
                                  maxDistinctNames
                                );

                                return (
                                  <>
                                    {renderedNames.length > 0 ? (
                                      renderedNames.map((name, index) => (
                                        <td key={index}>
                                          <span>{name}</span>
                                        </td>
                                      ))
                                    ) : (
                                      <td>
                                        <span>
                                          {consineeData.name.map(
                                            (item, index) => (
                                              <>
                                              <span key={index}>
                                                {item.name}
                                              </span> <br />
                                              </>
                                            )
                                          )}
                                        </span>
                                      </td>
                                    )}
                                  </>
                                );
                              })()}
                            </>
                          ) : (
                            <>
                              {consineeData &&
                                typeof consineeData.name[0] == "string" && (
                                  <>
                                    {consineeData ? consineeData.name : <></>}
                                  </>
                                )}
                            </>
                          )}

                          <td style={{ width: "8.9em" }} align="right">
                            {/* {consineeData &&
                              consineeData.userType === "Consignee" &&
                              consineeData.repound.length > 0 &&
                              (consineeData.repound[0].risk !== "" ? (
                                <Image src={Icon3} alt="" />
                              ) : consineeData.repound.length > 1 &&
                                consineeData.repound[1].risk !== "" ? (
                                <Image src={Icon3} alt="" />
                              ) : (
                                <Image src={Icon1} alt="" />
                              ))} */}

                            {filteredConsigneeRepound &&
                            filteredConsigneeRepound.length == 0 &&
                            consineeData.userType === "Consignee" ? (
                              <Image src={Icon1} alt="" />
                            ) : (
                              <Image src={IconPlaceholder} alt="" />
                            )}

                            {filteredConsigneeRepound &&
                            filteredConsigneeRepound.length > 0 &&
                            consineeData.userType === "Consignee" ? (
                              <Image src={IconPlaceholder} alt="" />
                            ) : (
                              <Image src={IconPlaceholder} alt="" />
                            )}

                            {filteredConsigneeRepound &&
                            filteredConsigneeRepound.length > 0 &&
                            consineeData.userType === "Consignee" ? (
                              <Image src={Icon3} alt="" />
                            ) : (
                              <Image src={IconPlaceholder} alt="" />
                            )}

                            {/* 

                            {consineeData &&
                              consineeData.userType === "Consignee" &&
                              consineeData.repound.length > 0 &&
                              consineeData.repound[0].risk == "" &&
                              consineeData.repound.length > 1 &&
                              consineeData.repound[1].length > 1 && (
                                <Image src={Icon1} alt="" />
                              )}
                            {consineeData &&
                              consineeData.userType === "Consignee" &&
                              consineeData.repound.length > 0 &&
                              consineeData.repound[0].risk == "" && (
                                <Image src={Icon1} alt="" />
                              )}


                            <Image src={IconPlaceholder} alt="" />
                            {consineeData &&
                            consineeData.userType === "Consignee" &&
                            consineeData.repound.length > 0 &&
                            (consineeData.repound[0].risk !== "" ||
                              (consineeData.repound.length > 1 &&
                                consineeData.repound[1].risk !== "")) ? (
                              <Image src={Icon3} alt="" />
                            ) : (
                              <Image src={IconPlaceholder} alt="" />
                            )} */}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            ) : (
              ""
            )}

            {EndUserData.length > 0 ? (
              <Col lg={4} md={6}>
                <Card className="mb-3 mb-lg-4 mb-md-4 card_Block">
                  <Card.Header>RPS (End User)</Card.Header>
                  <Card.Body>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th></th>

                          <th style={{ width: "8.9em" }} align="right">
                            <span>No Risk</span>
                            <span>Alert</span>
                            <span>Risk</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          {EndUserData[0] &&
                          EndUserData[0].name.length > 0 &&
                          EndUserData[0].name.some((item) =>
                            item.hasOwnProperty("name")
                          ) ? (
                            <>
                              {(() => {
                                const nameCounts = {};
                                const distinctNames = [];
                                const maxDistinctNames = 2; // Change this value to return more distinct names if needed

                                // Count occurrences of each name and store distinct names
                                EndUserData[0].name.forEach((item) => {
                                  const name = item.name;
                                  if (!nameCounts[name]) {
                                    nameCounts[name] = 1;
                                    distinctNames.push(name);
                                  } else {
                                    nameCounts[name]++;
                                  }
                                });

                                // Return at least two distinct names
                                const renderedNames = distinctNames.slice(
                                  0,
                                  maxDistinctNames
                                );

                                return (
                                  <>
                                    {renderedNames.length > 0 ? (
                                      renderedNames.map((name, index) => (
                                        <td key={index}>
                                          <span>{name}</span>
                                        </td>
                                      ))
                                    ) : (
                                      <td>
                                        <span>
                                          {EndUserData.name.map(
                                            (item, index) => (
                                              <>
                                              <span key={index}>
                                                {item.name}
                                              </span> <br />
                                              </>
                                            )
                                          )}
                                        </span>
                                      </td>
                                    )}
                                  </>
                                );
                              })()}
                            </>
                          ) : (
                            <>
                              {EndUserData &&
                                typeof EndUserData[0].name[0] == "string" && (
                                  <>
                                    {EndUserData[0] ? (
                                      EndUserData[0].name
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                )}
                            </>
                          )}

                          <td style={{ width: "8.9em" }} align="right">




                          <td style={{ width: "8.9em" }} align="right">
                          {filteredEndUserRepound &&
                            filteredEndUserRepound.length == 0 &&
                            EndUserData.length>0&&     EndUserData[0].userType === "End User" ? (
                              <Image src={Icon1} alt="" />
                            ) : (
                              <Image src={IconPlaceholder} alt="" />
                            )}

                            {filteredEndUserRepound &&
                            filteredEndUserRepound.length > 0 &&
                            EndUserData.length>0&&      EndUserData[0].userType === "End User" ? (
                              <Image src={IconPlaceholder} alt="" />
                            ) : (
                              <Image src={IconPlaceholder} alt="" />
                            )}

                            {filteredEndUserRepound &&
                            filteredEndUserRepound.length > 0 &&
                            EndUserData.length>0&&    EndUserData[0].userType === "End User" ? (
                              <Image src={Icon3} alt="" />
                            ) : (
                              <Image src={IconPlaceholder} alt="" />
                            )}
                          </td>
                            {/* {EndUserData &&
                            EndUserData.repound &&
                            EndUserData.repound.length > 0 ? (
                              EndUserData.repound.map((item, index) =>
                                item.risk !== "" ? (
                                  <Image key={index} src={Icon1} alt="" />
                                ) : (
                                  <Image key={index} src={Icon3} alt="" />
                                )
                              )
                            ) : (
                              <Image src={IconPlaceholder} alt="" />
                            )} */}

                            
                          </td>
                          {/* <td style={{ width: "8.9em" }} align="right">
                            {EndUserData&&EndUserData.repound[0]?.risk !== "" ? (
                              <Image src={Icon1} alt="" />
                            ) : EndUserData&&EndUserData.repound[1]?.risk !== "" ? (
                              <Image src={Icon3} alt="" />
                            ) : (
                              <Image src={IconPlaceholder} alt="" />
                            )}
                          </td> */}
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            ) : (
              ""
            )}

            <Col lg={4} md={4}>
              <Card className="mb-3 card_Block">
                <Card.Header>HS Code Sanctions</Card.Header>
                <Card.Body>
                  <Table responsive className="cont_table">
                    <thead>
                      <tr>
                        <th style={{ minWidth: "7em" }}></th>
                        <th style={{ minWidth: "7em" }}></th>
                        <th style={{ width: "9em" }} align="right">
                          <span>No Risk</span>
                          <span>Alert</span>
                          <span>Risk</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {hscodeData
                        ? hscodeData.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td style={{ minWidth: "7em" }}>
                                  {/* <span>JKLU654LP</span> */}
                                </td>
                                <td>
                                  <strong>{item.hsCode}</strong>
                                </td>
                                <td style={{ minWidth: "7em" }} align="right">
                                { item.status === "no" ? (
                              <Image src={Icon1} alt="" />
                            ) : (
                              <Image src={IconPlaceholder} alt="" />
                            )}

                            {item.status === "dfsdf"  ? (
                              <Image src={IconPlaceholder} alt="" />
                            ) : (
                              <Image src={IconPlaceholder} alt="" />
                            )}

                            {item.status === "yes"  ? (
                              <Image src={Icon3} alt="" />
                            ) : (
                              <Image src={IconPlaceholder} alt="" />
                            )}
                                  {/* <Image src={IconPlaceholder} alt="" />
                                  <Image src={IconPlaceholder} alt="" />
                                  <Image src={Icon3} alt="" /> */}
                                </td>
                              </tr>
                            );
                          })
                        : ""}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>

            <Col lg={6} md={6}>
              <Card className="mb-3 card_Block card_BlockBordeColor">
                <Card.Header>Dangerous Goods</Card.Header>
                <Card.Body>
                  <Table responsive className="cont_table1">
                    <thead>
                      <tr>
                        <th style={{ width: "11em" }}>Goods</th>
                        <th className="text-center">
                          Chemical Or Dangerous Good
                        </th>
                        <th className="text-center">
                          Dual Use Export Controlled Goods
                        </th>

                        <th className="text-center">
                          Hazchem
                          <br /> Class Label
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {DangerousGoods.map((item, index) => {
                        return item.hscodeData.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td align="center">
                                <span className="headingText">
                                  {item.goodDescription}
                                </span>
                              </td>

                              <td align="center">
                                <span className="borderRight">
                                  <span className="headingText">
                                    {item.chemicalDangerios}
                                  </span>
                                </span>
                              </td>
                              <td align="center">
                                <span className="borderRight">
                                  <span className="headingText">
                                    {item.DualUseExport}
                                  </span>
                                </span>
                              </td>
                              {/* <td className="text-center">
                                <span className="borderRight">
                                  <Image src={CheckIicon} alt="" />
                                </span>
                              </td>
                              <td className="text-center">
                                <span className="borderRightNew">
                                  <Image src={CheckIicon} alt="" />
                                </span>
                              </td> */}
                              <td className="text-center">
                                <Image
                                  src={`${PIC.PIC_URL}${
                                    item.badgeData && item.badgeData.BadgePic
                                  }`}
                                  alt=""
                                />
                              </td>
                            </tr>
                          );
                        });
                      })}

                      {/* <tr>
                        <td>
                          <span className="headingText">Hands Free 778 TY</span>
                        </td>
                        <td className="text-center">
                          <span className="borderRight">
                            <Image src={CheckIicon} alt="" />
                          </span>
                        </td>
                        <td className="text-center">
                          <span className="borderRightNew">
                            <Image src={CheckIicon} alt="" />
                          </span>
                        </td>

                        <td className="text-center">
                          <Image src={LogoNew1} alt="" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="headingText">Hands Free 778 TY</span>
                        </td>
                        <td className="text-center">
                          <span className="borderRight">
                            <Image src={CheckIicon} alt="" />
                          </span>
                        </td>
                        <td className="text-center">
                          <span className="borderRightNew">
                            <Image src={CheckIicon} alt="" />
                          </span>
                        </td>

                        <td className="text-center">
                          <Image src={LogoNew2} alt="" />
                        </td>
                      </tr> */}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>

            <Col lg={6} md={6}>
              <Card className="mb-3 card_Block card_BlockBordeColor card_BlockBordeColor1">
                <Card.Header>Dual-Use Re-Export & C.I.</Card.Header>
                <Card.Body>
                  <Table responsive className="cont_table1">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Goods</strong>
                        </td>
                        <td className="text-center">
                          <strong>ECCN or EU DUXXX#</strong>
                        </td>
                        <td className="text-center">
                          <strong>
                            No Licence
                            <br />
                            Required??
                          </strong>
                        </td>
                      </tr>

                      {DangerousGoods.map((item, index) => {
                        return item.hscodeData.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.goodDescription}</td>
                              <td className="text-center">
                                <span className="borderRight">{item.EccN}</span>
                              </td>
                              {item.licines === "Yes" ? (
      <FontAwesomeIcon icon={faCheck} style={{ color: "green", marginLeft: 5 }} />
    ) : item.licines === "No" ? (
      <FontAwesomeIcon icon={faTimes} style={{ color: "red", marginLeft: 5 }} />
    ) : null}
                              {/* <td className="text-center">{item.licines}</td> */}
                            </tr>
                          );
                        });
                      })}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ScreeningOverview;
