import React, { useState, useEffect } from "react";

import { Col, Container, Row, Card, Form, Table, Image } from "react-bootstrap";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { Icon } from "@iconify/react";
import RightIcon from "../images/crossii.svg";
import CorssIconBlank from "../images/crossblank.svg";

import * as API from "../../services/env";

const ScreeningOverviewPrecursorChemicals = () => {


  const [ShipperData, setShipperData] = useState('')
  const [consineeData, setconsineeData] = useState('')

  const [EndUserData, setEndUserData] = useState('')

  const [hazchemDetails, sethazchemDetails] = useState([])
  const [hscodeData, sethscodeData] = useState('')
  const [DangerousGoods, setDangerousGoods] = useState([])
  const getHsCodeSearch = async (req, res) => {

    const userData = localStorage.getItem("userDetail");
    const reference = localStorage.getItem("reference");
    const hscodeVerfication = localStorage.getItem("hsCodeVerficationData");
    var screeningType = localStorage.getItem("screeningType");

    sethazchemDetails(screeningType)

    const screeningId = localStorage.getItem("screeningId");
    if (userData) {
      var data = JSON.parse(userData);
      var dataReference = JSON.parse(reference);
      var hsCodeverfication = JSON.parse(hscodeVerfication);
      var screeningIds = JSON.parse(screeningId);

      getHsCodesearchDetail(screeningIds);
    }
  
    // if (userData) {
    //   var data = JSON.parse(userData);
    //   var dataReference = JSON.parse(reference);
    //   var hsCodeverfication = JSON.parse(hscodeVerfication);



    //   getHsCodesearchDetail(hsCodeverfication&&hsCodeverfication.screeningId)
    // }


  }


  const getHsCodesearchDetail = async (index) => {

    await fetch(`${API.BASE_URL}getScreenigTotalAALdeatil`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        ScreningId: index
      }),
    })
      .then((response) => response.json())
      .then(async (res) => {
        if (res.status == true) {

          sethscodeData(res.hazchemDetails[0].hscodeType
          )
          setDangerousGoods(res.hazchemDetails)
          setShipperData(res.data[0].userType == 'Shipper' ? res.data[0] : "")
          setconsineeData(res.data[1].userType == 'Consignee' ? res.data[1] : "")
          setEndUserData(res.data[2].userType == 'End User' ? res.data[2] : "")

          // sethscodetype(res.hscodeData?res.hscodeData.hscodeType:'')
        }
      })
      .catch((error) => { });
  };




  useEffect(() => {
    // getECCNcodesearch();
    getHsCodeSearch()

  }, [

  ]);






  return (
    <>
      <Helmet bodyAttributes={{ style: "background-color : #efefef" }} />
      <section className="py-3 overview_sec">
        <Container fluid className="widthSmall">
          <Row className="mb-lg-4 mb-md-4 mb-3">
            <Col md={12}>
              <div className="requestFlex">
                <h2 className="scheading">
                  Screening Overview : Precursor Chemicals
                </h2>

                <Button
                  as={Link}
                  variant="warning"
                  size="md"
                  className="btnBack"
                  to={`/screening-overview/1`}
                >
                  <Icon icon="carbon:task-view" fontSize={18} />
                  Back to Screening Overview
                </Button>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={12} className="tab_link">
            {/* {hazchemDetails!='Batch Screening'?
                   <Link to="/screeningoverview-RPS">RPS</Link>
              
              :<>
         
              
              </>} */}
                   <Link to="/screeningoverview-RPS">RPS</Link>

              <Link to="/screeningoverview-HSCodeVerification">
                HS Code Verification
              </Link>
              <Link to="/screeningoverview-HSCodeSanctions">
                HS Code Sanctions
              </Link>
              <Link to="/screeningoverview-DualUseGoods">Dual-Use Goods</Link>
              <Link to="/screeningoverview-DualUseExport">
                Re-export Country Control
              </Link>
              <Link to="/screeningoverview-DangerousGoods">
                Dangerous Goods
              </Link>
              <Link
                className="active"
                to="/screeningoverview-PrecursorChemicals"
              >
                Precursor Chemicals
              </Link>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Card className="mb-3 mb-lg-4 mb-md-4 card-boxx">
                <Card.Body>
                  <Table responsive className="table_Box">
                    <thead>
                      <tr>
                        <th>Goods Description</th>
                        <th>HS code or Taric Code (EU)</th>
                        <th>Chemical Or Dangerous Good</th>

                        <th>Dual Use Export Controlled Goods?</th>


                        <th>
                          is Precursor
                          <br /> Chemicals?
                        </th>
                      </tr>
                    </thead>
                    <tbody>

                      {hscodeData.length>0&&hscodeData.map((item,index)=>{
                        return(<tr key={index}>
                          <td>
                            <Form.Group>
                              <Form.Control
                                type={"text"}
                                placeholder={"Enter Goods Description"}
                                value={item.goodDescription  }
                              />
                            </Form.Group>
                          </td>
                          <td>
                            <Form.Group>
                              <Form.Control
                                type={"text"}
                                placeholder={"Enter HS code or Taric Code (EU) "}
                                value={item.EccN  }

                              />
                            </Form.Group>
                          </td>

                          <td>
                            <Form.Group>
                              <Form.Control
                                type={"text"}
                                placeholder={"Enter HS code or Taric Code (EU) "}
                                value={item.chemicalDangerios  }

                              />
                            </Form.Group>
                          </td>
                          <td>
                            <Form.Group>
                              <Form.Control
                                type={"text"}
                                placeholder={"Enter HS code or Taric Code (EU) "}
                                value={item.DualUseExport  }

                              />
                            </Form.Group>
                          </td>
  
                          <td>
                            <Form.Group>
                              <Form.Control
                                type={"text"}
                                placeholder={"Enter Precursor Chemical "}
                                value={item.Precursor  }

                              />
                            </Form.Group>
                          </td>
                        </tr>)
                      })}
                      
                      {/* <tr>
                        <td>
                          <Form.Group>
                            <Form.Control
                              type={"text"}
                              placeholder={"Enter Goods Description"}
                            />
                          </Form.Group>
                        </td>
                        <td>
                          <Form.Group>
                            <Form.Control
                              type={"text"}
                              placeholder={"Enter HS code or Taric Code (EU) "}
                            />
                          </Form.Group>
                        </td>

                        <td>
                          <div className="crBox">
                            <Image src={RightIcon} alt="" />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Form.Group>
                            <Form.Control
                              type={"text"}
                              placeholder={"Enter Goods Description"}
                            />
                          </Form.Group>
                        </td>
                        <td>
                          <Form.Group>
                            <Form.Control
                              type={"text"}
                              placeholder={"Enter HS code or Taric Code (EU) "}
                            />
                          </Form.Group>
                        </td>

                        <td>
                          <div className="crBox">
                            <Image src={RightIcon} alt="" />
                          </div>
                        </td>
                      </tr> */}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ScreeningOverviewPrecursorChemicals;
