import React, { useState, useEffect, useRef } from "react";
import {
  Accordion,
  Col,
  Container,
  Row,
  Button,
  Card,
  Table,
  Image,
  Tooltip,
  OverlayTrigger,
  Toast,
  Alert,
} from "react-bootstrap";
import Helmet from "react-helmet";
// import the progress bar
import StepProgressBar from "react-step-progress";
// import the stylesheet
import "react-step-progress/dist/index.css";
import CustomForm from "../customcomponents/CustomForm";
import CustomFileUpload from "../customcomponents/CustomFileUpload";
import CustomFormIcon from "../customcomponents/CustomFormIcon";
import { Form } from "react-bootstrap";
import { Icon } from "@iconify/react";
import CustomFormWithToolTip from "../customcomponents/CustomFormWithToolTip";
import { Link, useNavigate } from "react-router-dom";
import RightIcon from "../images/crossii.svg";
import CorssIconRed from "../images/crossii1.svg";
import CorssIconBlank from "../images/crossblank.svg";
import LogoNew from "../images/logonew.png";
import LogoNew1 from "../images/logonew1.png";
import LogoNew2 from "../images/logonew2.png";
import * as SERVICE from "../../services/index";
import * as API from "../../services/env";
import * as PIC from "../../services/env";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
// import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import debounce from "lodash/debounce";
import { Oval } from "react-loader-spinner";
import { logDOM } from "@testing-library/react";

var countryCodeData = [];

const screeningCheck = {
  hitOne: "hitOne",
  hitSec: "hitSec",
};

const consgineescreeningCheck = {
  hitOne: "hitOne",
  hitSec: "hitSec",
};

const EndUserscreeningCheck = {
  hitOne: "hitOne",
  hitSec: "hitSec",
};

const AddScreening = () => {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      This item cannot be shipped to the country indicated
    </Tooltip>
  );

  // setup the step content
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);

  function onFormSubmit() {
    navigate("/screening-overview/1");
  }

  const [apiResponse, setApiResponse] = useState(false);

  const handleApiResponse = (response) => {
    // Assuming your API response updates the state when it returns true
    setApiResponse(response);
  };

  const handleNext = () => {
    // Logic to handle the "Next" button click event
    // For example, you might want to advance to the next step in your progress bar
  };

  const [RPSScreening, setRPSScreening] = useState(false);
  const [RPSScreeningConsignee, setRPSScreeningConsignee] = useState(false);

  const [RPSScreeningenduser, setRPSScreeningenduser] = useState(false);

  const [HSCodeVerification, setHSCodeVerification] = useState(false);
  const [HSCCodeSanctions, setCodeSanctions] = useState(false);
  const [DualUseGoods, setDualUseGoods] = useState(false);
  const [DangerousGoods, setDangerousGoods] = useState(false);

  // const [RPSScreening,setRPSScreening] = useState(false)

  const RPSScreeningRef = useRef(
    (RPSScreening == true && RPSScreening) ||
      (RPSScreeningConsignee == true && RPSScreeningConsignee) ||
      (RPSScreeningenduser == true && RPSScreeningenduser)
  );
  const HSCodeVerificationRef = useRef(HSCodeVerification);
  const HSCCodeSanctionsRef = useRef(HSCCodeSanctions);
  const DualUseGoodsRef = useRef(DualUseGoods);
  const DangerousGoodsRef = useRef(DangerousGoods);

  const Step1Content = () => {
    const [consigneeScreening, setconsigneeScreening] = useState(false);
    const [screening, setScreening] = React.useState(false);

    const [EndUserScreening, setEndUserScreening] = useState(false);

    const [status, setStatus] = React.useState(0);
    const [consigneestatus, setconsigneeStatus] = React.useState(0);

    const [EndUserstatus, setEndUserStatus] = React.useState(0);
    const [referenceErr, setreferenceErr] = useState("");
    const [reference, setreference] = useState("");
    const [Shipperreference, setrShipperreference] = useState("");

    const [screeningType1, setscreeningType1] = useState(
      "Per Shipment Screening"
    );

    const [screeningTypeErr, setscreeningTypeErr] = useState("");

    const [userType, setUserType] = useState("");

    const [ConsigneeUserType, setConsigneeUserType] = useState("");

    const [ShipperUserType, setShipperUserType] = useState("");

    const [userTypeerr, setUserTypeerr] = useState("");

    const [Submit, setSubmit] = useState(false);
    const [errorCount, seterrCount] = useState(0);
    const [ShipperName, setshipperName] = useState("");
    const [shipperNameerr, setshipperNameerr] = useState("");
    const [risk, setrisk] = useState([]);
    const [ShipperDoB, setShipperDoB] = useState("");
    const [ShipperDoBerr, setShipperDoBerr] = useState("");

    const [ShipperFile, setShipperFile] = useState("");
    const [ShipperFileerr, setShipperFileerr] = useState("");

    const [shareholder_owns, setshareholder_owns] = useState("");
    const [shareholder_ownserr, setshareholder_ownserr] = useState("");

    const [Shipper_Owner, setShipper_Owner] = useState("");
    const [Shipper_Ownererr, setShipper_Ownererr] = useState("");

    const [Shipper_Owners_Address, setShipper_Owners_Address] = useState("");
    const [Shipper_Owners_Addresserr, setShipper_Owners_Addresserr] =
      useState("");

    const [BankCheck, setBankCheck] = useState("No");
    const [BankCheckerr, setBankCheckerr] = useState("");

    const [BankName, setBankName] = useState("");
    const [BankNameerr, setBankNameerr] = useState("");

    const [BankAdress, setBankAdress] = useState("");
    const [BankAdresserr, setBankAdresserr] = useState("");

    const [selectedFile, setSelectedFile] = useState(null);

    const [apiResponse, setapiResponse] = useState("0");
    const [apiResponse1, setapiResponse1] = useState("0");

    const [statusChange, setstatusChange] = useState("0");

    const [setBox, setSetbox] = useState("0");
    const [ShipperUserTypse, setShipperUserTypse] = useState("");
    const [loader, setloader] = useState(false);
    const [shipperloader, setshipperloader] = useState(false);

    const [consigneeloader, setconsigneeloader] = useState(false);

    const [EndUserloaders, setEndUserloaders] = useState(false);

    const [endUserIsDifferent, setEndUserIsDifferent] = useState(false);

    const [ConsigneeSubmit, setConsigneeSubmit] = useState(false);

    const [ConsigneescreeningType1, setsConsigneescreeningType1] = useState(
      "Per Shipment Screening"
    );

    const [ConsigneescreeningTypeErr, setConsigneescreeningTypeErr] =
      useState("");

    // const [ConsigneeuserType, setConsigneeUserTypes] = useState("");
    const [ConsigneeuserTypeerr, setConsigneeUserTypeerr] = useState("");

    const [ConsigneeerrorCount, setConsigneeerrCount] = useState(0);

    const [ConsigneeshipperSubmitError, setConsigneeshipperSubmitError] =
      useState(false);
    const [Consigneerisk, setConsigneerisk] = useState([]);
    const [ConsigneeShipperDoB, setConsigneeShipperDoB] = useState("");
    const [ConsigneeShipperDoBerr, setConsigneeShipperDoBerr] = useState("");

    const [ConsigneeShipperFile, setConsigneeShipperFile] = useState("");
    const [ConsigneeShipperFileerr, setConsigneeShipperFileerr] = useState("");

    const [Consigneeshareholder_owns, setConsigneeshareholder_owns] =
      useState("");
    const [Consigneeshareholder_ownserr, setConsigneeshareholder_ownserr] =
      useState("");

    const [ConsigneeShipper_Owner, setConsigneeShipper_Owner] = useState("");
    const [ConsigneeShipper_Ownererr, setConsigneeShipper_Ownererr] =
      useState("");

    const [
      ConsigneeShipper_Owners_Address,
      setConsigneeShipper_Owners_Address,
    ] = useState("");
    const [
      ConsigneeShipper_Owners_Addresserr,
      setConsigneeShipper_Owners_Addresserr,
    ] = useState("");
    const [ConsigneesetBox, setConsigneeSetbox] = useState("0");

    const [ConsigneeBankCheck, setConsigneeBankCheck] = useState("No");
    const [ConsigneeBankCheckerr, setConsigneeBankCheckerr] = useState("");

    const [ConsigneeBankName, setConsigneeBankName] = useState("");
    const [ConsigneeBankNameerr, setConsigneeBankNameerr] = useState("");

    const [ConsigneeBankAdress, setConsigneeBankAdress] = useState("");
    const [ConsigneeBankAdresserr, setConsigneeBankAdresserr] = useState("");

    const [ConsigneeepounRefernceNumber, setrConsigneeepounRefernceNumber] =
      useState("");

    const [ConsigneeapiResponse, setConsigneeapiResponse] = useState("0");
    const [ConsigneeapiResponse1, setConsigneeapiResponse1] = useState("0");

    const [Consigneename, setConsigneeShipper_Name] = useState("");
    const [ConsigneeShipperNameerr, setConsigneeShipperNameerr] = useState([
      "",
    ]);

    const [Consigneeloader, setConsigneeloader] = useState(false);

    const [Consigneeriskststus, setConsigneeriskststus] = useState("0");
    const [ConsigneeshowRiskFeild, setConsigneeshowRiskFeild] = useState("0");

    const [EndUserSubmit, setEndUserSubmit] = useState(false);

    const [EndUserscreeningType1, setsEndUserscreeningType1] = useState(
      "Per Shipment Screening"
    );

    const [EndUserscreeningTypeErr, setEndUserscreeningTypeErr] = useState("");

    const [EndUseruserType, setEndUserUserType] = useState("");
    const [EndUserserTypeerr, setEndUserUserTypeerr] = useState("");

    const [EndUserserTypeRefernce, setEndUserserTypeRefernce] = useState("");

    const [EndUserrrorCount, setEndUsererrCount] = useState(0);

    const [EndUsershipperSubmitError, setEndUsershipperSubmitError] =
      useState(false);
    const [EndUserrisk, setEndUserrisk] = useState([]);
    const [EndUserShipperDoB, setEndUserShipperDoB] = useState("");
    const [EndUserShipperDoBerr, setEndUserShipperDoBerr] = useState("");

    const [EndUserShipperFile, setEndUserShipperFile] = useState("");
    const [EndUserShipperFileerr, setEndUserShipperFileerr] = useState("");

    const [EndUsershareholder_owns, setEndUsershareholder_owns] = useState("");
    const [EndUsershareholder_ownserr, setEndUsershareholder_ownserr] =
      useState("");

    const [EndUserShipper_Owner, setEndUserShipper_Owner] = useState("");
    const [EndUserShipper_Ownererr, setEndUserShipper_Ownererr] = useState("");

    const [EndUserShipper_Owners_Address, setEndUserShipper_Owners_Address] =
      useState("");
    const [
      EndUserShipper_Owners_Addresserr,
      setEndUserShipper_Owners_Addresserr,
    ] = useState("");
    const [EndUsersetBox, setEndUserSetbox] = useState("0");

    const [EndUserBankCheck, setEndUserBankCheck] = useState("No");
    const [EndUserBankCheckerr, setEndUserBankCheckerr] = useState("");

    const [EndUserBankName, setEndUserBankName] = useState("");
    const [EndUserBankNameerr, setEndUserBankNameerr] = useState("");

    const [EndUserBankAdress, setEndUserBankAdress] = useState("");
    const [EndUserBankAdresserr, setEndUserBankAdresserr] = useState("");

    const [CommonuserType, setCommonuserType] = useState("");

    const [EndUserapiResponse, setEndUserapiResponse] = useState("0");
    const [EndUserapiResponse1, setEndUserapiResponse1] = useState("0");

    const [EndUsername, setEndUserShipper_Name] = useState("");
    const [EndUsershipperNameerr, setEndUsershipperNameerr] = useState([""]);

    const [EndUserloader, setEndUserloader] = useState(false);

    const [getSearchApi, setgetSearchApi] = useState([]);
    const [usergetSearchApi, setusergetSearchApi] = useState([]);

    const [consigneegetSearchApi, setconsigneegetSearchApi] = useState([]);
    const [cendUsergetSearchApi, setcendUsergetSearchApi] = useState([]);

    const [showNameModal, setShowNameModal] = useState(false);

    const [showNameModal1, setShowNameModal1] = useState(false);

    const [showNameModal2, setShowNameModal2] = useState(false);

    const [FirstScreeningrespone, setFirstScreeningrespone] = useState("");
    const [consigneeFirstScreeningrespone, setconsigneeFirstScreeningrespone] =
      useState("");

    const [EndUserFirstScreeningrespone, setEndUserFirstScreeningrespone] =
      useState("");

    const openAllUserType = () => {
      // setRPSScreening(false)
      // setRPSScreeningConsignee(false)
      // setRPSScreeningenduser(false)

      setEndUserSubmit(false);
      setEndUserSetbox("0");
      setEndUsershipperNameerr("");
      setEndUserapiResponse("0");

      setEndUserUserType("End User");
      setShipperUserType("End User");

      setConsigneeSubmit(false);

      setConsigneeSetbox("0");
      setConsigneeShipperNameerr("");
      setConsigneeapiResponse("0");
      // setUserType(event.target.value);

      setConsigneeUserType("Consignee");

      setSubmit(false);

      setUserType("Shipper");
      setSetbox("0");
      setshipperNameerr("");
      setapiResponse("0");
      setShipperUserTypse("Shipper");
    };

    const handleAddcolumn = () => {
      setRPSScreening(false);
      setRPSScreeningConsignee(false);
      setRPSScreeningenduser(false);

      if (CommonuserType == "Select") {
        setCommonuserType("");
      }

      if (CommonuserType == "End User") {
        setEndUserSubmit(false);
        setEndUserSetbox("0");
        setEndUsershipperNameerr("");
        setEndUserapiResponse("0");

        setEndUserUserType(CommonuserType);
        setShipperUserType(CommonuserType);
      }

      if (CommonuserType == "Consignee") {
        setConsigneeSubmit(false);

        setConsigneeSetbox("0");
        setConsigneeShipperNameerr("");
        setConsigneeapiResponse("0");
        // setUserType(event.target.value);

        setConsigneeUserType(CommonuserType);
      }
      if (CommonuserType == "Shipper") {
        setSubmit(false);

        setUserType(CommonuserType);
        setSetbox("0");
        setshipperNameerr("");
        setapiResponse("0");
        setShipperUserTypse(CommonuserType);
      }
    };

    const handleSwitchToggle = () => {
      setEndUserIsDifferent((prevValue) => !prevValue);
    };

    const handleOwnershipChange = (event) => {
      setshareholder_owns(event.target.value);
    };

    const consgoineehandleOwnershipChange = (event) => {
      setConsigneeshareholder_owns(event.target.value);
    };

    const EndUserhandleOwnershipChange = (event) => {
      setEndUsershareholder_owns(event.target.value);
    };

    const hadleSelectedtName = (item) => {
      setshipperName(item);
      setShowNameModal(false);
    };

    const consigneehadleSelectedtName = (item) => {
      setConsigneeShipper_Name(item);
      setShowNameModal1(false);
    };

    const userhadleSelectedtName = (item) => {
      setShowNameModal2(false);
      setEndUserShipper_Name(item);
    };

    const handleSelectChange = (event) => {
      if (event.target.value == "Select") {
        setCommonuserType("");
      } else {
        setCommonuserType(event.target.value);
      }
    };
    const radioHandler = (status) => {
      if (status == "1") {
        setBankCheck("yes");
        setapiResponse1("1");
      }
      if (status == "0") {
        setBankCheck("No");
      }
      setStatus(status);
    };

    const consgineeradioHandler = (status) => {
      if (status == "1") {
        setConsigneeBankCheck("yes");
        setConsigneeapiResponse1("1");
      }
      if (status == "0") {
        setConsigneeBankCheck("No");
      }
      setconsigneeStatus(status);
    };

    const EndUserradioHandler = (status) => {
      if (status == "1") {
        setEndUserBankCheck("yes");
        setEndUserapiResponse1("1");
      }
      if (status == "0") {
        setEndUserBankCheck("No");
      }
      setEndUserStatus(status);
    };

    const history = useNavigate();

    const handleRadioChange = (event) => {
      const selectedValue = event.target.value;
      setscreeningType1(
        selectedValue == "batch-screening"
          ? "Batch Screening"
          : "Per Shipment Screening"
      );
      // Navigate to the corresponding page based on the selected radio button
      switch (selectedValue) {
        case "add-screening":
          history("/add-screening");
          break;
        case "batch-screening":
          history("/batch-screening");
          break;
        // Add more cases for additional pages if needed
        default:
          break;
      }
    };

    const onProfileValidation = async () => {
      let errorCount = 0;
      if (SERVICE.hasError(ShipperName)) {
        errorCount++;
        let msg = "Please enter your name.";
        setshipperNameerr(msg);
      } else {
        setshipperNameerr(null);
      }

      if (SERVICE.hasError(userType)) {
        errorCount++;
        let msg = "Please select user type.";
        setUserTypeerr(msg);
      } else {
        setUserTypeerr(null);
      }

      if (SERVICE.hasError(screeningType1)) {
        errorCount++;
        let msg = "Please select screening.";
        setscreeningTypeErr(msg);
      } else {
        setscreeningTypeErr(null);
      }

      if (SERVICE.hasError(reference)) {
        errorCount++;
        let msg = "Please enter your reference.";
        setreferenceErr(msg);
      } else {
        setreferenceErr(null);
      }

      if (apiResponse == "1") {
        if (SERVICE.hasError(shareholder_owns)) {
          errorCount++;
          let msg = "Please select options.";
          setshareholder_ownserr(msg);
        } else {
          setshareholder_ownserr(null);
        }

        if (SERVICE.hasError(Shipper_Owner)) {
          errorCount++;
          let msg = "Please enter shipper name.";
          setShipper_Ownererr(msg);
        } else {
          setShipper_Ownererr(null);
        }

        // if (SERVICE.hasError(Shipper_Owners_Address)) {
        //   errorCount++;
        //   let msg = "Please enter Shipper address.";
        //   setShipper_Owners_Addresserr(msg);
        // } else {
        //   setShipper_Owners_Addresserr(null);
        // }

        // if (SERVICE.hasError(ShipperFile)) {
        //   errorCount++;
        //   let msg = "Please select file.";
        //   setShipperFileerr(msg);
        // } else {
        //   setShipperFileerr(null);
        // }

        if (SERVICE.hasError(BankCheck)) {
          errorCount++;
          let msg = "Please select Option.";
          setBankCheckerr(msg);
        } else {
          setBankCheckerr(null);
        }
      }

      if (FirstScreeningrespone == "1") {
        if (SERVICE.hasError(ShipperDoB)) {
          errorCount++;
          let msg = "Please enter Shipper DOB.";
          setShipperDoBerr(msg);
        } else {
          setShipperDoBerr(null);
        }
      }
      if (BankCheck == "yes" && apiResponse1 == "1") {
        if (SERVICE.hasError(BankName)) {
          errorCount++;
          let msg = "Please enter bank name.";
          setBankNameerr(msg);
        } else {
          setBankNameerr(null);
        }

        if (SERVICE.hasError(BankAdress)) {
          errorCount++;
          let msg = "Please enter bank address.";
          setBankAdresserr(msg);
        } else {
          setBankAdresserr(null);
        }
      }
      if (errorCount > 0)
        return setshipperloader(false), seterrCount(errorCount);
      else seterrCount(0);
    };

    const onProfileValidation1 = async () => {
      let errorCount = 0;

      if (consigneeFirstScreeningrespone == "1") {
        if (SERVICE.hasError(ConsigneeShipperDoB)) {
          errorCount++;
          let msg = "Please enter Shipper DOB.";
          setConsigneeShipperDoBerr(msg);
        } else {
          setConsigneeShipperDoBerr(null);
        }
      }
      if (SERVICE.hasError(reference)) {
        errorCount++;
        let msg = "Please enter your reference.";
        setreferenceErr(msg);
      } else {
        setreferenceErr(null);
      }
      if (SERVICE.hasError(Consigneename)) {
        errorCount++;
        let msg = "Please enter your name.";
        setConsigneeShipperNameerr(msg);
      } else {
        setConsigneeShipperNameerr(null);
      }

      if (SERVICE.hasError(ConsigneeUserType)) {
        errorCount++;
        let msg = "Please select user type.";
        setConsigneeUserTypeerr(msg);
      } else {
        setConsigneeUserTypeerr(null);
      }

      if (SERVICE.hasError(ConsigneescreeningType1)) {
        errorCount++;
        let msg = "Please select screening.";
        setConsigneescreeningTypeErr(msg);
      } else {
        setConsigneescreeningTypeErr(null);
      }

      if (SERVICE.hasError(reference)) {
        errorCount++;
        let msg = "Please enter your reference.";
        setreferenceErr(msg);
      } else {
        setreferenceErr(null);
      }
      if (ConsigneeapiResponse == "1") {
        if (SERVICE.hasError(Consigneeshareholder_owns)) {
          errorCount++;
          let msg = "Please select options.";
          setConsigneeshareholder_ownserr(msg);
        } else {
          setConsigneeshareholder_ownserr(null);
        }

        if (SERVICE.hasError(ConsigneeShipper_Owner)) {
          errorCount++;
          let msg = "Please enter shipper name.";
          setConsigneeShipper_Ownererr(msg);
        } else {
          setConsigneeShipper_Ownererr(null);
        }

        // if (SERVICE.hasError(ConsigneeShipper_Owners_Address)) {
        //   errorCount++;
        //   let msg = "Please enter Shipper address.";
        //   setConsigneeShipper_Owners_Addresserr(msg);
        // } else {
        //   setConsigneeShipper_Owners_Addresserr(null);
        // }

        // if (SERVICE.hasError(ConsigneeShipperFile)) {
        //   errorCount++;
        //   let msg = "Please select file.";
        //   setConsigneeShipperFileerr(msg);
        // } else {
        //   setConsigneeShipperFileerr(null);
        // }

        if (SERVICE.hasError(ConsigneeBankCheck)) {
          errorCount++;
          let msg = "Please select Option.";
          setConsigneeBankCheckerr(msg);
        } else {
          setConsigneeBankCheckerr(null);
        }
      }

      if (ConsigneeBankCheck == "yes" && ConsigneeapiResponse1 == "1") {
        if (SERVICE.hasError(ConsigneeBankName)) {
          errorCount++;
          let msg = "Please enter bank name.";
          setConsigneeBankNameerr(msg);
        } else {
          setConsigneeBankNameerr(null);
        }

        if (SERVICE.hasError(ConsigneeBankAdress)) {
          errorCount++;
          let msg = "Please enter bank address.";
          setConsigneeBankAdresserr(msg);
        } else {
          setConsigneeBankAdresserr(null);
        }
      }
      if (errorCount > 0)
        return setconsigneeloader(false), setConsigneeerrCount(errorCount);
      else setConsigneeerrCount(0);
    };

    const onProfileValidation2 = async () => {
      let errorCount = 0;

      if (SERVICE.hasError(reference)) {
        errorCount++;
        let msg = "Please enter your reference.";
        setreferenceErr(msg);
      } else {
        setreferenceErr(null);
      }

      if (SERVICE.hasError(EndUsername)) {
        errorCount++;
        let msg = "Please enter your name.";
        setEndUsershipperNameerr(msg);
      } else {
        setEndUsershipperNameerr(null);
      }

      if (SERVICE.hasError(EndUseruserType)) {
        errorCount++;
        let msg = "Please select user type.";
        setEndUserUserTypeerr(msg);
      } else {
        setEndUserUserTypeerr(null);
      }

      if (SERVICE.hasError(EndUserscreeningType1)) {
        errorCount++;
        let msg = "Please select screening.";
        setEndUserscreeningTypeErr(msg);
      } else {
        setEndUserscreeningTypeErr(null);
      }

      if (EndUserapiResponse == "1") {
        var shippervalidate = "0";
        if (SERVICE.hasError(EndUsershareholder_owns)) {
          var shippervalidate = "1";
          errorCount++;
          let msg = "Please select options.";
          setEndUsershareholder_ownserr(msg);
        } else {
          setEndUsershareholder_ownserr(null);
        }

        if (SERVICE.hasError(EndUserShipper_Owner)) {
          errorCount++;
          let msg = "Please enter shipper name.";
          setEndUserShipper_Ownererr(msg);
        } else {
          setEndUserShipper_Ownererr(null);
        }

        // if (SERVICE.hasError(EndUserShipper_Owners_Address)) {
        //   errorCount++;
        //   let msg = "Please enter Shipper address.";
        //   setEndUserShipper_Owners_Addresserr(msg);
        // } else {
        //   setEndUserShipper_Owners_Addresserr(null);
        // }

        // if (SERVICE.hasError(EndUserShipperFile)) {
        //   errorCount++;
        //   let msg = "Please select file.";
        //   setEndUserShipperFileerr(msg);
        // } else {
        //   setEndUserShipperFileerr(null);
        // }

        if (SERVICE.hasError(EndUserBankCheck)) {
          errorCount++;
          let msg = "Please select Option.";
          setEndUserBankCheckerr(msg);
        } else {
          setEndUserBankCheckerr(null);
        }
      }

      if (EndUserFirstScreeningrespone == "1") {
        if (SERVICE.hasError(EndUserShipperDoB)) {
          errorCount++;
          let msg = "Please enter Shipper DOB.";
          setEndUserShipperDoBerr(msg);
        } else {
          setEndUserShipperDoBerr(null);
        }
      }
      if (EndUserBankCheck == "yes" && EndUserapiResponse1 == "1") {
        if (SERVICE.hasError(EndUserBankName)) {
          errorCount++;
          let msg = "Please enter bank name.";
          setEndUserBankNameerr(msg);
        } else {
          setEndUserBankNameerr(null);
        }

        if (SERVICE.hasError(EndUserBankAdress)) {
          errorCount++;
          let msg = "Please enter bank address.";
          setEndUserBankAdresserr(msg);
        } else {
          setEndUserBankAdresserr(null);
        }
      }
      if (errorCount > 0)
        return setEndUserloaders(false), setEndUsererrCount(errorCount);
      else setEndUsererrCount(0);
    };
    const onChange = async (evt) => {
      setloader(true);
      const pic = evt.target.files[0];
      if (evt.target.files[0] != undefined) {
        const target = evt.target.files[0];
        setSelectedFile(target);
        // setPreview(URL.createObjectURL(evt.target.files[0]));

        let data = new FormData();

        data.append("uploadFile", evt.target.files[0]);

        var requestOptions = {
          method: "POST",
          body: data,
        };
        const response = await fetch(
          `${API.BASE_URL}uploadFile`,
          requestOptions
        )
          .then((response) => response.json())
          .then(async (res) => {
            setloader(false);
            if (res.status === true) {
              setShipperFile(res.data);
            }
          })
          .catch((error) => {});
      }
    };

    const onChangeconsignne = async (evt) => {
      setConsigneeloader(true);
      const pic = evt.target.files[0];
      if (evt.target.files[0] != undefined) {
        const target = evt.target.files[0];
        setSelectedFile(target);
        // setPreview(URL.createObjectURL(evt.target.files[0]));

        let data = new FormData();

        data.append("uploadFile", evt.target.files[0]);

        var requestOptions = {
          method: "POST",
          body: data,
        };
        const response = await fetch(
          `${API.BASE_URL}uploadFile`,
          requestOptions
        )
          .then((response) => response.json())
          .then(async (res) => {
            setConsigneeloader(false);
            if (res.status === true) {
              setConsigneeShipperFile(res.data);
            }
          })
          .catch((error) => {});
      }
    };

    const onChangeEndUser = async (evt) => {
      setEndUserloader(true);
      const pic = evt.target.files[0];
      if (evt.target.files[0] != undefined) {
        const target = evt.target.files[0];
        setSelectedFile(target);
        // setPreview(URL.createObjectURL(evt.target.files[0]));

        let data = new FormData();

        data.append("uploadFile", evt.target.files[0]);

        var requestOptions = {
          method: "POST",
          body: data,
        };
        const response = await fetch(
          `${API.BASE_URL}uploadFile`,
          requestOptions
        )
          .then((response) => response.json())
          .then(async (res) => {
            setEndUserloader(false);
            if (res.status === true) {
              setEndUserShipperFile(res.data);
            }
          })
          .catch((error) => {});
      }
    };

    const ScreeningAPi = async () => {
      setSubmit(true);
      setshipperloader(true);
      onProfileValidation();

      if (errorCount > 0) return;
      if (FirstScreeningrespone == "1" && ShipperDoB == "") {
        setshipperloader(false);

        return toast("Please fill required filled");
      }
      // await detectWhitespace(domain.trim());
      const userData = localStorage.getItem("userDetail");
      if (userData) {
        var data = JSON.parse(userData);
      }

      let payload = {
        name: ShipperName,
        reference_number: Shipperreference
          ? Shipperreference
          : ConsigneeepounRefernceNumber
          ? ConsigneeepounRefernceNumber
          : EndUserserTypeRefernce
          ? EndUserserTypeRefernce
          : reference,
        check: [userType],
        userType: userType,
        repound: [{ ShipperName: ShipperName }],
        shipmentType: screeningType1,
        userId: data._id,
        shareHolder_Owns: shareholder_owns,
        Shipper_Owners_name: Shipper_Owner,
        Shipper_Owners_Address: Shipper_Owners_Address,
        bankCheck: BankCheck,
        shipperFile: ShipperFile,
        shipperDOB: ShipperDoB,
        bankAddress: BankAdress,
        bankName: BankName,
      };

      await fetch(`${API.BASE_URL}getRPSScreeningData`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        // mode: "cors",
        body: JSON.stringify({
          name: ShipperName,
          reference_number: reference,
          check: [userType],
          userType: userType,
          repound: [{ ShipperName: ShipperName }],
          shipmentType: screeningType1,
          userId: data._id,
          shareHolder_Owns: shareholder_owns,
          Shipper_Owners_name: Shipper_Owner,
          Shipper_Owners_Address: Shipper_Owners_Address,
          bankCheck: BankCheck,
          shipperFile: ShipperFile,
          shipperDOB: ShipperDoB,
          bankAddress: BankAdress,
          bankName: BankName,
        }),
      })
        .then((response) => response.json())
        .then(async (res) => {
          setshipperloader(false);

          localStorage.removeItem("hsCodeVerficationData");

          setSetbox("1");

          await localStorage.setItem("reference", JSON.stringify(reference));
          await localStorage.setItem(
            "PerShipmentShipper",
            JSON.stringify(payload)
          );

          if (res.status == true) {
            if (userType == "Consignee") {
              await localStorage.setItem(
                "ConsigneeDATA",
                JSON.stringify(payload)
              );
            }
            setSubmit(false);

            setRPSScreening(true);
            setFirstScreeningrespone("1");
            toast("Risk found, You can proceed to next");
          }

          if (res.status == false) {
            if (userType == "Consignee") {
              await localStorage.setItem(
                "ConsigneeDATA",
                JSON.stringify(payload)
              );
            }
            setRPSScreening(true);

            toast("No risk found, You can proceed to next");
            setSubmit(false);
          }
          if (
            res.UK.status == "Risk" ||
            res.USA.status == "Risk" ||
            res.data.status == "Risk"
          ) {
            setRPSScreening(true);

            toast("Risk found, You can proceed to next");
          }
          if (
            res.UK.status == "No Risk" &&
            res.USA.status == "No Risk" &&
            res.data.status == "No Risk"
          ) {
            setRPSScreening(true);

            toast("No risk found, You can proceed to next ");
          }
          if (
            res.data.status == true ||
            res.USA.status == true ||
            res.UK.status == true
          ) {
            if (res.UK.status == true) {
              var filteredArray = res.UK.API_result.repound
                .filter((item) => item.risk)
                .map((item) => item.risk);
            } else if (res.USA.status == true) {
              var filteredArray = res.USA.API_result.repound
                .filter((item) => item.risk)
                .map((item) => item.risk);
            } else if (res.data.status == true) {
              var filteredArray = res.data.API_result.repound
                .filter((item) => item.risk)
                .map((item) => item.risk);
            }

            setapiResponse("1");
            // Updating state with filtered data

            const riskArray = [filteredArray];

            setrisk(riskArray);

            setRPSScreeningConsignee(false);
            setRPSScreeningenduser(false);

            if (screening == true || screening == undefined) {
              if (screening) setScreening(screeningCheck.hitOne);
              if (screening === screeningCheck.hitOne)
                setScreening(screeningCheck.hitSec);
            } else if (screening == false) {
              if (!screening) setScreening(screeningCheck.hitOne);
              if (screening === screeningCheck.hitOne)
                setScreening(screeningCheck.hitSec);
            }
          }

          if (
            res.data.status == false &&
            res.USA.status == false &&
            res.UK.status == false
          ) {
            setApiResponse("1");
            setRPSScreening(true);

            toast("No risk found, You can proceed to next ");
          }
        })
        .catch((error) => {});
    };

    const consigneeScreeningAPi = async () => {
      setConsigneeSubmit(true);
      setconsigneeloader(true);
      onProfileValidation1();
      if (ConsigneeerrorCount) {
        setconsigneeloader(false);
      }
      if (ConsigneeerrorCount > 0) return;

      if (consigneeFirstScreeningrespone == "1" && ConsigneeShipperDoB == "") {
        setconsigneeloader(false);

        return toast("Please fill required filled");
      }
      // await detectWhitespace(domain.trim());
      const userData = localStorage.getItem("userDetail");
      if (userData) {
        var data = JSON.parse(userData);
      }

      let payload = {
        name: Consigneename,
        reference_number: Shipperreference
          ? Shipperreference
          : ConsigneeepounRefernceNumber
          ? ConsigneeepounRefernceNumber
          : EndUserserTypeRefernce
          ? EndUserserTypeRefernce
          : reference,
        check: [ConsigneeUserType],
        userType: ConsigneeUserType,
        repound: [{ ShipperName: Consigneename }],
        shipmentType: screeningType1,
        userId: data._id,
        shareHolder_Owns: Consigneeshareholder_owns,
        Shipper_Owners_name: ConsigneeShipper_Owner,
        Shipper_Owners_Address: ConsigneeShipper_Owners_Address,
        bankCheck: ConsigneeBankCheck,
        shipperFile: ConsigneeShipperFile,
        shipperDOB: ConsigneeShipperDoB,
        bankAddress: ConsigneeBankAdress,
        bankName: ConsigneeBankName,
      };

      await fetch(`${API.BASE_URL}getRPSScreeningData`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        // mode: "cors",
        body: JSON.stringify({
          name: Consigneename,
          reference_number: reference,
          check: [ConsigneeUserType],
          userType: ConsigneeUserType,
          repound: [{ ShipperName: Consigneename }],
          shipmentType: screeningType1,
          userId: data._id,
          shareHolder_Owns: Consigneeshareholder_owns,
          Shipper_Owners_name: ConsigneeShipper_Owner,
          Shipper_Owners_Address: ConsigneeShipper_Owners_Address,
          bankCheck: ConsigneeBankCheck,
          shipperFile: ConsigneeShipperFile,
          shipperDOB: ConsigneeShipperDoB,
          bankAddress: ConsigneeBankAdress,
          bankName: ConsigneeBankName,
        }),
      })
        .then((response) => response.json())
        .then(async (res) => {
          setconsigneeloader(false);
          localStorage.removeItem("hsCodeVerficationData");

          setConsigneeSetbox("1");
          await localStorage.setItem("reference", JSON.stringify(reference));

          await localStorage.setItem(
            "PerShipmentConsignee",
            JSON.stringify(payload)
          );

          if (res.status == true) {
            if (userType == "Consignee") {
              await localStorage.setItem(
                "ConsigneeDATA",
                JSON.stringify(payload)
              );
            }

            setconsigneeFirstScreeningrespone("1");
            setRPSScreening(true);

            toast("Risk found,You can proceed to next ");
          }

          if (res.status == false) {
            if (userType == "Consignee") {
              await localStorage.setItem(
                "ConsigneeDATA",
                JSON.stringify(payload)
              );
            }

            setRPSScreening(true);

            toast("No risk found, You can proceed to next ");
            setConsigneeSubmit(false);
          }
          if (
            res.UK.status == "Risk" ||
            res.USA.status == "Risk" ||
            res.data.status == "Risk"
          ) {
            setRPSScreening(true);

            toast("Risk found");
          }
          if (
            res.UK.status == "No Risk" &&
            res.USA.status == "No Risk" &&
            res.data.status == "No Risk"
          ) {
            setRPSScreening(true);

            toast("No risk found, You can proceed to next ");
          }
          if (
            res.data.status == true ||
            res.USA.status == true ||
            res.UK.status == true
          ) {
            if (res.UK.status == true) {
              var filteredArray = res.UK.API_result.repound
                .filter((item) => item.risk)
                .map((item) => item.risk);
            } else if (res.USA.status == true) {
              var filteredArray = res.USA.API_result.repound
                .filter((item) => item.risk)
                .map((item) => item.risk);
            } else if (res.data.status == true) {
              var filteredArray = res.data.API_result.repound
                .filter((item) => item.risk)
                .map((item) => item.risk);
            }

            // Updating state with filtered data

            const riskArray = [filteredArray];

            setConsigneerisk(riskArray);

            setRPSScreeningenduser(false);

            setRPSScreening(false);

            if (consigneeScreening == true) {
              if (!consigneeScreening)
                setconsigneeScreening(consgineescreeningCheck.hitOne);
              if (consigneeScreening === consgineescreeningCheck.hitOne)
                setconsigneeScreening(consgineescreeningCheck.hitSec);
            } else if (consigneeScreening == false) {
              if (!consigneeScreening)
                setconsigneeScreening(consgineescreeningCheck.hitOne);
              if (consigneeScreening === consgineescreeningCheck.hitOne)
                setconsigneeScreening(consgineescreeningCheck.hitSec);
            }
            setConsigneeapiResponse("1");
          }

          if (
            res.data.status == false &&
            res.USA.status == false &&
            res.UK.status == false
          ) {
            setRPSScreening(true);

            toast("No risk found, You can proceed to next ");
          }
        })
        .catch((error) => {});
    };

    const endUserScreeningAPi = async () => {
      setEndUserSubmit(true);
      setEndUserloaders(true);
      onProfileValidation2();

      if (EndUserrrorCount > 0) return;
      if (EndUserFirstScreeningrespone == "1" && EndUserShipperDoB == "") {
        setEndUserloaders(false);

        return toast("Please fill required filled");
      }
      const userData = localStorage.getItem("userDetail");
      if (userData) {
        var data = JSON.parse(userData);
      }

      let payload = {
        name: EndUsername,
        reference_number: Shipperreference
          ? Shipperreference
          : ConsigneeepounRefernceNumber
          ? ConsigneeepounRefernceNumber
          : EndUserserTypeRefernce
          ? EndUserserTypeRefernce
          : reference,
        check: [EndUseruserType],
        userType: EndUseruserType,
        repound: [{ ShipperName: EndUsername }],
        shipmentType: screeningType1,
        userId: data._id,
        shareHolder_Owns: EndUsershareholder_owns,
        Shipper_Owners_name: EndUserShipper_Owner,
        Shipper_Owners_Address: EndUserShipper_Owners_Address,
        bankCheck: EndUserBankCheck,
        shipperFile: EndUserShipperFile,
        shipperDOB: EndUserShipperDoB,
        bankAddress: EndUserBankAdress,
        bankName: EndUserBankName,
      };

      await fetch(`${API.BASE_URL}getRPSScreeningData`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        // mode: "cors",
        body: JSON.stringify({
          name: EndUsername,
          reference_number: reference,
          check: [EndUseruserType],
          userType: EndUseruserType,
          repound: [{ ShipperName: EndUsername }],
          shipmentType: screeningType1,
          userId: data._id,
          shareHolder_Owns: EndUsershareholder_owns,
          Shipper_Owners_name: EndUserShipper_Owner,
          Shipper_Owners_Address: EndUserShipper_Owners_Address,
          bankCheck: EndUserBankCheck,
          shipperFile: EndUserShipperFile,
          shipperDOB: EndUserShipperDoB,
          bankAddress: EndUserBankAdress,
          bankName: EndUserBankName,
        }),
      })
        .then((response) => response.json())
        .then(async (res) => {
          setEndUserloaders(false);

          localStorage.removeItem("hsCodeVerficationData");

          setEndUserSetbox("1");

          await localStorage.setItem(
            "PerShipmentEndUser",
            JSON.stringify(payload)
          );

          await localStorage.setItem("reference", JSON.stringify(reference));

          if (res.status == true) {
            if (userType == "Consignee") {
              await localStorage.setItem(
                "ConsigneeDATA",
                JSON.stringify(payload)
              );
            }

            setRPSScreening(true);
            setEndUserFirstScreeningrespone("1");

            toast("Risk found, You can proceed to next");
          }

          if (res.status == false) {
            if (userType == "Consignee") {
              await localStorage.setItem(
                "ConsigneeDATA",
                JSON.stringify(payload)
              );
            }
            setRPSScreening(true);

            toast("No risk found, You can proceed to next ");
            setEndUserSubmit(false);
          }
          if (
            res.UK.status == "Risk" ||
            res.USA.status == "Risk" ||
            res.data.status == "Risk"
          ) {
            setRPSScreening(true);

            toast("Risk found");
          }
          if (
            res.UK.status == "No Risk" &&
            res.USA.status == "No Risk" &&
            res.data.status == "No Risk"
          ) {
            setRPSScreening(true);

            toast("No risk found, You can proceed to next ");
          }
          if (
            res.data.status == true ||
            res.USA.status == true ||
            res.UK.status == true
          ) {
            if (res.UK.status == true) {
              var filteredArray = res.UK.API_result.repound
                .filter((item) => item.risk)
                .map((item) => item.risk);
            } else if (res.USA.status == true) {
              var filteredArray = res.USA.API_result.repound
                .filter((item) => item.risk)
                .map((item) => item.risk);
            } else if (res.data.status == true) {
              var filteredArray = res.data.API_result.repound
                .filter((item) => item.risk)
                .map((item) => item.risk);
            }

            // Updating state with filtered data

            const riskArray = [filteredArray];

            setEndUserrisk(riskArray);

            setRPSScreeningConsignee(false);
            setRPSScreening(false);

            if (EndUserScreening == true) {
              if (!EndUserScreening)
                setEndUserScreening(EndUserscreeningCheck.hitOne);
              if (EndUserScreening === EndUserscreeningCheck.hitOne)
                setEndUserScreening(EndUserscreeningCheck.hitSec);
            } else if (EndUserScreening == false) {
              if (!EndUserScreening)
                setEndUserScreening(EndUserscreeningCheck.hitOne);
              if (EndUserScreening === EndUserscreeningCheck.hitOne)
                setEndUserScreening(EndUserscreeningCheck.hitSec);
            }
            setEndUserapiResponse("1");
          }

          if (
            res.data.status == false &&
            res.USA.status == false &&
            res.UK.status == false
          ) {
            setRPSScreening(true);

            toast("No risk found, You can proceed to next");
          }
        })
        .catch((error) => {});
    };

    // const localsearchApi = async()=>{

    //  const SearchAPIS =  await localStorage.getItem('seacrhApi')

    //  const searchData = JSON.parse(SearchAPIS)
    //     setgetSearchApi(searchData)

    // }

    const datasearch = debounce(async (term) => {
      const searchAPIStr = await localStorage.getItem("seacrhApi");
      const searchData = JSON.parse(searchAPIStr);
      // Filter the searchData based on the ShipperName input value
      const filteredData = searchData.filter((item) => {
        // Assuming you want to filter based on the 'Name' property of each item
        return item.Name.toLowerCase().includes(ShipperName.toLowerCase());
      });
      setgetSearchApi(filteredData);
    }, 300);

    const consigneedatasearch = debounce(async (term) => {
      const searchAPIStr = await localStorage.getItem("seacrhApi");
      const searchData = JSON.parse(searchAPIStr);
      // Filter the searchData based on the ShipperName input value
      const filteredData = searchData.filter((item) => {
        // Assuming you want to filter based on the 'Name' property of each item
        return item.Name.toLowerCase().includes(ShipperName.toLowerCase());
      });
      setconsigneegetSearchApi(filteredData);
    }, 300);

    const Userdatasearch = debounce(async (term) => {
      const searchAPIStr = await localStorage.getItem("seacrhApi");
      const searchData = JSON.parse(searchAPIStr);
      // Filter the searchData based on the ShipperName input value
      const filteredData = searchData.filter((item) => {
        // Assuming you want to filter based on the 'Name' property of each item
        return item.Name.toLowerCase().includes(ShipperName.toLowerCase());
      });
      setcendUsergetSearchApi(filteredData);
    }, 300);

    const localsearchApi = async () => {
      const searchAPIStr = await localStorage.getItem("seacrhApi");
      const searchData = JSON.parse(searchAPIStr);
      // Filter the searchData based on the ShipperName input value
      datasearch(searchData);
      // setgetSearchApi(filteredData);
    };

    const consigneelocalsearchApi = async () => {
      const searchAPIStr = await localStorage.getItem("seacrhApi");
      const searchData = JSON.parse(searchAPIStr);
      // Filter the searchData based on the ShipperName input value
      consigneedatasearch(searchData);
      // setgetSearchApi(filteredData);
    };

    const userlocalsearchApi = async () => {
      const searchAPIStr = await localStorage.getItem("seacrhApi");
      const searchData = JSON.parse(searchAPIStr);
      // Filter the searchData based on the ShipperName input value
      Userdatasearch(searchData);
      // setgetSearchApi(filteredData);
    };

    const getsearchaPI = async () => {
      // setSubmit(true);
      let payload = {
        name: ShipperName,
      };

      await fetch(`${API.BASE_URL}SearchAPI`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        // mode: "cors",
        body: JSON.stringify({
          name: [{ name: ShipperName }],
        }),
      })
        .then((response) => response.json())
        .then(async (res) => {
          if (res.status == true && res.nameArray.length > 0) {
            const jsonString = JSON.stringify(res.nameArray);
            await localStorage.setItem("seacrhApi", jsonString);
            const filteredData = res.nameArray.filter((item) => {
              // Assuming you want to filter based on the 'Name' property of each item
              return item.toLowerCase().includes(ShipperName.toLowerCase());
            });

            setShowNameModal(true);
            setusergetSearchApi(filteredData);
          }

          // setgetSearchApi(filteredCarrierList);

          //  setgetSearchApi(res)
        })
        .catch((error) => {});
    };

    const consigneegetsearchaPI = async () => {
      // setSubmit(true);

      await fetch(`${API.BASE_URL}SearchAPI`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        // mode: "cors",
        body: JSON.stringify({
          name: [{ name: Consigneename }],
        }),
      })
        .then((response) => response.json())
        .then(async (res) => {
          if (res.status == true && res.nameArray.length > 0) {
            const jsonString = JSON.stringify(res.nameArray);
            await localStorage.setItem("seacrhApi", jsonString);
            const filteredData = res.nameArray.filter((item) => {
              // Assuming you want to filter based on the 'Name' property of each item
              return item.toLowerCase().includes(Consigneename.toLowerCase());
            });
            setShowNameModal1(true);
            setconsigneegetSearchApi(filteredData);
            // setgetSearchApi(filteredCarrierList);

            //  setgetSearchApi(res)
          }
        })
        .catch((error) => {});
    };

    const EndusergetsearchaPI = async () => {
      // setSubmit(true);

      await fetch(`${API.BASE_URL}SearchAPI`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        // mode: "cors",
        body: JSON.stringify({
          name: [{ name: EndUsername }],
        }),
      })
        .then((response) => response.json())
        .then(async (res) => {
          if (res.status == true && res.nameArray.length > 0) {
            const jsonString = JSON.stringify(res.nameArray);
            await localStorage.setItem("seacrhApi", jsonString);
            const filteredData = res.nameArray.filter((item) => {
              // Assuming you want to filter based on the 'Name' property of each item
              return item.toLowerCase().includes(EndUsername.toLowerCase());
            });
            setShowNameModal2(true);
            setcendUsergetSearchApi(filteredData);
            // setgetSearchApi(filteredCarrierList);

            //  setgetSearchApi(res)
          }
        })
        .catch((error) => {});
    };

    const getRefernceNo = async () => {
      const userData = localStorage.getItem("userDetail");

      if (userData) {
        var data = JSON.parse(userData);
      }

      await fetch(`${API.BASE_URL}generateReferenceNumber`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        // mode: "cors",
        body: JSON.stringify({
          userId: data._id,
        }),
      })
        .then((response) => response.json())
        .then(async (res) => {
          if (status == true) {
          }
          setreference(res.referenceNo);
        })
        .catch((error) => {});
    };

    useEffect(() => {
      if (ShipperName === "") {
        setShowNameModal(false);
      }
      if (Consigneename === "") {
        setShowNameModal(false);
      }

      if (EndUsername === "") {
        setShowNameModal(false);
      }
    }, [ShipperName, Consigneename, EndUsername]);
    useEffect(() => {
      const EndUserData = JSON.parse(
        localStorage.getItem("PerShipmentEndUser")
      );
      const ConsigneeData = JSON.parse(
        localStorage.getItem("PerShipmentConsignee")
      );

      const shipperData = JSON.parse(
        localStorage.getItem("PerShipmentShipper")
      );

      if (EndUserData) {
        setEndUserShipper_Name(EndUserData.name);
        setEndUserserTypeRefernce(EndUserData.reference_number);
        setEndUserUserType(EndUserData.userType);
        setShipperUserType(EndUserData.userType);
        setscreeningType1(EndUserData.shipmentType);
        setEndUsershareholder_owns(EndUserData.shareHolder_Owns);
        setEndUserShipper_Owner(EndUserData.Shipper_Owners_name);
        setEndUserShipper_Owners_Address(EndUserData.Shipper_Owners_Address);
        setEndUserBankCheck(EndUserData.bankCheck);
        setEndUserShipperFile(EndUserData.shipperFile);
        setEndUserShipperDoB(EndUserData.shipperDOB);
        setEndUserBankAdress(EndUserData.bankAddress);
        setEndUserBankName(EndUserData.bankName);

        if (EndUserData.shipperDOB) {
          if (EndUserScreening == true) {
            if (!EndUserScreening)
              setEndUserScreening(EndUserscreeningCheck.hitOne);
            if (EndUserScreening === EndUserscreeningCheck.hitOne)
              setEndUserScreening(EndUserscreeningCheck.hitSec);
          } else if (EndUserScreening == false) {
            if (!EndUserScreening)
              setEndUserScreening(EndUserscreeningCheck.hitOne);
            if (EndUserScreening === EndUserscreeningCheck.hitOne)
              setEndUserScreening(EndUserscreeningCheck.hitSec);
          }
        }

        if (EndUserData.shipperDOB) {
          setEndUserFirstScreeningrespone("1");
        }
      }

      if (ConsigneeData) {
        setConsigneeShipper_Name(ConsigneeData.name);

        setrConsigneeepounRefernceNumber(ConsigneeData.reference_number);
        setConsigneeUserType(ConsigneeData.userType);
        setscreeningType1(ConsigneeData.shipmentType);
        setConsigneeshareholder_owns(ConsigneeData.shareHolder_Owns);

        setConsigneeShipper_Owner(ConsigneeData.Shipper_Owners_name);
        setConsigneeShipper_Owners_Address(
          ConsigneeData.Shipper_Owners_Address
        );
        setConsigneeBankCheck(ConsigneeData.bankCheck);
        setconsigneeStatus(shipperData.bankCheck == "yes" ? 1 : 0);
        setConsigneeShipperFile(ConsigneeData.shipperFile);
        setConsigneeShipperDoB(ConsigneeData.shipperDOB);
        setConsigneeBankAdress(ConsigneeData.bankAddress);
        setConsigneeBankName(ConsigneeData.bankName);

        if (ConsigneeData.Shipper_Owners_name) {
          if (consigneeScreening == true) {
            if (!consigneeScreening)
              setconsigneeScreening(consgineescreeningCheck.hitOne);
            if (consigneeScreening === consgineescreeningCheck.hitOne)
              setconsigneeScreening(consgineescreeningCheck.hitSec);
          } else if (consigneeScreening == false) {
            if (!consigneeScreening)
              setconsigneeScreening(consgineescreeningCheck.hitOne);
            if (consigneeScreening === consgineescreeningCheck.hitOne)
              setconsigneeScreening(consgineescreeningCheck.hitSec);
          }
        }

        if (ConsigneeData.shipperDOB) {
          setconsigneeFirstScreeningrespone("1");
        }
      }

      if (shipperData) {
        setshipperName(shipperData.name);

        setrShipperreference(shipperData.reference_number);
        setUserType(shipperData.userType);
        setscreeningType1(shipperData.shipmentType);
        setshareholder_owns(shipperData.shareHolder_Owns);

        setShipper_Owner(shipperData.Shipper_Owners_name);
        setShipper_Owners_Address(shipperData.Shipper_Owners_Address);
        setBankCheck(shipperData.bankCheck == "yes" ? 1 : 0);
        setStatus(shipperData.bankCheck == "yes" ? 1 : 0);
        setShipperFile(shipperData.shipperFile);
        setShipperDoB(shipperData.shipperDOB);
        setBankAdress(shipperData.bankAddress);
        setBankName(shipperData.bankName);

        if (shipperData.Shipper_Owners_name) {
          if (screening == true || screening == undefined) {
            if (screening) setScreening(screeningCheck.hitOne);
            if (screening === screeningCheck.hitOne)
              setScreening(screeningCheck.hitSec);
          } else if (screening == false) {
            if (!screening) setScreening(screeningCheck.hitOne);
            if (screening === screeningCheck.hitOne)
              setScreening(screeningCheck.hitSec);
          }
        }

        if (shipperData.shipperDOB) {
          setFirstScreeningrespone("1");
        }
      }

      getRefernceNo();
    }, []);

    useEffect(() => {
      if (ShipperName !== "") {
        const selectedShipper = getSearchApi.find(
          (DATA) => DATA.Name === ShipperName
        );

        if (selectedShipper) {
          setshipperName(selectedShipper.Name);
        }
      }
      if (Consigneename !== "") {
        const selectedShipper = consigneegetSearchApi.find(
          (DATA) => DATA.Name === Consigneename
        );

        if (selectedShipper) {
          setConsigneeShipper_Name(selectedShipper.Name);
        }
      }
      if (EndUsername !== "") {
        const selectedShipper = usergetSearchApi.find(
          (DATA) => DATA.Name === EndUsername
        );

        if (selectedShipper) {
          setEndUserShipper_Name(selectedShipper.Name);
        }
      }
    }, [
      ShipperName,
      getSearchApi,
      Consigneename,
      consigneegetSearchApi,
      EndUsername,
      usergetSearchApi,
    ]);
    useEffect(() => {
      localStorage.removeItem("reference");
    }, []);

    useEffect(() => {
      onProfileValidation();
    }, [
      reference,

      ShipperName,
      shareholder_owns,
      Shipper_Owner,
      Shipper_Owners_Address,
      ShipperDoB,
      ShipperFile,
      BankCheck,
      BankName,
      BankAdress,
    ]);
    useEffect(() => {
      onProfileValidation1();
    }, [
      reference,
      Consigneename,
      ConsigneeUserType,
      Consigneeshareholder_owns,
      ConsigneeShipper_Owner,
      ConsigneeShipper_Owners_Address,
      ConsigneeBankCheck,
      ConsigneeShipperFile,
      ConsigneeShipperDoB,
      ConsigneeBankAdress,
      ConsigneeBankName,
    ]);
    useEffect(() => {
      onProfileValidation2();
    }, [
      reference,

      EndUsername,
      EndUseruserType,
      EndUserscreeningType1,
      EndUsershareholder_owns,
      EndUserShipper_Owner,
      EndUserShipper_Owners_Address,
      EndUserShipperDoB,
      EndUserBankCheck,
      EndUserBankName,
      EndUserBankAdress,
    ]);

    return (
      <>
        <Row>
          <Col md={6}>
            <CustomForm
              onChange={(e) => setreference(e.target.value)} // set the onChange handler
              value={
                Shipperreference
                  ? Shipperreference
                  : ConsigneeepounRefernceNumber
                  ? ConsigneeepounRefernceNumber
                  : EndUserserTypeRefernce
                  ? EndUserserTypeRefernce
                  : reference
              }
              color={"#222528"}
              type="text"
              readOnly={true}
              lableText="Reference Number/Description"
              placeholderText="Enter Reference Number/Description"
            />
            {(referenceErr && Submit) ||
            ConsigneeSubmit ||
            (EndUserSubmit && !reference) ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                }}
              >
                {referenceErr}
              </div>
            ) : null}
          </Col>
          <Col md={6}>
            <Form.Label>
              Is this Per shipment Screening or Batch Screening?
            </Form.Label>
            {["radio"].map((type) => (
              <div key={`inline-${type}`} className="radioBx">
                <Form.Check
                  inline
                  label="Per Shipment Screening"
                  name="pageSelection"
                  type={type}
                  checked
                  value="add-screening"
                  onChange={handleRadioChange}
                />
                <Form.Check
                  inline
                  label="Batch Screening"
                  name="pageSelection"
                  type={type}
                  value="batch-screening"
                  onChange={handleRadioChange}
                />
                {screeningTypeErr && Submit && !screeningType1 ? (
                  <div
                    style={{
                      fontFamily: "AvenirLTProLight",
                      color: "#FF0000",
                      opacity: 0.6,
                    }}
                  >
                    {screeningTypeErr}
                  </div>
                ) : null}
              </div>
            ))}
          </Col>
        </Row>

        <div className="mb-4">
          <Form.Label>Add User Type</Form.Label>
          <div className="d-flex align-items-center w-100">
            <div className="position-relative w-50">
              <Form.Select
                value={CommonuserType}
                onChange={handleSelectChange}
                defaultValue="Shipper"
              >
                <option value="Select">Select</option>
                <option value="Shipper">Shipper</option>
                <option value="Consignee">Consignee</option>
                <option value="End User">End User</option>
              </Form.Select>

              {userTypeerr && Submit && !userType ? (
                <div
                  style={{
                    fontFamily: "AvenirLTProLight",
                    color: "#FF0000",
                    opacity: 0.6,
                  }}
                >
                  {userTypeerr}
                </div>
              ) : null}

              <div className="cu_icon">
                <svg width="14" height="7" viewBox="0 0 14 7" fill="none">
                  <path d="M0 0L7 7L14 0H0Z" fill="#222528" />
                </svg>
              </div>
            </div>

            {CommonuserType ? (
              <>
                <Button
                  className="uploadBtn uploadBtn1 mx-2"
                  onClick={() => handleAddcolumn()}
                >
                  <Icon icon="carbon:add-filled" fontSize={18} /> Add Column
                </Button>
              </>
            ) : (
              <></>
            )}
            <Button
              className="uploadBtn uploadBtn1 mx-2"
              style={{ width: 100 }}
              onClick={() => openAllUserType()}
            >
              <Icon icon="carbon:add-filled" fontSize={18} /> All
            </Button>
          </div>
        </div>
        {userType == "Shipper" && (
          <Accordion defaultActiveKey="0">
            <>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Shipper</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col md={12}>
                      <Row>
                        <Col md={12}>
                          <div className="searchbtnmain_div">
                            <div
                              className="position-relative"
                              style={{ flexGrow: 1 }}
                            >
                              <CustomFormIcon
                                // onChange={(e) => setshipperName(e.target.value)} // set the onChange handler
                                onChange={(event) => {
                                  setshipperName(event.target.value);
                                  // getsearchaPI();
                                }}
                                SearchApi={getsearchaPI}
                                value={ShipperName}
                                color={"#222528"}
                                type="text"
                                lableText="Shipper Name"
                                readOnly={setBox == "1" ? true : false}
                                placeholderText="Enter Shipper Name"
                              />
                              {showNameModal && (
                                <div className="modal-container">
                                  {usergetSearchApi.map((item, index) => (
                                    <li
                                      key={index}
                                      onClick={() =>
                                        hadleSelectedtName(item, index)
                                      }
                                    >
                                      {item}
                                    </li>
                                  ))}
                                </div>
                              )}
                            </div>

                            <button
                              className="btn btn-primary search_btn"
                              type="button"
                              onClick={getsearchaPI}
                            >
                              <Icon
                                icon="iconoir:search"
                                className="icon_pos"
                                fontSize={18}
                              />
                            </button>
                          </div>
                        </Col>
                        {shipperNameerr &&
                        Submit &&
                        !ShipperName &&
                        userType == "Shipper" ? (
                          <div
                            style={{
                              fontFamily: "AvenirLTProLight",
                              color: "#FF0000",
                              opacity: 0.6,
                            }}
                          >
                            {shipperNameerr}
                          </div>
                        ) : null}
                      </Row>

                      {(screeningCheck.hitOne === screening ||
                        screeningCheck.hitSec === screening) && (
                        <div>
                          <Col md={12}>
                            {/* <strong>West Coast Shipping Co.</strong> */}
                            {risk.map((item, index) => {
                              return (
                                <div className="my-3" key={index}>
                                  <span className="rejected fw-normal">
                                    {item}
                                  </span>
                                </div>
                              );
                            })}
                            {/* <div className="my-3">
                              <span className="rejected fw-normal">
                                Risks Found
                              </span>
                              <span className="rejected mx-4">
                                UK Sanctioned List
                              </span>
                              <span className="rejected mx-4">
                                EU Sanctioned List
                              </span>
                              <span className="rejected">
                                USA Sanctioned List
                              </span>
                            </div> */}
                          </Col>

                          <Col md={12}>
                            <small className="smallText">
                              First Screening Return Hit
                            </small>

                            <Row>
                              <Col md={12} lg={6}>
                                <Row>
                                  <Col md={12} lg={6}>
                                    <div className="mb-3">
                                      <span className="form-label">
                                        Does the shareholder owns {">"} 51%
                                      </span>
                                      {["radio"].map((type) => (
                                        <div
                                          key={`inline-${type}`}
                                          className="radioBx"
                                        >
                                          <Form.Check
                                            inline
                                            label="Yes"
                                            name="owns"
                                            type={type}
                                            value="Yes"
                                            checked={shareholder_owns === "Yes"}
                                            id={`inline-${type}-1`}
                                            onChange={handleOwnershipChange}
                                          />
                                          <Form.Check
                                            inline
                                            label="No"
                                            name="owns"
                                            type={type}
                                            value="No"
                                            checked={shareholder_owns === "No"}
                                            id={`inline-${type}-2`}
                                            onChange={handleOwnershipChange}
                                          />
                                        </div>
                                      ))}
                                      {shareholder_ownserr &&
                                      Submit &&
                                      !shareholder_owns ? (
                                        <div
                                          style={{
                                            fontFamily: "AvenirLTProLight",
                                            color: "#FF0000",
                                            opacity: 0.6,
                                          }}
                                        >
                                          {shareholder_ownserr}
                                        </div>
                                      ) : null}
                                    </div>
                                  </Col>
                                  <Col md={12} lg={6}>
                                    <CustomForm
                                      value={Shipper_Owner}
                                      onChange={(e) =>
                                        setShipper_Owner(e.target.value)
                                      }
                                      color={"#222528"}
                                      type="text"
                                      lableText="Shipper Owner’s name**"
                                      placeholderText="Enter Shipper Owner’s name"
                                    />
                                    {Shipper_Ownererr &&
                                    Submit &&
                                    !Shipper_Owner ? (
                                      <div
                                        style={{
                                          fontFamily: "AvenirLTProLight",
                                          color: "#FF0000",
                                          opacity: 0.6,
                                        }}
                                      >
                                        {Shipper_Ownererr}
                                      </div>
                                    ) : null}
                                  </Col>
                                </Row>
                              </Col>

                              {status === 2 && null}
                              <Col md={6}>
                                <CustomForm
                                  value={Shipper_Owners_Address}
                                  onChange={(e) =>
                                    setShipper_Owners_Address(e.target.value)
                                  }
                                  color={"#222528"}
                                  type="text"
                                  lableText="Shipper Owner’s Address *"
                                  placeholderText="Enter Shipper Owner’s Address"
                                />
                                {Shipper_Owners_Addresserr &&
                                Submit &&
                                !Shipper_Owners_Address ? (
                                  <div
                                    style={{
                                      fontFamily: "AvenirLTProLight",
                                      color: "#FF0000",
                                      opacity: 0.6,
                                    }}
                                  >
                                    {Shipper_Owners_Addresserr}
                                  </div>
                                ) : null}
                              </Col>

                              {/* <Col md={6}>
                                <CustomForm
                                  color={"#222528"}
                                  type="text"
                                  lableText="Bank Address *"
                                  placeholderText="Enter Bank Address"
                                />
                              </Col> */}
                              <Col md={12}>
                                <h3 className="s15">
                                  Shipper’s Business Bank Name Optional for all
                                  country destinations except for
                                  <span>
                                    Russia, Belarus, Iran, Cuba, Myanmar, Syria,
                                    Sudan, Iraq, Libya, Somalia, Lebanon, Yemen,
                                    Zimbabwe
                                  </span>
                                  – if shipment destination these countries then
                                  Mandatory
                                </h3>

                                <div className="mb-3">
                                  <span className="form-label">
                                    Country of destinations to these countries?
                                  </span>
                                  {["radio"].map((type) => (
                                    <div
                                      key={`inline-${type}`}
                                      className="radioBx"
                                    >
                                      <Form.Check
                                        inline
                                        label="Yes"
                                        name="owns1"
                                        checked={status === 1}
                                        onChange={(e) => radioHandler(1)}
                                        type={type}
                                        id={`inline-${type}-1`}
                                      />
                                      <Form.Check
                                        inline
                                        label="No"
                                        name="owns1"
                                        type={type}
                                        checked={status === 0}
                                        onChange={(e) => radioHandler(0)}
                                        id={`inline-${type}-2`}
                                      />
                                    </div>
                                  ))}
                                </div>
                                {BankCheckerr && Submit && !BankCheck ? (
                                  <div
                                    style={{
                                      fontFamily: "AvenirLTProLight",
                                      color: "#FF0000",
                                      opacity: 0.6,
                                    }}
                                  >
                                    {BankCheckerr}
                                  </div>
                                ) : null}
                              </Col>
                            </Row>
                            {status === 1 && (
                              <Col md={12}>
                                <Row>
                                  <Col md={6}>
                                    <CustomFormWithToolTip
                                      color={"#222528"}
                                      type="text"
                                      toolTopContent="Shipper’s Business Bank Name
                          Optional for all country destinations
                          except for Russia, Belarus, Iran,
                          Cuba, Myanmar, Syria, Sudan, Iraq,
                          Libya, Somalia, Lebanon, Yemen,
                          Zimbabwe – if shipment destination
                          these countries then Mandatory"
                                      lableText="Bank Name (optional except several countries)"
                                      placeholderText="Enter Bank Name"
                                      value={BankName}
                                      onChange={(e) =>
                                        setBankName(e.target.value)
                                      }
                                    />
                                    {BankNameerr && Submit && !BankName ? (
                                      <div
                                        style={{
                                          fontFamily: "AvenirLTProLight",
                                          color: "#FF0000",
                                          opacity: 0.6,
                                          marginTop: -12,
                                        }}
                                      >
                                        {BankNameerr}
                                      </div>
                                    ) : null}
                                  </Col>

                                  <Col md={6}>
                                    <CustomForm
                                      color={"#222528"}
                                      type="text"
                                      lableText="Bank Address *"
                                      placeholderText="Enter Bank Address"
                                      value={BankAdress}
                                      onChange={(e) =>
                                        setBankAdress(e.target.value)
                                      }
                                    />
                                    {BankAdresserr && Submit && !BankAdress ? (
                                      <div
                                        style={{
                                          fontFamily: "AvenirLTProLight",
                                          color: "#FF0000",
                                          opacity: 0.6,
                                          marginTop: -12,
                                        }}
                                      >
                                        {BankAdresserr}
                                      </div>
                                    ) : null}
                                  </Col>
                                </Row>
                              </Col>
                            )}
                          </Col>
                        </div>
                      )}

                      {FirstScreeningrespone == "1" && (
                        <>
                          <small className="smallText">
                            Second Screening Return Hit
                          </small>
                          <Row>
                            <Col md={6}>
                              <CustomForm
                                color={"#222528"}
                                type="date"
                                lableText="Shipper DOB *"
                                placeholderText="Enter Shipper DOB"
                                value={ShipperDoB}
                                onChange={(e) => setShipperDoB(e.target.value)}
                              />
                              {ShipperDoBerr && Submit && !ShipperDoB ? (
                                <div
                                  style={{
                                    fontFamily: "AvenirLTProLight",
                                    color: "#FF0000",
                                    opacity: 0.6,
                                  }}
                                >
                                  {ShipperDoBerr}
                                </div>
                              ) : null}
                            </Col>

                            <Col md={6}>
                              <CustomFileUpload
                                color={"#222528"}
                                type="file"
                                lableText="Shipper Passport (optional)"
                                placeholderText="Upload Passport"
                                onChange={onChange}
                              />
                              {ShipperFileerr && Submit && !ShipperFile ? (
                                <div
                                  style={{
                                    fontFamily: "AvenirLTProLight",
                                    color: "#FF0000",
                                    opacity: 0.6,
                                  }}
                                >
                                  {ShipperFileerr}
                                </div>
                              ) : null}
                            </Col>
                          </Row>
                        </>
                      )}

                      <Button
                        // onClick={() => {
                        //   // ScreeningAPi();
                        //   // handleRepeatColumn11();
                        //   if (shipperData) {
                        //     ScreeningAPi();
                        //   }
                        // }}

                        onClick={() => {
                          // if (name === "") {
                          //   alert("Please enter shipper name");
                          // } else {
                          //   ScreeningAPi();
                          // }
                          ScreeningAPi();
                        }}
                        className="btn btn-primary mb-4 mt-2 width_btn"
                      >
                        {shipperloader == true ? (
                          <Oval
                            height="30"
                            width="30"
                            radius="30"
                            color="#f2f5f3"
                            ariaLabel="loading"
                            wrapperStyle={{ marginLeft: 100 }}
                            wrapperClass
                          />
                        ) : (
                          <>Start Screening</>
                        )}
                      </Button>

                      <ToastContainer />
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </>
          </Accordion>
        )}
        {ConsigneeUserType == "Consignee" && (
          <Accordion defaultActiveKey="0">
            <>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Consignee</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col md={12}>
                      <Row>
                        <Col md={12}>
                          <div className="searchbtnmain_div">
                            <div
                              className="position-relative"
                              style={{ flexGrow: 1 }}
                            >
                              <CustomFormIcon
                                onChange={(event) => {
                                  setConsigneeShipper_Name(event.target.value);
                                  // getsearchaPI();
                                  // consigneelocalsearchApi();
                                }}
                                SearchApi={consigneegetsearchaPI}
                                // onChange={(e) => setConsigneeShipper_Name(e.target.value)} // set the onChange handler
                                value={Consigneename}
                                color={"#222528"}
                                type="text"
                                lableText="Consignee Name"
                                readOnly={ConsigneesetBox == "1" ? true : false}
                                placeholderText="Enter Consignee Name"
                              />
                              {showNameModal1 && (
                                <div className="modal-container">
                                  {consigneegetSearchApi.map((item, index) => (
                                    <li
                                      key={index}
                                      onClick={() =>
                                        consigneehadleSelectedtName(item, index)
                                      }
                                    >
                                      {item}
                                    </li>
                                  ))}
                                </div>
                              )}
                            </div>

                            <button
                              className="btn btn-primary search_btn"
                              type="button"
                              onClick={consigneegetsearchaPI}
                            >
                              <Icon
                                icon="iconoir:search"
                                className="icon_pos"
                                fontSize={18}
                              />
                            </button>
                          </div>
                        </Col>
                        {ConsigneeShipperNameerr &&
                        ConsigneeSubmit &&
                        !Consigneename &&
                        ConsigneeUserType == "Consignee" ? (
                          <div
                            style={{
                              fontFamily: "AvenirLTProLight",
                              color: "#FF0000",
                              opacity: 0.6,
                            }}
                          >
                            {ConsigneeShipperNameerr}
                          </div>
                        ) : null}
                      </Row>

                      {(consgineescreeningCheck.hitOne === consigneeScreening ||
                        consgineescreeningCheck.hitSec ===
                          consigneeScreening) && (
                        <div>
                          <Col md={12}>
                            {/* <strong>West Coast Shipping Co.</strong> */}
                            {Consigneerisk.map((item, index) => {
                              return (
                                <div className="my-3" key={index}>
                                  <span className="rejected fw-normal">
                                    {item}
                                  </span>
                                </div>
                              );
                            })}
                            {/* <div className="my-3">
                              <span className="rejected fw-normal">
                                Risks Found
                              </span>
                              <span className="rejected mx-4">
                                UK Sanctioned List
                              </span>
                              <span className="rejected mx-4">
                                EU Sanctioned List
                              </span>
                              <span className="rejected">
                                USA Sanctioned List
                              </span>
                            </div> */}
                          </Col>

                          <Col md={12}>
                            <small className="smallText">
                              First Screening Return Hit
                            </small>

                            <Row>
                              <Col md={12} lg={6}>
                                <Row>
                                  <Col md={12} lg={6}>
                                    <div className="mb-3">
                                      <span className="form-label">
                                        Does the shareholder owns {">"} 51%
                                      </span>
                                      {["radio"].map((type) => (
                                        <div
                                          key={`inline-${type}`}
                                          className="radioBx"
                                        >
                                          <Form.Check
                                            inline
                                            label="Yes"
                                            name="owns18"
                                            type={type}
                                            value="Yes"
                                            checked={
                                              Consigneeshareholder_owns ===
                                              "Yes"
                                            }
                                            id={`inline-11`}
                                            onChange={
                                              consgoineehandleOwnershipChange
                                            }
                                          />
                                          <Form.Check
                                            inline
                                            label="No"
                                            name="owns2"
                                            type={type}
                                            value="No"
                                            checked={
                                              Consigneeshareholder_owns === "No"
                                            }
                                            id={`inline-12`}
                                            onChange={
                                              consgoineehandleOwnershipChange
                                            }
                                          />
                                        </div>
                                      ))}
                                      {Consigneeshareholder_ownserr &&
                                      ConsigneeSubmit &&
                                      !Consigneeshareholder_ownserr ? (
                                        <div
                                          style={{
                                            fontFamily: "AvenirLTProLight",
                                            color: "#FF0000",
                                            opacity: 0.6,
                                          }}
                                        >
                                          {Consigneeshareholder_ownserr}
                                        </div>
                                      ) : null}
                                    </div>
                                  </Col>
                                  <Col md={12} lg={6}>
                                    <CustomForm
                                      value={ConsigneeShipper_Owner}
                                      onChange={(e) =>
                                        setConsigneeShipper_Owner(
                                          e.target.value
                                        )
                                      }
                                      color={"#222528"}
                                      type="text"
                                      lableText="Consignee Owner’s name**"
                                      placeholderText="Enter Consignee Owner’s name"
                                    />
                                    {ConsigneeShipper_Ownererr &&
                                    ConsigneeSubmit &&
                                    !ConsigneeShipper_Owner ? (
                                      <div
                                        style={{
                                          fontFamily: "AvenirLTProLight",
                                          color: "#FF0000",
                                          opacity: 0.6,
                                        }}
                                      >
                                        {ConsigneeShipper_Ownererr}
                                      </div>
                                    ) : null}
                                  </Col>
                                </Row>
                              </Col>

                              {consigneestatus === 2 && null}
                              <Col md={6}>
                                <CustomForm
                                  value={ConsigneeShipper_Owners_Address}
                                  onChange={(e) =>
                                    setConsigneeShipper_Owners_Address(
                                      e.target.value
                                    )
                                  }
                                  color={"#222528"}
                                  type="text"
                                  lableText="Consignee Owner’s Address *"
                                  placeholderText="Enter Consignee Owner’s Address"
                                />
                                {ConsigneeShipper_Owners_Addresserr &&
                                ConsigneeSubmit &&
                                !ConsigneeShipper_Owners_Address ? (
                                  <div
                                    style={{
                                      fontFamily: "AvenirLTProLight",
                                      color: "#FF0000",
                                      opacity: 0.6,
                                    }}
                                  >
                                    {ConsigneeShipper_Owners_Addresserr}
                                  </div>
                                ) : null}
                              </Col>

                              {/* <Col md={6}>
                                <CustomForm
                                  color={"#222528"}
                                  type="text"
                                  lableText="Bank Address *"
                                  placeholderText="Enter Bank Address"
                                />
                              </Col> */}
                              <Col md={12}>
                                <h3 className="s15">
                                  Shipper’s Business Bank Name Optional for all
                                  country destinations except for
                                  <span>
                                    Russia, Belarus, Iran, Cuba, Myanmar, Syria,
                                    Sudan, Iraq, Libya, Somalia, Lebanon, Yemen,
                                    Zimbabwe
                                  </span>
                                  – if shipment destination these countries then
                                  Mandatory
                                </h3>

                                <div className="mb-3">
                                  <span className="form-label">
                                    Country of destinations to these countries?
                                  </span>
                                  {["radio"].map((type) => (
                                    <div
                                      key={`inline-${type}`}
                                      className="radioBx"
                                    >
                                      <Form.Check
                                        inline
                                        label="Yes"
                                        name="owns12"
                                        checked={consigneestatus === 1}
                                        onChange={(e) =>
                                          consgineeradioHandler(1)
                                        }
                                        type={type}
                                        id={`inline-23`}
                                      />
                                      <Form.Check
                                        inline
                                        label="No"
                                        name="owns15"
                                        type={type}
                                        checked={consigneestatus === 0}
                                        onChange={(e) =>
                                          consgineeradioHandler(0)
                                        }
                                        id={`inline-24`}
                                      />
                                    </div>
                                  ))}
                                </div>
                                {ConsigneeBankCheckerr &&
                                ConsigneeSubmit &&
                                !ConsigneeBankCheck ? (
                                  <div
                                    style={{
                                      fontFamily: "AvenirLTProLight",
                                      color: "#FF0000",
                                      opacity: 0.6,
                                    }}
                                  >
                                    {ConsigneeBankCheckerr}
                                  </div>
                                ) : null}
                              </Col>
                            </Row>
                            {consigneestatus === 1 && (
                              <Col md={12}>
                                <Row>
                                  <Col md={6}>
                                    <CustomFormWithToolTip
                                      color={"#222528"}
                                      type="text"
                                      toolTopContent="Shipper’s Business Bank Name
                          Optional for all country destinations
                          except for Russia, Belarus, Iran,
                          Cuba, Myanmar, Syria, Sudan, Iraq,
                          Libya, Somalia, Lebanon, Yemen,
                          Zimbabwe – if shipment destination
                          these countries then Mandatory"
                                      lableText="Bank Name (optional except several countries)"
                                      placeholderText="Enter Bank Name"
                                      value={ConsigneeBankName}
                                      onChange={(e) =>
                                        setConsigneeBankName(e.target.value)
                                      }
                                    />
                                    {ConsigneeBankNameerr &&
                                    ConsigneeSubmit &&
                                    !ConsigneeBankName ? (
                                      <div
                                        style={{
                                          fontFamily: "AvenirLTProLight",
                                          color: "#FF0000",
                                          opacity: 0.6,
                                          marginTop: -12,
                                        }}
                                      >
                                        {ConsigneeBankNameerr}
                                      </div>
                                    ) : null}
                                  </Col>

                                  <Col md={6}>
                                    <CustomForm
                                      color={"#222528"}
                                      type="text"
                                      lableText="Bank Address *"
                                      placeholderText="Enter Bank Address"
                                      value={ConsigneeBankAdress}
                                      onChange={(e) =>
                                        setConsigneeBankAdress(e.target.value)
                                      }
                                    />
                                    {ConsigneeBankAdresserr &&
                                    ConsigneeSubmit &&
                                    !ConsigneeBankAdress ? (
                                      <div
                                        style={{
                                          fontFamily: "AvenirLTProLight",
                                          color: "#FF0000",
                                          opacity: 0.6,
                                          marginTop: -12,
                                        }}
                                      >
                                        {ConsigneeBankAdresserr}
                                      </div>
                                    ) : null}
                                  </Col>
                                </Row>
                              </Col>
                            )}
                          </Col>
                        </div>
                      )}

                      {consigneeFirstScreeningrespone == "1" && (
                        <>
                          <small className="smallText">
                            Second Screening Return Hit
                          </small>
                          <Row>
                            <Col md={6}>
                              <CustomForm
                                color={"#222528"}
                                type="date"
                                lableText="Consignee DOB *"
                                placeholderText="Enter Consignee DOB"
                                value={ConsigneeShipperDoB}
                                onChange={(e) =>
                                  setConsigneeShipperDoB(e.target.value)
                                }
                              />
                              {ConsigneeShipperDoBerr &&
                              ConsigneeSubmit &&
                              !ConsigneeShipperDoB ? (
                                <div
                                  style={{
                                    fontFamily: "AvenirLTProLight",
                                    color: "#FF0000",
                                    opacity: 0.6,
                                  }}
                                >
                                  {ConsigneeShipperDoBerr}
                                </div>
                              ) : null}
                            </Col>

                            <Col md={6}>
                              <CustomFileUpload
                                color={"#222528"}
                                type="file"
                                lableText="Consignee Passport (optional)"
                                placeholderText="Upload Passport"
                                onChange={onChangeconsignne}
                              />
                              {ShipperFileerr && Submit && !ShipperFile ? (
                                <div
                                  style={{
                                    fontFamily: "AvenirLTProLight",
                                    color: "#FF0000",
                                    opacity: 0.6,
                                  }}
                                >
                                  {ShipperFileerr}
                                </div>
                              ) : null}
                            </Col>
                          </Row>
                        </>
                      )}

                      <Button
                        onClick={() => {
                          consigneeScreeningAPi();
                        }}
                        className="btn btn-primary mb-4 mt-2 width_btn"
                      >
                        {consigneeloader == true ? (
                          <Oval
                            height="30"
                            width="30"
                            radius="30"
                            color="#f2f5f3"
                            ariaLabel="loading"
                            wrapperStyle={{ marginLeft: 100 }}
                            wrapperClass
                          />
                        ) : (
                          <>Start Screening</>
                        )}
                      </Button>

                      <ToastContainer />
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </>
          </Accordion>
        )}

        {ShipperUserType == "End User" && (
          <Accordion defaultActiveKey="0">
            <>
              <Accordion.Item eventKey="0">
                <Accordion.Header>End User</Accordion.Header>
                <Accordion.Body>
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="enduser"
                    label="End user is different from consignee"
                    checked={endUserIsDifferent}
                    onChange={handleSwitchToggle}
                  />
                  <Row>
                    <Col md={12}>
                      <Row>
                        <Col md={12}>
                          <div className="searchbtnmain_div">
                            <div
                              className="position-relative"
                              style={{ flexGrow: 1 }}
                            >
                              <CustomFormIcon
                                onChange={(event) => {
                                  setEndUserShipper_Name(event.target.value);
                                  // getsearchaPI();
                                }}
                                // onChange={(e) => setEndUserShipper_Name(e.target.value)} // set the onChange handler
                                SearchApi={EndusergetsearchaPI}
                                value={EndUsername}
                                color={"#222528"}
                                type="text"
                                lableText="End User Name"
                                readOnly={EndUsersetBox == "1" ? true : false}
                                placeholderText="Enter End User Name"
                              />
                              {showNameModal2 && (
                                <div className="modal-container">
                                  {cendUsergetSearchApi.map((item, index) => {
                                    return (
                                      <>
                                        <li
                                          key={index}
                                          onClick={() =>
                                            userhadleSelectedtName(item)
                                          }
                                        >
                                          {item}
                                        </li>
                                      </>
                                    );
                                  })}
                                </div>
                              )}
                            </div>

                            <button
                              className="btn btn-primary search_btn"
                              type="button"
                              onClick={EndusergetsearchaPI}
                            >
                              <Icon
                                icon="iconoir:search"
                                className="icon_pos"
                                fontSize={18}
                              />
                            </button>
                          </div>
                        </Col>
                        {EndUsershipperNameerr &&
                        EndUserSubmit &&
                        !EndUsername &&
                        EndUseruserType == "End User" ? (
                          <div
                            style={{
                              fontFamily: "AvenirLTProLight",
                              color: "#FF0000",
                              opacity: 0.6,
                            }}
                          >
                            {EndUsershipperNameerr}
                          </div>
                        ) : null}
                      </Row>

                      {(EndUserscreeningCheck.hitOne === EndUserScreening ||
                        EndUserscreeningCheck.hitSec === EndUserScreening) && (
                        <div>
                          <Col md={12}>
                            {/* <strong>West Coast Shipping Co.</strong> */}
                            {EndUserrisk.map((item, index) => {
                              return (
                                <div className="my-3" key={index}>
                                  <span className="rejected fw-normal">
                                    {item}
                                  </span>
                                </div>
                              );
                            })}
                            {/* <div className="my-3">
                              <span className="rejected fw-normal">
                                Risks Found
                              </span>
                              <span className="rejected mx-4">
                                UK Sanctioned List
                              </span>
                              <span className="rejected mx-4">
                                EU Sanctioned List
                              </span>
                              <span className="rejected">
                                USA Sanctioned List
                              </span>
                            </div> */}
                          </Col>

                          <Col md={12}>
                            <small className="smallText">
                              First Screening Return Hit
                            </small>

                            <Row>
                              <Col md={12} lg={6}>
                                <Row>
                                  <Col md={12} lg={6}>
                                    <div className="mb-3">
                                      <span className="form-label">
                                        Does the shareholder owns {">"} 51%
                                      </span>
                                      {["radio"].map((type) => (
                                        <div
                                          key={`inline-${type}`}
                                          className="radioBx"
                                        >
                                          <Form.Check
                                            inline
                                            label="Yes"
                                            name="owns34"
                                            type={type}
                                            value="Yes"
                                            checked={
                                              EndUsershareholder_owns === "Yes"
                                            }
                                            id={`inline-45`}
                                            onChange={
                                              EndUserhandleOwnershipChange
                                            }
                                          />
                                          <Form.Check
                                            inline
                                            label="No"
                                            name="owns44"
                                            type={type}
                                            value="No"
                                            checked={
                                              EndUsershareholder_owns === "No"
                                            }
                                            id={`inline-46`}
                                            onChange={
                                              EndUserhandleOwnershipChange
                                            }
                                          />
                                        </div>
                                      ))}
                                      {EndUsershareholder_ownserr &&
                                      EndUserSubmit &&
                                      !EndUsershareholder_owns ? (
                                        <div
                                          style={{
                                            fontFamily: "AvenirLTProLight",
                                            color: "#FF0000",
                                            opacity: 0.6,
                                          }}
                                        >
                                          {EndUsershareholder_ownserr}
                                        </div>
                                      ) : null}
                                    </div>
                                  </Col>
                                  <Col md={12} lg={6}>
                                    <CustomForm
                                      value={EndUserShipper_Owner}
                                      onChange={(e) =>
                                        setEndUserShipper_Owner(e.target.value)
                                      }
                                      color={"#222528"}
                                      type="text"
                                      lableText="End User Owner’s name**"
                                      placeholderText="Enter End User Owner’s name"
                                    />
                                    {EndUserShipper_Ownererr &&
                                    EndUserSubmit &&
                                    !EndUserShipper_Owner ? (
                                      <div
                                        style={{
                                          fontFamily: "AvenirLTProLight",
                                          color: "#FF0000",
                                          opacity: 0.6,
                                        }}
                                      >
                                        {EndUserShipper_Ownererr}
                                      </div>
                                    ) : null}
                                  </Col>
                                </Row>
                              </Col>

                              {EndUserstatus === 2 && null}
                              <Col md={6}>
                                <CustomForm
                                  value={EndUserShipper_Owners_Address}
                                  onChange={(e) =>
                                    setEndUserShipper_Owners_Address(
                                      e.target.value
                                    )
                                  }
                                  color={"#222528"}
                                  type="text"
                                  lableText="End User Owner’s Address *"
                                  placeholderText="Enter End User Owner’s Address"
                                />
                                {EndUserShipper_Owners_Addresserr &&
                                EndUserSubmit &&
                                !EndUserShipper_Owners_Address ? (
                                  <div
                                    style={{
                                      fontFamily: "AvenirLTProLight",
                                      color: "#FF0000",
                                      opacity: 0.6,
                                    }}
                                  >
                                    {EndUserShipper_Owners_Addresserr}
                                  </div>
                                ) : null}
                              </Col>

                              {/* <Col md={6}>
                                <CustomForm
                                  color={"#222528"}
                                  type="text"
                                  lableText="Bank Address *"
                                  placeholderText="Enter Bank Address"
                                />
                              </Col> */}
                              <Col md={12}>
                                <h3 className="s15">
                                  Shipper’s Business Bank Name Optional for all
                                  country destinations except for
                                  <span>
                                    Russia, Belarus, Iran, Cuba, Myanmar, Syria,
                                    Sudan, Iraq, Libya, Somalia, Lebanon, Yemen,
                                    Zimbabwe
                                  </span>
                                  – if shipment destination these countries then
                                  Mandatory
                                </h3>

                                <div className="mb-3">
                                  <span className="form-label">
                                    Country of destinations to these countries?
                                  </span>
                                  {["radio"].map((type) => (
                                    <div
                                      key={`inline-${type}`}
                                      className="radioBx"
                                    >
                                      <Form.Check
                                        inline
                                        label="Yes"
                                        name="owns1"
                                        checked={EndUserstatus === 1}
                                        onChange={(e) => EndUserradioHandler(1)}
                                        type={type}
                                        id={`inline-${type}-1`}
                                      />
                                      <Form.Check
                                        inline
                                        label="No"
                                        name="owns1"
                                        type={type}
                                        checked={EndUserstatus === 0}
                                        onChange={(e) => EndUserradioHandler(0)}
                                        id={`inline-${type}-2`}
                                      />
                                    </div>
                                  ))}
                                </div>
                                {EndUserBankCheckerr &&
                                EndUserSubmit &&
                                !EndUserBankCheck ? (
                                  <div
                                    style={{
                                      fontFamily: "AvenirLTProLight",
                                      color: "#FF0000",
                                      opacity: 0.6,
                                    }}
                                  >
                                    {EndUserBankCheckerr}
                                  </div>
                                ) : null}
                              </Col>
                            </Row>
                            {EndUserstatus === 1 && (
                              <Col md={12}>
                                <Row>
                                  <Col md={6}>
                                    <CustomFormWithToolTip
                                      color={"#222528"}
                                      type="text"
                                      toolTopContent="Shipper’s Business Bank Name
                          Optional for all country destinations
                          except for Russia, Belarus, Iran,
                          Cuba, Myanmar, Syria, Sudan, Iraq,
                          Libya, Somalia, Lebanon, Yemen,
                          Zimbabwe – if shipment destination
                          these countries then Mandatory"
                                      lableText="Bank Name (optional except several countries)"
                                      placeholderText="Enter Bank Name"
                                      value={EndUserBankName}
                                      onChange={(e) =>
                                        setEndUserBankName(e.target.value)
                                      }
                                    />
                                    {EndUserBankNameerr &&
                                    EndUserSubmit &&
                                    !EndUserBankName ? (
                                      <div
                                        style={{
                                          fontFamily: "AvenirLTProLight",
                                          color: "#FF0000",
                                          opacity: 0.6,
                                          marginTop: -12,
                                        }}
                                      >
                                        {EndUserBankNameerr}
                                      </div>
                                    ) : null}
                                  </Col>

                                  <Col md={6}>
                                    <CustomForm
                                      color={"#222528"}
                                      type="text"
                                      lableText="Bank Address *"
                                      placeholderText="Enter Bank Address"
                                      value={EndUserBankAdress}
                                      onChange={(e) =>
                                        setEndUserBankAdress(e.target.value)
                                      }
                                    />
                                    {EndUserBankAdresserr &&
                                    EndUserSubmit &&
                                    !EndUserBankAdress ? (
                                      <div
                                        style={{
                                          fontFamily: "AvenirLTProLight",
                                          color: "#FF0000",
                                          opacity: 0.6,
                                          marginTop: -12,
                                        }}
                                      >
                                        {EndUserBankAdresserr}
                                      </div>
                                    ) : null}
                                  </Col>
                                </Row>
                              </Col>
                            )}
                          </Col>
                        </div>
                      )}

                      {EndUserFirstScreeningrespone == "1" && (
                        <>
                          <small className="smallText">
                            Second Screening Return Hit
                          </small>
                          <Row>
                            <Col md={6}>
                              <CustomForm
                                color={"#222528"}
                                type="date"
                                lableText="Shipper DOB *"
                                placeholderText="Enter Shipper DOB"
                                value={EndUserShipperDoB}
                                onChange={(e) =>
                                  setEndUserShipperDoB(e.target.value)
                                }
                              />
                              {EndUserShipperDoBerr &&
                              EndUserSubmit &&
                              !EndUserShipperDoB ? (
                                <div
                                  style={{
                                    fontFamily: "AvenirLTProLight",
                                    color: "#FF0000",
                                    opacity: 0.6,
                                  }}
                                >
                                  {EndUserShipperDoBerr}
                                </div>
                              ) : null}
                            </Col>

                            <Col md={6}>
                              <CustomFileUpload
                                color={"#222528"}
                                type="file"
                                lableText="Shipper Passport (optional)"
                                placeholderText="Upload Passport"
                                onChange={onChangeEndUser}
                              />
                              {EndUserShipperFileerr &&
                              EndUserSubmit &&
                              !EndUserShipperFile ? (
                                <div
                                  style={{
                                    fontFamily: "AvenirLTProLight",
                                    color: "#FF0000",
                                    opacity: 0.6,
                                  }}
                                >
                                  {EndUserShipperFileerr}
                                </div>
                              ) : null}
                            </Col>
                          </Row>
                        </>
                      )}
                      <Button
                        onClick={() => {
                          endUserScreeningAPi();
                        }}
                        className="btn btn-primary mb-4 mt-2 width_btn"
                      >
                        {EndUserloaders == true ? (
                          <Oval
                            height="30"
                            width="30"
                            radius="30"
                            color="#f2f5f3"
                            ariaLabel="loading"
                            wrapperStyle={{ marginLeft: 100 }}
                            wrapperClass
                          />
                        ) : (
                          <>Start Screening</>
                        )}
                      </Button>

                      <ToastContainer />
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </>
          </Accordion>
        )}
      </>
    );
  };

  const Step2Content = () => {
    const [rows, setRows] = useState([
      {
        hsCode: "",
        goodsDescription: "",
        chemicalDangerios: "",
        DualUseExport: "",
      },
    ]);
    const [rowshscode, setRowshscode] = useState("");
    const [addrowstatus, setaddrowstatusl] = useState(0);
    const [Deleterow, setDeleterow] = useState(0);

    const [HsCodeSearch, setHsCodeSearch] = useState("");
    const [currentIndex, setCurrentIndex] = useState(null);

    const [excelSelectedFile, setexcelSelectedFile] = useState(null);

    const [ChemicalselectedValue, setChemicalselectedValue] = useState("");
    const [DangerousselectedValue, setDangerousselectedValue] = useState("");
    const [ControlSselectedValue, setControlSselectedValue] = useState("");

    const [selectedOption, setSelectedOption] = useState("");
    const [DualSelectedOption, setDualSelectedOption] = useState("");

    const handleSelectChange = (e) => {
      setChemicalselectedValue(e.target.value);
    };

    const handleSelectChanges = (event) => {
      setSelectedOption(event.target.value);
    };
    const handleDualSelectChanges = (event) => {
      setDualSelectedOption(event.target.value);
    };

    const handleDownload = () => {
      // Create a temporary link element
      const link = document.createElement("a");
      link.href = `${API.BASE_URL}server/data/accelBackend/DownloadSampleHscode.xlsx`;

      // Set the desired file name here
      const fileName = "DownlaodSampleHscode.xlsx";

      // Set the download attribute to the desired file name
      link.setAttribute("download", fileName);

      // Append the link to the body
      document.body.appendChild(link);

      // Trigger the click event on the link
      link.click();

      // Clean up: remove the link from the DOM
      document.body.removeChild(link);
    };

    const handleNewRows = () => {
      let newRows = []; // Create a new array to hold the new rows

      for (let i = 0; i < rows.length; i++) {
        const item = rows[i];
        if (item.hsCode === "") {
          newRows.push(item.goodsDescription);
          toast("Please fill in the HS code");
          return; // Stop code execution here
        } else if (item.goodsDescription === "") {
          newRows.push(item.hsCode);
          toast("Please fill in the goods description");
          return; // Stop code execution here
        } else if (item.chemicalDangerios === "") {
          newRows.push(item.chemicalDangerios);
          toast("Please select chemical or dangerous Good");
          return; // Stop code execution here
        } else if (item.DualUseExport === "") {
          newRows.push(item.DualUseExport);
          toast("Please select dual use export controlled goods");
          return; // Stop code execution here
        } else {
          newRows.push(item); // Add the current item to the new array
        }
      }
      // Check if new rows need to be added
      if (addrowstatus === 0) {
        newRows.push({
          hsCode: "",
          goodsDescription: "",
          chemicalDangerios: "",
          DualUseExport: "",
        });
      }

      // Update the state with the new array
      setRows(newRows);

      // Update the state with the new array
    };

    const hadleSelectedtName = (item) => {
      setRowshscode(item);
    };

    // const handleChange = async (index, fieldName, value) => {

    //   setCurrentIndex(index);

    //   const updatedRows = [...rows];
    //   updatedRows[index][fieldName] = value;

    //   // If the fieldName is 'hsCode', do not modify 'goodsDescription'
    //   if (fieldName === 'hsCode') {
    //     setRowshscode(value);
    //     setCurrentIndex(index);
    //   } else if (fieldName === 'goodsDescription') {
    //     // If the fieldName is 'goodsDescription', do not modify 'hsCode'
    //     setCurrentIndex(null);
    //   }

    //   setRows(updatedRows, () => {
    //     const debouncedSetRows = debounce(setRows);
    //     debouncedSetRows(updatedRows);
    //   });
    // };

    const handleChange = async (index, fieldName, value) => {
      setCurrentIndex(index);

      const updatedRows = [...rows];
      updatedRows[index][fieldName] = value;

      
      if (fieldName === "hsCode") {
        setRowshscode(value);
        setCurrentIndex(index);
      } else if (fieldName === "goodsDescription") {
        setRowshscode(value);
        setCurrentIndex(index);
      }

      // Add selected option value to chemicalDangerios and DualUseExport fields
      if (fieldName === "selectedOption") {
        updatedRows[index].chemicalDangerios = value === "Yes" ? "Yes" : "No";
      } else if (fieldName === "DualSelectedOption") {
        updatedRows[index].DualUseExport = value === "Yes" ? "Yes" : "No";
      }

      setRows(updatedRows, () => {
        const debouncedSetRows = debounce(setRows);
        debouncedSetRows(updatedRows);
      });
    };

    const handleFileChange = (event) => {
      const file = event.target.files[0];
      setexcelSelectedFile(file);

      HsCodeSearchExcelUpload(file);
    };

    const handleDeleteRow = (index) => {
      if (index > 0 && Deleterow == 0) {
        const updatedRows = rows.filter((_, idx) => idx !== index);
        setRows(updatedRows);
      }
    };
    const getHsCodesearch = async (index) => {
      const hsCodeToSearch = rows[index].hsCode;
      const goodsDescriptionSerach = rows[index].goodsDescription;

      const chemicalDangeriosSerach = rows[index].chemicalDangerios;
      const DualUseExportSerach = rows[index].DualUseExport; // Get the HS code from the specific row
      // Get the HS code from the specific row
      // Get the HS code from the specific row DualUseExport
      // Get the HS code from the specific row

      await fetch(`${API.BASE_URL}hscodeSearchDeatil`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          hsCodeNumber: hsCodeToSearch,
          description:goodsDescriptionSerach
        }),
      })
        .then((response) => response.json())
        .then(async (res) => {
          if (res.status && res.data.length > 0) {
            const { hsCode, description } = res.data[0];
            const updatedRows = [...rows];
            updatedRows[index] = { hsCode:hsCode, goodsDescription: description };
            setRows(updatedRows);
          } else if (hsCodeToSearch !== "") {
            const updatedRows = [...rows];
            updatedRows[index] = {
              hsCode: hsCodeToSearch,
              goodsDescription: goodsDescriptionSerach,
              chemicalDangerios: chemicalDangeriosSerach,
              DualUseExport: DualUseExportSerach,
            };
            setRows(updatedRows);
          }

          setHsCodeSearch([res.data[0].hsCode]);
        })
        .catch((error) => {});
    };

    const HsCodeSearchExcelUpload = async (file) => {
      const userData = localStorage.getItem("userDetail");
      if (userData) {
        var data = JSON.parse(userData);
      }
      let excelData = new FormData();
      excelData.append("attachments", file ? file : excelSelectedFile);

      var requestOptions = {
        method: "POST",
        body: excelData,
      };

      const response = await fetch(
        `${API.BASE_URL}uploadHsCodeSearchExcelFile`,
        requestOptions
      )
        .then((response) => response.json())
        .then(async (res) => {
          if (res.success && Array.isArray(res.data)) {
            // Map over the data array and create new rows
            const newRows = res.data.map((item) => ({
              hsCode: item.HS6,
              goodsDescription: item.Description,
              chemicalDangerios: "",
              DualUseExport: "",
            }));

            // Check if the initial empty object is present
            const hasInitialEmptyObject =
              rows.length > 0 &&
              rows[0].hsCode === "" &&
              rows[0].goodsDescription === "" &&
              rows[0].chemicalDangerios === "" &&
              rows[0].DualUseExport === "";

            // Concatenate the new rows with the existing rows state
            setRows((prevRows) =>
              hasInitialEmptyObject
                ? [...prevRows.slice(1), ...newRows] // Exclude the initial empty object
                : [...prevRows, ...newRows]
            ); // Include the initial empty object
          }
        });
    };
    const HsCodeSeacrh = async () => {
      const userData = localStorage.getItem("userDetail");
      const reference = localStorage.getItem("reference");
    
      if (!userData || !reference) {
        console.error("User data or reference not found in localStorage.");
        return; // Exit function if required data is missing
      }
    
      const data = JSON.parse(userData);
      const dataReference = JSON.parse(reference);
    
      const isValidHSCode = (hsCode) => {
        return /^\d{6}$/.test(hsCode.replace(/\./g, '')); // Removes any decimal points before checking
      };
    
      // Check if any HS code is invalid
      const invalidHSCodeRows = rows.filter((item) => !isValidHSCode(item.hsCode));
      if (invalidHSCodeRows.length > 0) {
        invalidHSCodeRows.forEach((invalidRow) => {
          toast(`Invalid HS code: ${invalidRow.hsCode}. HS code must be exactly 6 digits long.`);
        });
        return; // Exit function if there are invalid HS codes
      }
    
      // Check if any field in the rows is empty
      const isAnyFieldEmpty = rows.some((row) => Object.values(row).some((value) => value === ""));
      if (isAnyFieldEmpty) {
        toast("One or more fields in the rows are empty.");
        return; // Exit function if any field is empty
      }
    
      const payload = {
        hscodeType: rows,
        reference: dataReference,
        userId: data._id,
      };
    
      try {
        const response = await fetch(`${API.BASE_URL}addHsCodeverificationData`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          mode: "cors",
          body: JSON.stringify(payload),
        });
    
        const res = await response.json();
    
        if (res.status === true) {
          localStorage.setItem("hsCodeVerficationData", JSON.stringify(res.data));
          setaddrowstatusl(1);
          setDeleterow(1);
          setHSCodeVerification(true);
          toast("Submit successful. You can proceed to the next step.");
        } else {
          console.error("API returned false status.");
        }
      } catch (error) {
        console.error("Error in API call:", error);
      }
    };
    
  //   const HsCodeSeacrh = async () => {
  //     const userData = localStorage.getItem("userDetail");
  //     const reference = localStorage.getItem("reference");

  //     if (userData) {
  //       var data = JSON.parse(userData);
  //       var dataReference = JSON.parse(reference);
  //     }
  //     const isValidHSCode = (hsCode) => {
  //       return /^\d{6}$/.test(hsCode); // Checks if hsCode is exactly 6 digits long
  //     };
  //     // Define your rows array here
  //     rows.forEach((item) => {
  //       if (!isValidHSCode(item.hsCode)) {
  //  return  toast(`Invalid HS code: ${item.hsCode}. HS code must be 6 digits long.`);
  //         // Handle the invalid HS code case here (e.g., show an error message)
  //       } else {
  //         // HS code is valid, proceed with processing
          
  //         // Process the data further
  //       }
  //     });
  //     const isAnyFieldEmpty = rows.some((row) =>
  //       Object.values(row).some((value) => value === "")
  //     );

  //     if (isAnyFieldEmpty) {
  //       toast("One or more fields in the rows are empty.");
  //       return; // Stop execution if any field is empty
  //     }

  //     let payload = {
  //       hscodeType: rows,
  //       reference: dataReference,
  //       userId: data._id,
  //     };

  //     try {
  //       const response = await fetch(
  //         `${API.BASE_URL}addHsCodeverificationData`,
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //             Accept: "application/json",
  //           },
  //           mode: "cors",
  //           body: JSON.stringify({
  //             hscodeType: rows,
  //             reference: dataReference,
  //             userId: data._id,
  //           }),
  //         }
  //       );

  //       const res = await response.json();

  //       if (res.status === true) {
  //         await localStorage.setItem(
  //           "hsCodeVerficationData",
  //           JSON.stringify(res.data)
  //         );
  //         setaddrowstatusl(1);
  //         setDeleterow(1);
  //         setHSCodeVerification(true);
  //         toast("Submit successfully, You can proceed to next");
  //       } else {
  //         console.error("API returned false status.");
  //       }
  //     } catch (error) {
  //       console.error("Error in API call:", error);
  //     }
  //   };

    const getHsCodeSearch = async (req, res) => {
      const userData = localStorage.getItem("userDetail");
      const reference = localStorage.getItem("reference");
      const hscodeVerfication = localStorage.getItem("hsCodeVerficationData");

      // if (reference) {
      // } else {
      //   localStorage.removeItem("hsCodeVerficationData");
      // }
      if (userData) {
        var data = JSON.parse(userData);
        var dataReference = JSON.parse(reference);
        var hsCodeverfication = JSON.parse(hscodeVerfication);

        // sethscodeid(hsCodeverfication ? hsCodeverfication._id : []);
      }
      if (hsCodeverfication && hsCodeverfication.hscodeType) {
        // Create a new array to store the rows
        const newRows = [];
        hsCodeverfication.hscodeType.forEach((item) => {
          // Add each object from hsCodeverfication.hscodeType to newRows
          newRows.push({
            hsCode: item.hsCode,
            goodsDescription: item.goodDescription,
            chemicalDangerios: item.chemicalDangerios,
            DualUseExport: item.DualUseExport,
          });
        });
        // Set the rows state to the newRows array
        setRows(newRows);
      }
    };

    const sampleFileUrl = "";

    useEffect(() => {
      if (rowshscode !== "" && currentIndex !== null) {
        // getHsCodesearch(currentIndex); // Pass the current index to the function
      }
    }, [rowshscode, currentIndex]);

    useEffect(() => {
      // getECCNcodesearch();
      getHsCodeSearch();
    }, []);


    console.log('rowshscode',rowshscode);

    return (
      <>
        <div className="d-flex align-items-center">
          <h2 className="rpsHeading">HS Code Verification</h2>
          <div className="upload-btn-wrapper d-flex align-items-center">
            <div class="uploadBtn uploadBtn1">
              <Button
                className="uploadBtn uploadBtn1"
                onClick={() => handleFileChange()}
              >
                <Icon icon="carbon:add-filled" fontSize={18} /> File Upload
              </Button>
              <input
                type="file"
                name="myfile"
                accept=".xls,.xlsx"
                onChange={handleFileChange}
              />
            </div>
          </div>

          {/* <a href="/Testinghscode.xlsx" download className="textDeco ms-2">
      Download Sample
    </a> */}
          <Button
            variant="primary"
            className="w-25"
            size="md"
            onClick={handleDownload}
          >
            Download Sample
          </Button>

          <div className="ms-auto d-flex align-items-center">
            <Button
              variant="primary"
              size="md"
              className="me-1 wfix"
              onClick={() => {
                handleNewRows();
              }}
            >
              <Icon icon="pixelarticons:text-add" fontSize={18} />
              Add Row
            </Button>
          </div>
        </div>

        <>
          {rows.map((row, index) => (
            <div key={index}>
              <Row className="mb-3 mb-lg-2">
                <Col md={12}>
                  <div className="scree_btn scree_btnShrink">
                    <CustomFormIcon
                      color={"#222528"}
                      type="text"
                      lableText="HS Code Search (6 digits)"
                      value={row.hsCode}
                      onChange={(e) =>
                        handleChange(index, "hsCode", e.target.value)
                      }
                      placeholderText="Enter HS Code Search"
                    />
                    <span className="orHeading mx-3"> OR </span>
                    <div className="scree_btn scree_btn1 w-100">
                      <CustomForm
                        color={"#222528"}
                        type="text"
                        lableText="Goods Description"
                        value={row.goodsDescription}
                        onChange={(e) => {
                          handleChange(
                            index,
                            "goodsDescription",
                            e.target.value
                          );
                        }}
                        placeholderText="If HS Code is unknown enter goods description"
                      />
                    </div>
                    <div className="scree_btn scree_btn1 wWidth ms-4">
                      <Form.Group className="mb-3">
                        <Form.Label>Chemical Or Dangerous Good</Form.Label>
                        <select
                          className="form-control"
                          value={row.selectedOption || row.chemicalDangerios}
                          onChange={(e) =>
                            handleChange(
                              index,
                              "selectedOption",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select...</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </Form.Group>
                    </div>

                    <div className="scree_btn scree_btn1 wWidth">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          Dual Use Export Controlled Goods
                        </Form.Label>
                        <select
                          className="form-control"
                          value={row.DualSelectedOption || row.DualUseExport}
                          onChange={(e) =>
                            handleChange(
                              index,
                              "DualSelectedOption",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select...</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </Form.Group>
                    </div>

                    <Button
                      variant="primary"
                      size="md"
                      className="mr-1"
                      style={{ minWidth: 100 }}
                      onClick={() => handleDeleteRow(index)}
                    >
                      <Icon icon="pixelarticons:text-add" fontSize={18} />
                      Delete
                    </Button>

                    <ToastContainer />
                  </div>
                </Col>
              </Row>
            </div>
          ))}
          <div className="text-center mt-4 d-flex justify-content-center align-items-center">
        
            <Button
              variant="warning"
              size="md"
              className="btn btn-primary  width_btn me-2"
              onClick={() => HsCodeSeacrh()}
            >
              Submit
            </Button>
            <Button
              variant="warning"
              size="md"
              className="btn btn-primary  width_btn"
              onClick={() => {
                if (rowshscode !== "" && currentIndex !== null) {
                  getHsCodesearch(currentIndex); // Pass the current index to the function
                }
              }}
            >
              HS Code Search
            </Button>
          </div>
        </>

        <div className="arrowUpDown">
          <Icon
            icon="fluent:chevron-down-12-filled"
            onClick={() => setOpen(!open)}
            fontSize={18}
          />
          <Icon icon="fluent:chevron-up-12-filled" fontSize={18} />
        </div>
      </>
    );
  };

  const Step3Content = () => {
    const [Submit, setSubmit] = useState(false);
    const [showDropdown, setShowDropdown] = useState(true);
    const [hsCodeHide, sethsCodeShow] = useState(false);
    const [hscodeid, sethscodeid] = useState("");
    const [hsCodeVerificationData, sethsCodeVerificationData] = useState([]);
    const [CountryOrigin, setCountryOrigin] = useState("");
    const [DestinationOrigin, setDestinationOrigin] = useState("");
    const [CountryOrigin1, setCountryOrigin1] = useState("");
    const [DestinationOrigin1, setDestinationOrigin1] = useState("");
    const [TransDestinationOrigin, setTransDestinationOrigin] = useState("");

    const [Trans_shipment, setTrans_shipment] = useState("");
    const [Trans_shipment1, setTrans_shipment1] = useState("");
    const [Trans_shipment11, setTrans_shipment11] = useState("");

    const [Trans_shipmentDescription, setTrans_shipmentDescription] =
      useState("");
    const [Trans_shipmentDescription1, setTrans_shipmentDescription2] =
      useState("");

    const [
      Trans_shipmentDescriptionmanual,
      setTrans_shipmentDescriptionmanual,
    ] = useState("");

    const [countryCode, setcountrycode] = useState([
      {
        _id: "",
        country: "",
      },
    ]);
    const [loader, setloader] = useState(false);
    const [currentForm, setcurrentForm] = useState(0);

    const [CountryOriginerr, setCountryOriginerr] = useState("");
    const [DestinationOriginerr, setDestinationOriginerr] = useState("");
    const [Trans_shipmenterr, setTrans_shipmenterr] = useState("");
    const [Trans_shipmenterr1, setTrans_shipmenterr1] = useState("");

    const [Trans_shipmentDescriptionerr, setTrans_shipmentDescriptionerr] =
      useState("");

    const [reexportDesciption, setreexportDesciptionerr] = useState("");

    const [errorCount, seterrCount] = useState("");
    const [ECCNcodevalue, setECCNcodevalue] = useState([]);
    const [linces, setlinces] = useState([]);

    const [anyTransment, setanyTransment] = useState("");
    const [anyTransment1, setanyTransment1] = useState("");

    const onProfileValidation = async () => {
      let errorCount = 0;
      // if (SERVICE.hasError(CountryOrigin)) {
      //   errorCount++;
      //   let msg = "Please enter your country origin.";
      //   setCountryOriginerr(msg);
      // } else {
      //   setCountryOriginerr(null);
      // }

      // if (SERVICE.hasError(Trans_shipmentDescription)) {
      //   errorCount++;
      //   let msg = "Please select your country destination.";
      //   setDestinationOriginerr(msg);
      // } else {
      //   setDestinationOriginerr(null);
      // }

      if (SERVICE.hasError(Trans_shipment)) {
        errorCount++;
        let msg = "Please select any trans-shipment.";
        setTrans_shipmenterr(msg);
      } else {
        setTrans_shipmenterr(null);
      }

      if (SERVICE.hasError(Trans_shipment1)) {
        errorCount++;
        let msg = "Please select any-rexport.";
        setTrans_shipmenterr1(msg);
      } else {
        setTrans_shipmenterr1(null);
      }
      if (anyTransment == "1") {
        if (SERVICE.hasError(Trans_shipmentDescriptionmanual)) {
          errorCount++;
          let msg = "Please enter your trans-shipment destination.";
          setTrans_shipmentDescriptionerr(msg);
        } else {
          setTrans_shipmentDescriptionerr(null);
        }
      }
      if (anyTransment == "1") {
        if (SERVICE.hasError(Trans_shipmentDescription)) {
          errorCount++;
          let msg = "Please select your trans-shipment destination.";
          setreexportDesciptionerr(msg);
        } else {
          setreexportDesciptionerr(null);
        }
      }

      if (errorCount > 0) return seterrCount(errorCount);
      else seterrCount(0);
    };

    const handleOwnershipChange = (event) => {
      setanyTransment(event.target.value == "Yes" ? "1" : "");
      setTrans_shipment(event.target.value);
    };

    const handleOwnershipChange1 = (event) => {
      setanyTransment1(event.target.value == "Yes" ? "1" : "");
      setTrans_shipment1(event.target.value);
    };
    var reference_number = "";
    const getHsCodeSearch = async (req, res) => {
      const userData = localStorage.getItem("userDetail");
      const reference = localStorage.getItem("reference");
      const hscodeVerfication = localStorage.getItem("hsCodeVerficationData");

      const hscodeSanctions = JSON.parse(localStorage.getItem("Sanctions"));

      // if (reference) {

      // } else {

      //   localStorage.removeItem("hsCodeVerficationData")
      // }
      if (userData) {
        var data = JSON.parse(userData);
        var dataReference = JSON.parse(reference);
        var hsCodeverfication = JSON.parse(hscodeVerfication);

        sethsCodeVerificationData(
          hsCodeverfication ? hsCodeverfication.hscodeType : []
        );
        sethscodeid(hsCodeverfication ? hsCodeverfication._id : []);
      }
      if (hscodeSanctions) {
        setCountryOrigin1(hscodeSanctions.countryOrigin);
        setDestinationOrigin1(hscodeSanctions.countryDestination);
        setTrans_shipmentDescription(hscodeSanctions.TransShipmentDesription);
        setTrans_shipment(hscodeSanctions.anyTransShipment);
        setTrans_shipment11(hscodeSanctions.anyReexport);
        setTrans_shipmentDescriptionmanual(
          hscodeSanctions.TrandestinationDesription
        );
        sethsCodeVerificationData(
          hscodeSanctions ? hscodeSanctions.hscodeType : []
        );
        sethsCodeShow(true);
        if (hscodeSanctions.anyTransShipment == "Yes") {
          setanyTransment("1");
        }
      }
    };

    const HsCodeSeacrh = async () => {
      setSubmit(true);

      onProfileValidation();

      if (errorCount > 0) return;

      // await detectWhitespace(domain.trim());
      const userData = localStorage.getItem("userDetail");
      const reference = localStorage.getItem("reference");

      if (userData) {
        var data = JSON.parse(userData);
        var dataReference = JSON.parse(reference);
      }

      let payload = {
        hscodeType: hsCodeVerificationData,
        hsCodeVerifiyID: hscodeid,
        userId: data._id,
        countryOrigin: CountryOrigin1,
        countryDestination: DestinationOrigin1,
        anyTransShipment: Trans_shipment,
        TransShipmentDesription: Trans_shipmentDescription1,
        TrandestinationDesription: Trans_shipmentDescriptionmanual,

        anyReexport: Trans_shipment1,
      };

      await fetch(`${API.BASE_URL}addHsCodeverificationData`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        mode: "cors",
        body: JSON.stringify({
          hscodeType: hsCodeVerificationData,
          hsCodeVerifiyID: hscodeid,
          userId: data._id,
          countryOrigin: CountryOrigin,
          countryDestination: DestinationOrigin,
          anyTransShipment: Trans_shipment,
          TransShipmentDesription: Trans_shipmentDescription,
          TrandestinationDesription: Trans_shipmentDescriptionmanual,

          anyReexport: Trans_shipment1,

          // CountryId:countryCode._id,
        }),
      })
        .then((response) => response.json())
        .then(async (res) => {
          await localStorage.setItem("Sanctions", JSON.stringify(payload));

          if (res.status == true) {
            if (res.data.hscodeType[0].EccN) {
              setCodeSanctions(true);
              setcurrentForm(0);
            } else {
              setcurrentForm(1);
            }

            toast("Submit successfully, You can proceed to next");
            await localStorage.setItem(
              "hsCodeVerficationData",
              JSON.stringify(res.data)
            );
            if (hsCodeVerificationData[0].EccN) {
            } else {
              hsToggle();
            }
          }
        })
        .catch((error) => {});
    };

    const countrycodeSearch = async () => {
      setloader(true);
      await fetch(`${API.BASE_URL}CountryCodeSearchDeatil`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        mode: "cors",
        body: JSON.stringify({}),
      })
        .then((response) => response.json())
        .then(async (res) => {
          if (res.status === true) {
            const formattedData = res.data.map((country) => ({
              _id: country._id,
              country: country.country,
            }));
            setcountrycode(formattedData);
            countryCodeData.push(formattedData);
            setloader(false);

            // await localStorage.setItem("hsCodeVerficationData", JSON.stringify(res.data));
            // hsToggle()
          }
        })
        .catch((error) => {});
    };

    const [ECCNcode, setECCNcode] = useState([]);

    const [showNameModal, setShowNameModal] = useState(false);

    const hadleSelectedtName = (item) => {
      setShowNameModal(false);
    };

    const getECCNcodesearch = async (eccn) => {
      let payload = {
        EccnCode: eccn,
      };

      await fetch(`${API.BASE_URL}eccnCodeSearchDeatil`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          EccnCode: eccn,
        }),
      })
        .then((response) => response.json())
        .then(async (res) => {
          const formattedData = res.data.map((esCode) => ({
            esCode: esCode,
          }));

          setECCNcode(formattedData);

          setShowNameModal(true);
        })
        .catch((error) => {});
    };

    useEffect(() => {
      if (ECCNcodevalue === "") {
        setShowNameModal(false);
      }
    }, [ECCNcodevalue]);
    useEffect(() => {
      // getECCNcodesearch();
      getHsCodeSearch();
      countrycodeSearch();
    }, []);

    useEffect(() => {
      onProfileValidation();
    }, [
      CountryOrigin,
      DestinationOrigin,
      Trans_shipment,
      Trans_shipmentDescription,
    ]);

    const hsToggle = () => {
      sethsCodeShow(!hsCodeHide);
    };

    const handleHsSearch = () => {
      const isBlank = hsCodeVerificationData.some(
        (item) => !item.goodDescription || !item.EccN || !item.licines
      );
      if (isBlank && hsCodeHide == true) {
        toast("Please fill in all fields before searching.");
      } else {
        HsCodeSeacrh();
      }
    };

    return (
      <>
        <div className="d-flex align-items-center">
          <h2 className="rpsHeading">HS Code Sanctions</h2>
        </div>

        <Row>
          <Col md={12}>
            <Card className="mb-3 mb-lg-4 mb-md-4 card-boxx">
              <Card.Body>
                {hsCodeVerificationData.length > 0 &&
                  hsCodeVerificationData.map((item, index) => {
                    return (
                      <div>
                        <Row key={index}>
                          <Col lg={3}>
                            <div className="repeat_sec">
                              <CustomForm
                                color={"#222528"}
                                type="text"
                                value={item.hsCode}
                                lableText="Goods Description *"
                                placeholderText="Enter Goods Description"
                              />
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div className="repeat_sec">
                              <CustomForm
                                color={"#222528"}
                                type="text"
                                value={item.goodDescription}
                                lableText="HS Code or Taric Code (EU)"
                                placeholderText="Enter HS Code or Taric Code EU"
                              />

                              {/* <Icon
                        icon="ic:outline-delete"
                        className="delete"
                        fontSize={22}
                      /> */}
                            </div>
                          </Col>

                          <Col lg={3}>
                            <div className="repeat_sec">
                              <CustomForm
                                color={"#222528"}
                                type="text"
                                value={item.chemicalDangerios}
                                lableText="Chemical Or Dangerous Good *"
                                placeholderText="Enter Goods Description"
                              />
                            </div>
                          </Col>

                          <Col lg={3}>
                            <div className="repeat_sec">
                              <CustomForm
                                color={"#222528"}
                                type="text"
                                value={item.DualUseExport}
                                lableText="Dual Use Export Controlled Goods?*"
                                placeholderText="Enter Goods Description"
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    );
                  })}
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mb-3 mb-lg-2">
          {/* <Col md={6}>
            <CustomFormIcon
              color={"#222528"}
              type="text"
              value={CountryOrigin}
              onChange={(e)=>setCountryOrigin(e.target.value)}
              lableText="Country Origin of Shipment *"
              placeholderText="Enter Country Origin of Shipment"
            />
          </Col> */}

          <Col md={6}>
            <select
              className="form-control"
              value={CountryOrigin}
              onChange={(e) => {
                setCountryOrigin(e.target.value);
                const selectedCountry = countryCode.find(
                  (country) => country._id === e.target.value
                );
                if (selectedCountry) {
                  setCountryOrigin1(selectedCountry.country);
                }
              }}
            >
              <option>
                {" "}
                {CountryOrigin1
                  ? CountryOrigin1
                  : "Select Origin Country of Shipment"}
              </option>
              {loader && <option disabled>Loading...</option>}
              {!loader &&
                countryCode.map((country) => (
                  <option key={country._id} value={country._id}>
                    {country.country}
                  </option>
                ))}
            </select>

            {CountryOriginerr && Submit && !CountryOrigin ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                }}
              >
                {CountryOriginerr}
              </div>
            ) : null}
          </Col>

          <Col md={6}>
            <select
              className="form-control"
              value={DestinationOrigin}
              onChange={(e) => {
                setDestinationOrigin(e.target.value);
                const selectedCountry = countryCode.find(
                  (country) => country._id === e.target.value
                );
                if (selectedCountry) {
                  setDestinationOrigin1(selectedCountry.country);
                }
              }}
            >
              <option value="" disabled={loader}>
                {DestinationOrigin1
                  ? DestinationOrigin1
                  : "Select Origin Country of Shipment"}
              </option>
              {loader && <option disabled>Loading...</option>}
              {!loader &&
                countryCode.map((country) => (
                  <option key={country._id} value={country._id}>
                    {country.country}
                  </option>
                ))}
            </select>
            {/* {DestinationOriginerr && Submit && !DestinationOrigin ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                }}
              >
                {DestinationOriginerr}
              </div>
            ) : null} */}
          </Col>
        </Row>

        <Row>
          <Col md={12} lg={6}>
            <div className="mb-3">
              <span className="form-label">Any Trans-shipment?</span>
              {["radio"].map((type) => (
                <div key={`inline-${type}`} className="radioBx">
                  <Form.Check
                    inline
                    label="Yes"
                    name="group1"
                    type={type}
                    value="Yes"
                    checked={Trans_shipment === "Yes"}
                    id={`inline-${type}-1`}
                    onChange={handleOwnershipChange}
                  />
                  <Form.Check
                    inline
                    label="No"
                    name="group1"
                    type={type}
                    value="No"
                    checked={Trans_shipment === "No"}
                    id={`inline-${type}-2`}
                    onChange={handleOwnershipChange}
                  />
                </div>
              ))}
            </div>
            {Trans_shipmenterr && Submit && !Trans_shipment ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                }}
              >
                {Trans_shipmenterr}
              </div>
            ) : null}
          </Col>

          {anyTransment == "1" ? (
            <>
              {" "}
              <Col md={12} lg={6}>
                <Col md={12}>
                  <div className="repeat_sec">
                    <CustomForm
                      color={"#222528"}
                      type="text"
                      lableText="Trans-shipment Destination *"
                      placeholderText="Trans-shipment Destination"
                      value={Trans_shipmentDescriptionmanual}
                      onChange={(e) =>
                        setTrans_shipmentDescriptionmanual(e.target.value)
                      }
                    />

                    {Trans_shipmentDescriptionerr &&
                    Submit &&
                    !Trans_shipmentDescriptionmanual ? (
                      <div
                        style={{
                          fontFamily: "AvenirLTProLight",
                          color: "#FF0000",
                          opacity: 0.6,
                        }}
                      >
                        {Trans_shipmentDescriptionerr}
                      </div>
                    ) : null}
                  </div>
                  {/* <select
                className="form-control"
                value={Trans_shipmentDescription}
                onChange={(e) => setTrans_shipmentDescription(e.target.value)}
              >
                <option value="">Select Any Trans-shipment Destination</option>

                {loader && <option disabled>Loading...</option>}
                {!loader && countryCode.map(country => (
                  <option key={country._id} value={country._id}>
                    {country.country}
                  </option>
                ))}

              </select>
              {Trans_shipmentDescriptionerr && Submit && !Trans_shipmentDescription ? (
                <div
                  style={{
                    fontFamily: "AvenirLTProLight",
                    color: "#FF0000",
                    opacity: 0.6,
                  }}
                >
                  {Trans_shipmentDescriptionerr}
                </div>
              ) : null} */}
                </Col>

                {/* <CustomForm
              color={"#222528"}
              type="text"
              lableText="Trans-shipment Description *"
              value={Trans_shipmentDescription}
              onChange={(e) => setTrans_shipmentDescription(e.target.value)}
              placeholderText="Enter Trans-shipment Description"
            />
            {Trans_shipmentDescriptionerr && Submit && !Trans_shipmentDescription ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                }}
              >
                {Trans_shipmentDescriptionerr}
              </div>
            ) : null} */}
              </Col>
            </>
          ) : (
            <></>
          )}
        </Row>

        <Row>
          <Col md={12} lg={6}>
            <div className="mb-3">
              <span className="form-label">Any Re-Export?</span>
              {["radio"].map((type) => (
                <div key={`inline-${type}`} className="radioBx">
                  <Form.Check
                    inline
                    label="Yes"
                    name="group11"
                    type={type}
                    value="Yes"
                    checked={
                      Trans_shipment11 == "Yes" || Trans_shipment1 === "Yes"
                    }
                    id={`inline-11`}
                    onChange={handleOwnershipChange1}
                  />
                  <Form.Check
                    inline
                    label="No"
                    name="group12"
                    type={type}
                    value="No"
                    checked={
                      Trans_shipment11 == "No" || Trans_shipment1 === "No"
                    }
                    id={`inline-12`}
                    onChange={handleOwnershipChange1}
                  />
                </div>
              ))}
            </div>
            {Trans_shipmenterr1 && Submit && !Trans_shipment1 ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                }}
              >
                {Trans_shipmenterr1}
              </div>
            ) : null}
          </Col>

          {anyTransment == "1" ? (
            <>
              {" "}
              <Col md={12} lg={6}>
                <Col md={12}>
                  <select
                    className="form-control"
                    value={Trans_shipmentDescription}
                    onChange={(e) => {
                      setTrans_shipmentDescription(e.target.value);
                      const selectedCountry = countryCode.find(
                        (country) => country._id === e.target.value
                      );
                      if (selectedCountry) {
                        setTrans_shipmentDescription2(selectedCountry.country);
                      }
                    }}
                  >
                    <option value="">
                      {Trans_shipmentDescription
                        ? Trans_shipmentDescription
                        : "Select Any Trans-shipment Destination"}
                    </option>

                    {loader && <option disabled>Loading...</option>}
                    {!loader &&
                      countryCode.map((country) => (
                        <option key={country._id} value={country._id}>
                          {country.country}
                        </option>
                      ))}
                  </select>
                  {reexportDesciption &&
                  Submit &&
                  !Trans_shipmentDescription ? (
                    <div
                      style={{
                        fontFamily: "AvenirLTProLight",
                        color: "#FF0000",
                        opacity: 0.6,
                      }}
                    >
                      {reexportDesciption}
                    </div>
                  ) : null}
                </Col>

                {/* <CustomForm
              color={"#222528"}
              type="text"
              lableText="Trans-shipment Description *"
              value={Trans_shipmentDescription}
              onChange={(e) => setTrans_shipmentDescription(e.target.value)}
              placeholderText="Enter Trans-shipment Description"
            />
            {Trans_shipmentDescriptionerr && Submit && !Trans_shipmentDescription ? (
              <div
                style={{
                  fontFamily: "AvenirLTProLight",
                  color: "#FF0000",
                  opacity: 0.6,
                }}
              >
                {Trans_shipmentDescriptionerr}
              </div>
            ) : null} */}
              </Col>
            </>
          ) : (
            <></>
          )}
        </Row>
        {hsCodeHide && (
          <Row>
            <Col md={12}>
              <Card className="mb-3 mb-lg-4 mb-md-4 card-boxx">
                <Card.Body>
                  <Table responsive className="table_Box">
                    <thead>
                      <tr>
                        <th>Goods Description</th>
                        <th>Chemical Or Dangerous Good</th>

                        <th>Dual Use Export Controlled Goods</th>

                        <th>
                          ECCN/<strong>Taric-DU#</strong>
                        </th>
                        <th>No Licence Required?</th>
                      </tr>
                    </thead>
                    <tbody>
                      {hsCodeVerificationData.length > 0 &&
                        hsCodeVerificationData.map((item, index) => {
                          return (
                            <>
                              <tr key={index}>
                                <td>
                                  <Form.Group>
                                    <Form.Control
                                      type={"text"}
                                      placeholder={"Enter Goods Description"}
                                      value={item.goodDescription}
                                    />
                                  </Form.Group>
                                </td>

                                <td>
                                  <Form.Group>
                                    <Form.Control
                                      type={"text"}
                                      placeholder={
                                        "Chemical Or Dangerous Good "
                                      }
                                      value={item.chemicalDangerios}
                                    />
                                  </Form.Group>
                                </td>

                                <td>
                                  <Form.Group>
                                    <Form.Control
                                      type={"text"}
                                      placeholder={
                                        "Dual Use Export Controlled Goods?"
                                      }
                                      value={item.DualUseExport}
                                    />
                                  </Form.Group>
                                </td>
                                <td>
                                  <Form.Group>
                                    <Form.Control
                                      type={"text"}
                                      placeholder={"Enter ECCN/Taric-DU# "}
                                      value={item.EccN} // Use item.EccN for the value
                                      onChange={(e) => {
                                        const newValue = e.target.value;
                                        sethsCodeVerificationData(
                                          (prevData) => {
                                            const newData = [...prevData];
                                            newData[index].EccN = newValue;
                                            // Update EccN for specific index
                                            getECCNcodesearch(e.target.value);
                                            setECCNcodevalue(e.target.value);
                                            return newData;
                                          }
                                        );
                                      }}
                                    />
                                  </Form.Group>
                                </td>
                                <td>
                                  <Form.Group>
                                    <Form.Check
                                      type="checkbox"
                                      // label="Dual Use"
                                      checked={item.licines === "Yes"} // Checked if item.licines is "Yes"
                                      onChange={(e) => {
                                        const isChecked = e.target.checked;
                                        const newValue = isChecked
                                          ? "Yes"
                                          : "No";
                                        sethsCodeVerificationData(
                                          (prevData) => {
                                            const newData = [...prevData];
                                            newData[index].licines = newValue;
                                            // Update licines for specific index
                                            setlinces(newValue); // Assuming setlinces is the correct setter function for licines
                                            return newData;
                                          }
                                        );
                                      }}
                                    />
                                  </Form.Group>
                                  {/* Conditional rendering for icon */}
                                  {/* {item.licines === "Yes" ? (
                                    <FontAwesomeIcon
                                      icon={faCheck}
                                      style={{ color: "green" }}
                                    />
                                  ) : item.licines === "No" ? (
                                    <FontAwesomeIcon
                                      icon={faTimes}
                                      style={{ color: "red" }}
                                    />
                                  ) : null} */}
                                </td>

                                {/* <td>
                                  <Form.Group>
                                    <Form.Control
                                      as="select"
                                      placeholder="Not Dual Use"
                                      value={item.licines}
                                      onChange={(e) => {
                                        const newValue = e.target.value;
                                        sethsCodeVerificationData(
                                          (prevData) => {
                                            const newData = [...prevData];
                                            newData[index].licines = newValue;
                                            // Update licines for specific index
                                            setlinces(newValue); // Assuming setlinces is the correct setter function for licines
                                            return newData;
                                          }
                                        );
                                      }}
                                    >
                                      <option value="">Select Option</option>

                                      <option value="Yes">Yes</option>
                                      <option value="No">No</option>

                                      {/* Add more options as needed */}
                                {/* </Form.Control>
                                  </Form.Group>
                                </td> */}
                              </tr>
                            </>
                          );
                        })}
                      {/* {showNameModal &&
                        <div>{ECCNcode.map((item, index) => {
                          return (<>
                            <li key={index}
                              onClick={() => hadleSelectedtName(item.EccN)}
                            >
                              {item.esCode.esCode}
                            </li>
                          </>)
                        })}</div>

                      } */}

                      {/* <tr>
                        <td>
                          <Form.Group>
                            <Form.Control
                              type={"text"}
                              placeholder={"Enter Goods Description"}
                            />
                          </Form.Group>
                        </td>
                        <td>
                          <Form.Group>
                            <Form.Control
                              type={"text"}
                              placeholder={"Enter ECCN/Taric-DU#"}
                            />
                          </Form.Group>
                        </td>
                        <td>
                          <Form.Group>
                            <Form.Control
                              type={"text"}
                              placeholder={"Not Dual Use"}
                            />
                          </Form.Group>
                        </td>
                      </tr> */}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}

        <div className="text-center mt-2">
          <Button
            className="btn btn-primary  width_btn"
            variant="primary"
            onClick={() => {
              handleHsSearch();
            }}
            size="md"
          >
            Submit
          </Button>
          <ToastContainer />
        </div>

        <div className="arrowUpDown">
          <Icon
            icon="fluent:chevron-down-12-filled"
            onClick={() => setOpen(!open)}
            fontSize={18}
          />
          <Icon icon="fluent:chevron-up-12-filled" fontSize={18} />
        </div>
      </>
    );
  };

  const Step4Content = () => {
    const [hsCodeVerificationData, sethsCodeVerificationData] = useState([]);
    const [hsCodeHide, sethsCodeShow] = useState(false);
    const [hscodeid, sethscodeid] = useState("");
    const [CountryOrigin, setCountryOrigin] = useState("");
    const [DestinationOrigin, setDestinationOrigin] = useState("");
    const [Trans_shipment, setTrans_shipment] = useState("");
    const [Trans_shipmentDescription, setTrans_shipmentDescription] =
      useState("");

    const getHsCodeSearch = async (req, res) => {
      const userData = localStorage.getItem("userDetail");
      const reference = localStorage.getItem("reference");
      const hscodeVerfication = localStorage.getItem("hsCodeVerficationData");

      if (userData) {
        var data = JSON.parse(userData);
        var dataReference = JSON.parse(reference);
        var hsCodeverfication = JSON.parse(hscodeVerfication);

        sethsCodeVerificationData(
          hsCodeverfication && hsCodeverfication.hscodeType
        );
        setTrans_shipmentDescription(
          hsCodeverfication && hsCodeverfication.TransShipmentDesription
        );
        setCountryOrigin(hsCodeverfication && hsCodeverfication.countryOrigin);
        setDestinationOrigin(
          hsCodeverfication && hsCodeverfication.countryDestination
        );
        setTrans_shipment(
          hsCodeverfication && hsCodeverfication.anyTransShipment
        );
        sethscodeid(hsCodeverfication && hsCodeverfication._id);

        // sethscodeid(hsCodeverfication._id)
      }
    };

    useEffect(() => {
      // getECCNcodesearch();
      getHsCodeSearch();
      // countrycodeSearch()
    }, []);

    const Dual_Use_Goods = async () => {
      // setSubmit(true);
      // setshipperloader(true)
      // onProfileValidation();

      // if (errorCount > 0) return;
      // await detectWhitespace(domain.trim());
      const userData = localStorage.getItem("userDetail");
      const reference = localStorage.getItem("reference");

      if (userData) {
        var data = JSON.parse(userData);
        var dataReference = JSON.parse(reference);
      }

      let payload = {
        hscodeType: hsCodeVerificationData,
        hsCodeVerifiyID: hscodeid,
        userId: data._id,
        countryOrigin: CountryOrigin,
        countryDestination: DestinationOrigin,
        anyTransShipment: Trans_shipment,
        TransShipmentDesription: Trans_shipmentDescription,
      };

      await fetch(`${API.BASE_URL}addHsCodeverificationData`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        mode: "cors",
        body: JSON.stringify({
          hscodeType: hsCodeVerificationData,
          hsCodeVerifiyID: hscodeid,
          userId: data._id,
          countryOrigin: CountryOrigin,
          countryDestination: DestinationOrigin,
          anyTransShipment: Trans_shipment,
          TransShipmentDesription: Trans_shipmentDescription,

          // CountryId:countryCode._id,
        }),
      })
        .then((response) => response.json())
        .then(async (res) => {
          if (res.status == true) {
            toast("Submit successfully, You can proceed to next");
            setDualUseGoods(true);
            await localStorage.setItem(
              "hsCodeVerficationData",
              JSON.stringify(res.data)
            );
            if (hsCodeVerificationData[0].EccN) {
            }
          }
        })
        .catch((error) => {});
    };

    return (
      <>
        <h2 className="rpsHeading">Dual-Use Goods</h2>

        <Row>
          <Col md={12}>
            <Card className="mb-3 mb-lg-4 mb-md-4 card-boxx card-boxx_mar">
              <Card.Body>
                <div className="repeat_sec repeat_bbbox">
                  <Table responsive className="table_Box table_Box12">
                    <thead>
                      <tr>
                        <th>Goods Description</th>
                        <th>
                          HS code or
                          <br /> Taric Code <br /> EU*
                        </th>
                        <th>
                          Chemical Or
                          <br /> Dangerous Good <br />
                        </th>
                        <th>
                          Dual Use Export
                          <br /> Controlled Goods <br />
                        </th>
                        <th>ECCN or EU DUXXX#</th>
                        {/* <th>Is DG?</th>
                      <th>Not DG?</th> */}

                        <th>No Licence Required?</th>
                        <th>Sanctioned?</th>
                        {/* <th>Re Export</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {hsCodeVerificationData &&
                        hsCodeVerificationData.length > 0 &&
                        hsCodeVerificationData.map((item, index) => {
                          return (
                            <>
                              {" "}
                              <tr key={index}>
                                <td>
                                  <Form.Group>
                                    <Form.Control
                                      type={"text"}
                                      placeholder={"Enter Goods Description"}
                                      value={item.goodDescription}
                                    />
                                  </Form.Group>
                                </td>
                                <td>
                                  <Form.Group>
                                    <Form.Control
                                      type={"text"}
                                      placeholder={
                                        "HS code or Taric Code (EU) "
                                      }
                                      value={item.hsCode}
                                    />
                                  </Form.Group>
                                </td>

                                <td>
                                  <Form.Group>
                                    <Form.Control
                                      type={"text"}
                                      placeholder={
                                        "Chemical Or Dangerous Good  "
                                      }
                                      value={item.chemicalDangerios}
                                    />
                                  </Form.Group>
                                </td>

                                <td>
                                  <Form.Group>
                                    <Form.Control
                                      type={"text"}
                                      placeholder={
                                        "Dual Use Export Controlled Goods"
                                      }
                                      value={item.DualUseExport}
                                    />
                                  </Form.Group>
                                </td>
                                <td>
                                  <Form.Group>
                                    <Form.Control
                                      type={"text"}
                                      placeholder={"Enter ECCN or EU DUXXX#"}
                                      value={item.EccN}
                                    />
                                  </Form.Group>
                                </td>

                                {/* <td>
                            <div className="crBox">
                              <Image src={RightIcon} alt="" />
                            </div>
                          </td>
                          <td>
                            <div className="crBox">
                              <Image src={RightIcon} alt="" />
                            </div>
                          </td> */}
                                <td>
                                  <Form.Group>
                                    {item.licines === "Yes" ? (
                                      <FontAwesomeIcon
                                        icon={faCheck}
                                        style={{
                                          color: "green",
                                          marginLeft: 5,
                                        }}
                                      />
                                    ) : item.licines === "No" ? (
                                      <FontAwesomeIcon
                                        icon={faTimes}
                                        style={{ color: "red", marginLeft: 5 }}
                                      />
                                    ) : null}
                                    {/* <Form.Control
                                      type={"text"}
                                      placeholder={"Not Dual Use"}
                                      value={item.licines}
                                    /> */}
                                  </Form.Group>
                                </td>
                                <td>
                                  <Form.Group>
                                    <select
                                      className="form-control"
                                      value={
                                        hsCodeVerificationData[index].Sanctioned
                                      }
                                      onChange={(e) => {
                                        const newValue = e.target.value;

                                        sethsCodeVerificationData(
                                          (prevData) => {
                                            const newData = [...prevData];
                                            newData[index].Sanctioned =
                                              newValue;
                                            // Update EccN for specific index
                                            // getECCNcodesearch(newValue);
                                            // setECCNcodevalue(newValue);
                                            return newData;
                                          }
                                        );
                                      }}
                                    >
                                      <option value="Yes">Yes</option>
                                      <option value="No">No</option>
                                      {/* 
  {countryCode.map(country => (
    <option key={country._id} value={country._id}>
      {country.country}
    </option>
  ))} */}
                                    </select>
                                    {/* <Form.Control type={"text"} placeholder={"Yes"} value={item.EccN} // Use item.EccN for the value
                                onChange={(e) => {
                                  const newValue = e.target.value;
                                  sethsCodeVerificationData(prevData => {
                                    const newData = [...prevData];
                                    newData[index].EccN = newValue; 
                                    // Update EccN for specific index
                                    getECCNcodesearch(e.target.value)
                                    setECCNcodevalue(e.target.value)
                                    return newData;
                                  });
                                }}/> */}
                                  </Form.Group>
                                </td>
                                {/* <td>
                            <Form.Group>
                              <select
                                className="form-control"
                                value={hsCodeVerificationData[index].ReExport}
                                onChange={(e) => {
                                  const newValue = e.target.value;

                                  sethsCodeVerificationData(prevData => {
                                    const newData = [...prevData];
                                    newData[index].ReExport = newValue;
                                    // Update EccN for specific index
                                    // getECCNcodesearch(newValue);
                                    // setECCNcodevalue(newValue);
                                    return newData;
                                  });
                                }}
                              >
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                         
                              </select>
                            
                            </Form.Group>
                          </td> */}
                              </tr>
                            </>
                          );
                        })}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>

            <div className="text-center">
              <Button
                className="btn btn-primary mt-2 width_btn"
                onClick={() => Dual_Use_Goods()}
              >
                Start Screening
              </Button>
              <ToastContainer />
            </div>
          </Col>
        </Row>
        <div className="arrowUpDown">
          <Icon
            icon="fluent:chevron-down-12-filled"
            onClick={() => setOpen(!open)}
            fontSize={18}
          />
          <Icon icon="fluent:chevron-up-12-filled" fontSize={18} />
        </div>
      </>
    );
  };

  const Step5Content = () => {
    const [countrtDetail, setcountrtDetail] = useState([]);

    const getHsCodeSearch = async (req, res) => {
      const userData = localStorage.getItem("userDetail");
      const reference = localStorage.getItem("reference");
      const hscodeVerfication = localStorage.getItem("hsCodeVerficationData");

      if (userData) {
        var data = JSON.parse(userData);
        var dataReference = JSON.parse(reference);
        var hsCodeverfication = JSON.parse(hscodeVerfication);

        Re_export_Country_Control(
          hsCodeverfication && hsCodeverfication.TransShipmentDesription
        );
      }
    };

    const Re_export_Country_Control = async (id) => {
      const userData = localStorage.getItem("userDetail");
      const reference = localStorage.getItem("reference");

      if (userData) {
        var data = JSON.parse(userData);
        var dataReference = JSON.parse(reference);
      }

      let payload = {
        countryDestitation: id,
      };

      await fetch(`${API.BASE_URL}getRe_export_Country_Control`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        mode: "cors",
        body: JSON.stringify({
          countryDestitation: id,
        }),
      })
        .then((response) => response.json())
        .then(async (res) => {
          if (res.status == true) {
            setcountrtDetail(res.data);
            // toast('Verrification successfull')
            //   await localStorage.setItem("hsCodeVerficationData", JSON.stringify(res.data));
            //   if(hsCodeVerificationData[0].EccN){

            //   }else{
            //     hsToggle()
            //   }
          }
        })
        .catch((error) => {});
    };

    useEffect(() => {
      getHsCodeSearch();
      // Re_export_Country_Control();

      // countrycodeSearch()
    }, []);

    return (
      <>
        <h2 className="rpsHeading">Re-export Country Control</h2>

        <Row>
          <Col md={12}>
            <Row>
              <Col md={12}>
                <Card className="mb-3 mb-lg-4 mb-md-4 card-boxx card-boxx_mar">
                  <Card.Body className="newtabLe">
                    <Table bordered responsive>
                      <thead>
                        <tr>
                          <th style={{ minWidth: "130px" }}>Country</th>
                          <th style={{ minWidth: "130px" }}>
                            <div className="text-center">
                              Chemical and
                              <br /> biological
                              <br /> weapons
                            </div>
                            <div className="cxBox">
                              <span>CB1</span>
                              <span>CB2</span>
                              <span>CB3</span>
                            </div>
                          </th>
                          <th style={{ minWidth: "130px" }}>
                            <div className="text-center">
                              Nuclear Non
                              <br /> Proliferation
                            </div>
                            <div className="cxBox">
                              <span>NP1</span>
                              <span>NP2</span>
                            </div>
                          </th>
                          <th style={{ minWidth: "130px" }}>
                            <div className="text-center">
                              National
                              <br /> Security
                            </div>
                            <div className="cxBox">
                              <span>NS1</span>
                              <span>NS2</span>
                            </div>
                          </th>
                          <th style={{ minWidth: "130px" }}>
                            <div className="text-center">
                              Missile
                              <br /> Tech
                            </div>
                            <div className="cxBox">MT1</div>
                          </th>
                          <th style={{ minWidth: "130px" }}>
                            <div className="text-center">
                              Regional
                              <br /> Stability
                            </div>
                            <div className="cxBox">
                              <span>RS1</span>
                              <span>RS2</span>
                            </div>
                          </th>
                          <th style={{ minWidth: "130px" }}>
                            <div className="text-center">
                              Firearms
                              <br /> Convention
                            </div>
                            <div className="cxBox">
                              <span>FC1</span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {countrtDetail.map((item, index) => {
                          return (
                            <tr key={index}>
                              <th>{item.country}</th>
                              <th>
                                <div className="cxBox mt-0">
                                  <span>
                                    {item.CB == "0" ? (
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip}
                                      >
                                        <Image src={CorssIconRed} alt="" />
                                      </OverlayTrigger>
                                    ) : (
                                      <Image src={RightIcon} alt="" />
                                    )}
                                  </span>
                                  <span>
                                    {item.CB1 == "0" ? (
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip}
                                      >
                                        <Image src={CorssIconRed} alt="" />
                                      </OverlayTrigger>
                                    ) : (
                                      <Image src={RightIcon} alt="" />
                                    )}
                                  </span>
                                  <span>
                                    {item.CB2 == "0" ? (
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip}
                                      >
                                        <Image src={CorssIconRed} alt="" />
                                      </OverlayTrigger>
                                    ) : (
                                      <Image src={RightIcon} alt="" />
                                    )}
                                  </span>
                                </div>
                              </th>
                              <th>
                                <div className="cxBox mt-0">
                                  <span>
                                    {item.CB3 == "0" ? (
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip}
                                      >
                                        <Image src={CorssIconRed} alt="" />
                                      </OverlayTrigger>
                                    ) : (
                                      <Image src={RightIcon} alt="" />
                                    )}
                                  </span>
                                  <span>
                                    {item.CB4 == "0" ? (
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip}
                                      >
                                        <Image src={CorssIconRed} alt="" />
                                      </OverlayTrigger>
                                    ) : (
                                      <Image src={RightIcon} alt="" />
                                    )}
                                  </span>
                                </div>
                              </th>
                              <th>
                                <div className="cxBox mt-0">
                                  <span>
                                    {item.CB5 == "0" ? (
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip}
                                      >
                                        <Image src={CorssIconRed} alt="" />
                                      </OverlayTrigger>
                                    ) : (
                                      <Image src={RightIcon} alt="" />
                                    )}
                                  </span>
                                  <span>
                                    {item.CB6 == "0" ? (
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip}
                                      >
                                        <Image src={CorssIconRed} alt="" />
                                      </OverlayTrigger>
                                    ) : (
                                      <Image src={RightIcon} alt="" />
                                    )}
                                  </span>
                                </div>
                              </th>
                              <th>
                                <div className="cxBox mt-0">
                                  <span>
                                    {item.CB7 == "0" ? (
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip}
                                      >
                                        <Image src={CorssIconRed} alt="" />
                                      </OverlayTrigger>
                                    ) : (
                                      <Image src={RightIcon} alt="" />
                                    )}
                                  </span>
                                </div>
                              </th>
                              <th>
                                <div className="cxBox mt-0">
                                  <span>
                                    {item.CB8 == "0" ? (
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip}
                                      >
                                        <Image src={CorssIconRed} alt="" />
                                      </OverlayTrigger>
                                    ) : (
                                      <Image src={RightIcon} alt="" />
                                    )}
                                  </span>
                                  <span>
                                    {item.CB9 == "0" ? (
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip}
                                      >
                                        <Image src={CorssIconRed} alt="" />
                                      </OverlayTrigger>
                                    ) : (
                                      <Image src={RightIcon} alt="" />
                                    )}
                                  </span>
                                </div>
                              </th>
                              <th>
                                <div className="cxBox mt-0">
                                  <span>
                                    {item.CB10 == "0" ? (
                                      <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip}
                                      >
                                        <Image src={CorssIconRed} alt="" />
                                      </OverlayTrigger>
                                    ) : (
                                      <Image src={RightIcon} alt="" />
                                    )}
                                  </span>
                                </div>
                              </th>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            {/* <div className="text-center">
          <Button className="btn btn-primary mt-2 width_btn">
            Start Screening
          </Button>
        </div> */}
          </Col>
        </Row>
        <div className="arrowUpDown">
          <Icon
            icon="fluent:chevron-down-12-filled"
            onClick={() => setOpen(!open)}
            fontSize={18}
          />
          <Icon icon="fluent:chevron-up-12-filled" fontSize={18} />
        </div>
      </>
    );
  };

  const Step6Content = () => {
    const [hsCodeVerificationData, sethsCodeVerificationData] = useState([]);
    const [hsCodeHide, sethsCodeShow] = useState(false);
    const [hscodeid, sethscodeid] = useState("");
    const [CountryOrigin, setCountryOrigin] = useState("");
    const [DestinationOrigin, setDestinationOrigin] = useState("");
    const [Trans_shipment, setTrans_shipment] = useState("");
    const [Trans_shipmentDescription, setTrans_shipmentDescription] =
      useState("");

    const [HazemClass, setHazemClass] = useState([]);
    // const[Trans_shipment,setTrans_shipment ]= useState('')

    const getHsCodeSearch = async (req, res) => {
      const userData = localStorage.getItem("userDetail");
      const reference = localStorage.getItem("reference");
      const hscodeVerfication = localStorage.getItem("hsCodeVerficationData");

      if (userData) {
        var data = JSON.parse(userData);
        var dataReference = JSON.parse(reference);
        var hsCodeverfication = JSON.parse(hscodeVerfication);

        sethsCodeVerificationData(
          hsCodeverfication && hsCodeverfication.hscodeType
        );
        setTrans_shipmentDescription(
          hsCodeverfication && hsCodeverfication.TransShipmentDesription
        );
        setCountryOrigin(hsCodeverfication && hsCodeverfication.countryOrigin);
        setDestinationOrigin(
          hsCodeverfication && hsCodeverfication.countryDestination
        );
        setTrans_shipment(
          hsCodeverfication && hsCodeverfication.anyTransShipment
        );
        sethscodeid(hsCodeverfication && hsCodeverfication._id);

        // sethscodeid(hsCodeverfication._id)
      }
    };

    const Dual_Use_Goods = async () => {
      // setSubmit(true);
      // setshipperloader(true)
      // onProfileValidation();

      // if (errorCount > 0) return;
      // await detectWhitespace(domain.trim());
      const userData = localStorage.getItem("userDetail");
      const reference = localStorage.getItem("reference");

      if (userData) {
        var data = JSON.parse(userData);
        var dataReference = JSON.parse(reference);
      }

      let payload = {
        hscodeType: hsCodeVerificationData,
        hsCodeVerifiyID: hscodeid,
        userId: data._id,
        countryOrigin: CountryOrigin,
        countryDestination: DestinationOrigin,
        anyTransShipment: Trans_shipment,
        TransShipmentDesription: Trans_shipmentDescription,
      };

      await fetch(`${API.BASE_URL}addHsCodeverificationData`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        mode: "cors",
        body: JSON.stringify({
          hscodeType: hsCodeVerificationData,
          hsCodeVerifiyID: hscodeid,
          userId: data._id,
          countryOrigin: CountryOrigin,
          countryDestination: DestinationOrigin,
          anyTransShipment: Trans_shipment,
          TransShipmentDesription: Trans_shipmentDescription,

          // CountryId:countryCode._id,
        }),
      })
        .then((response) => response.json())
        .then(async (res) => {
          if (res.status == true) {
            setDangerousGoods(true);
            toast("Submit successfully, You can proceed to next");
            await localStorage.setItem(
              "hsCodeVerficationData",
              JSON.stringify(res.data)
            );
          }
        })
        .catch((error) => {});
    };

    const getbadgedeatil = async () => {
      await fetch(`${API.BASE_URL}getBadgesDetail`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        mode: "cors",
        body: JSON.stringify({
          // CountryId:countryCode._id,
        }),
      })
        .then((response) => response.json())
        .then(async (res) => {
          if (res.status == true) {
            setHazemClass(res.data);
          }
        })
        .catch((error) => {});
    };

    useEffect(() => {
      getHsCodeSearch();
      getbadgedeatil();
      // Re_export_Country_Control();

      // countrycodeSearch()
    }, []);

    return (
      <>
        <h2 className="rpsHeading">Dangerous Goods</h2>
        <Row>
          <Col md={12}>
            <Card className="mb-3 mb-lg-4 mb-md-4 card-boxx">
              <Card.Body>
                <Table responsive className="table_Box">
                  <thead>
                    <tr>
                      <th>Goods Description</th>
                      <th>HS code or Taric Code (EU)</th>
                      <th>
                        Chemical Or
                        <br /> Dangerous Good <br />
                      </th>
                      <th>
                        Dual Use Export
                        <br /> Controlled Goods <br />
                      </th>
                      {/* <th>Is DG?</th>
                    <th>Not DG?</th> */}

                      <th>HAZCHEM Class</th>
                      <th>Label</th>
                    </tr>
                  </thead>
                  <tbody>
                    {hsCodeVerificationData &&
                      hsCodeVerificationData.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <Form.Group>
                                <Form.Control
                                  type={"text"}
                                  placeholder={"Enter Goods Description"}
                                  value={item.goodDescription}
                                />
                              </Form.Group>
                            </td>
                            <td>
                              <Form.Group>
                                <Form.Control
                                  type={"text"}
                                  placeholder={
                                    "Enter HS code or Taric Code (EU) "
                                  }
                                  value={item.hsCode}
                                />
                              </Form.Group>
                            </td>
                            <td>
                              <Form.Group>
                                <Form.Control
                                  type={"text"}
                                  placeholder={"Chemical Or Dangerous Good  "}
                                  value={item.chemicalDangerios}
                                />
                              </Form.Group>
                            </td>

                            <td>
                              <Form.Group>
                                <Form.Control
                                  type={"text"}
                                  placeholder={
                                    "Dual Use Export Controlled Goods  "
                                  }
                                  value={item.DualUseExport}
                                />
                              </Form.Group>
                            </td>
                            {/* <td>
                        <div className="crBox">
                          <Image src={CorssIconBlank} alt="" />
                        </div>
                      </td>
                      <td>
                        <div className="crBox">
                          <Image src={RightIcon} alt="" />
                        </div>
                      </td> */}

                            <td>
                              <Form.Group>
                                {/* <Form.Control type={"text"} placeholder={"Yes"} /> */}

                                <select
                                  value={hsCodeVerificationData[index].HAZCHEM}
                                  className="form-control"
                                  onChange={(e) => {
                                    const newValue = e.target.value;

                                    sethsCodeVerificationData((prevData) => {
                                      const newData = [...prevData];
                                      newData[index].HAZCHEM = newValue;
                                      // Update EccN for specific index
                                      // getECCNcodesearch(newValue);
                                      // setECCNcodevalue(newValue);
                                      return newData;
                                    });
                                  }}
                                >
                                  <option value="">Select Type</option>
                                  {HazemClass.map((badge) => (
                                    <option key={badge._id} value={badge._id}>
                                      {badge.BadgeTitle}
                                    </option>
                                  ))}
                                </select>
                              </Form.Group>
                            </td>
                            <td>
                              <td>
                                <div className="text-center">
                                  {/* Render the image based on the selected badge */}
                                  <Image
                                    src={
                                      `${PIC.PIC_URL}` +
                                      (HazemClass.find(
                                        (badge) =>
                                          badge._id ===
                                          hsCodeVerificationData[index].HAZCHEM
                                      )?.BadgePic || "")
                                    }
                                    alt=""
                                  />
                                </div>
                              </td>

                              {/* <div className="text-center">
      <Image src={LogoNew1} alt="" />
    </div> */}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </Card.Body>

              <div className="text-center mb-4">
                <Button
                  className="btn btn-primary mt-2 width_btn"
                  onClick={() => Dual_Use_Goods()}
                >
                  Start Screening
                </Button>
                <ToastContainer />
              </div>
            </Card>
          </Col>
        </Row>
        <div className="arrowUpDown">
          <Icon
            icon="fluent:chevron-down-12-filled"
            onClick={() => setOpen(!open)}
            fontSize={18}
          />
          <Icon icon="fluent:chevron-up-12-filled" fontSize={18} />
        </div>
      </>
    );
  };

  const Step7Content = () => {
    const [hsCodeVerificationData, sethsCodeVerificationData] = useState([]);

    const [Submit, setSubmit] = useState(false);

    const [hsCodeHide, sethsCodeShow] = useState(false);
    const [hscodeid, sethscodeid] = useState("");
    const [CountryOrigin, setCountryOrigin] = useState("");
    const [DestinationOrigin, setDestinationOrigin] = useState("");

    const [TransDestinationOrigin, setTransDestinationOrigin] = useState("");

    const [Trans_shipment, setTrans_shipment] = useState("");
    const [Trans_shipment1, setTrans_shipment1] = useState("");

    const [Trans_shipmentDescription, setTrans_shipmentDescription] =
      useState("");
    const [
      Trans_shipmentDescriptionmanual,
      setTrans_shipmentDescriptionmanual,
    ] = useState("");

    const getHsCodeSearch = async () => {
      const userData = localStorage.getItem("userDetail");
      const reference = localStorage.getItem("reference");
      const hscodeVerfication = localStorage.getItem("hsCodeVerficationData");

      if (userData) {
        var data = JSON.parse(userData);
        var dataReference = JSON.parse(reference);
        var hsCodeverfication = JSON.parse(hscodeVerfication);


        sethsCodeVerificationData(
          hsCodeverfication && hsCodeverfication.hscodeType
        );

        setTrans_shipmentDescription(
          hsCodeverfication && hsCodeverfication.TransShipmentDesription
        );
        setTrans_shipmentDescriptionmanual(
          hsCodeverfication && hsCodeverfication.TrandestinationDesription
        );
        setTrans_shipment1(hsCodeverfication && hsCodeverfication.anyReexport);
        setTrans_shipment(
          hsCodeverfication && hsCodeverfication.anyTransShipment
        );
        setTrans_shipment(
          hsCodeverfication && hsCodeverfication.anyTransShipment
        );

        setDestinationOrigin(
          hsCodeverfication && hsCodeverfication.countryDestination
        );
        setCountryOrigin(hsCodeverfication && hsCodeverfication.countryOrigin);

        sethscodeid(hsCodeverfication && hsCodeverfication._id);

        // sethscodeid(hsCodeverfication._id)
      }
    };

    const HsCodeSeacrh = async () => {
      // await detectWhitespace(domain.trim());
      const userData = localStorage.getItem("userDetail");
      const reference = localStorage.getItem("reference");

      if (userData) {
        var data = JSON.parse(userData);
        var dataReference = JSON.parse(reference);
      }

      let payload = {
        hscodeType: hsCodeVerificationData,
        hsCodeVerifiyID: hscodeid,
        userId: data._id,
        countryOrigin: CountryOrigin,
        countryDestination: DestinationOrigin,
        anyTransShipment: Trans_shipment,
        TransShipmentDesription: Trans_shipmentDescription,
        TrandestinationDesription: Trans_shipmentDescriptionmanual,

        anyReexport: Trans_shipment1,
      };

      await fetch(`${API.BASE_URL}addHsCodeverificationData`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        mode: "cors",
        body: JSON.stringify({
          hscodeType: hsCodeVerificationData,
          hsCodeVerifiyID: hscodeid,
          userId: data._id,
          countryOrigin: CountryOrigin,
          countryDestination: DestinationOrigin,
          anyTransShipment: Trans_shipment,
          TransShipmentDesription: Trans_shipmentDescription,
          TrandestinationDesription: Trans_shipmentDescriptionmanual,

          anyReexport: Trans_shipment1,

          // CountryId:countryCode._id,
        }),
      })
        .then((response) => response.json())
        .then(async (res) => {
          if (res.status == true) {
            toast("Submit successfully, You can proceed to next");
            await localStorage.setItem(
              "hsCodeVerficationData",
              JSON.stringify(res.data)
            );
            if (hsCodeVerificationData[0].EccN) {
            }
          }
        })
        .catch((error) => {});
    };
    useEffect(() => {
      getHsCodeSearch();
      // getbadgedeatil()
      // Re_export_Country_Control();

      // countrycodeSearch()
    }, []);

    return (
      <>
        <h2 className="rpsHeading">Precursor Chemicals</h2>

        <Row>
          <Col md={12}>
            <Card className="mb-3 mb-lg-4 mb-md-4 card-boxx">
              <Card.Body>
                <Table responsive className="table_Box">
                  <thead>
                    <tr>
                      <th>Goods Description</th>
                      <th>HS code or Taric Code (EU)</th>
                      <th>
                        Chemical Or
                        <br /> Dangerous Good <br />
                      </th>
                      <th>
                        Dual Use Export
                        <br /> Controlled Goods <br />
                      </th>
                      <th>
                        is Precursor
                        <br /> Chemicals?
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {hsCodeVerificationData &&
                      hsCodeVerificationData.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <Form.Group>
                                <Form.Control
                                  type={"text"}
                                  placeholder={"Enter Goods Description"}
                                  value={item.goodDescription}
                                />
                              </Form.Group>
                            </td>
                            <td>
                              <Form.Group>
                                <Form.Control
                                  type={"text"}
                                  placeholder={
                                    "Enter HS code or Taric Code (EU) "
                                  }
                                  value={item.hsCode}
                                />
                              </Form.Group>
                            </td>
                            <td>
                              <Form.Group>
                                <Form.Control
                                  type={"text"}
                                  placeholder={"Chemical Or Dangerous Good  "}
                                  value={item.chemicalDangerios}
                                />
                              </Form.Group>
                            </td>

                            <td>
                              <Form.Group>
                                <Form.Control
                                  type={"text"}
                                  placeholder={
                                    "Dual Use Export Controlled Goods  "
                                  }
                                  value={item.DualUseExport}
                                />
                              </Form.Group>
                            </td>

                            <td>
                              <Form.Group>
                                <Form.Control
                                  as="select"
                                  placeholder="is Precursor
                              Chemicals?"
                                  value={item.Precursor}
                                  onChange={(e) => {
                                    const newValue = e.target.value;
                                    sethsCodeVerificationData((prevData) => {
                                      const newData = [...prevData];
                                      newData[index].Precursor = newValue;
                                      // Update licines for specific index
                                      // Assuming setlinces is the correct setter function for licines
                                      return newData;
                                    });
                                  }}
                                >
                                  <option value="">Select Option</option>

                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>

                                  {/* Add more options as needed */}
                                </Form.Control>
                              </Form.Group>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </Card.Body>
              <div className="text-center mb-4">
                <Button
                  className="btn btn-primary mt-2 width_btn"
                  onClick={() => HsCodeSeacrh()}
                >
                  Start Screening
                </Button>
                <ToastContainer />
              </div>
            </Card>
          </Col>
        </Row>
        <div className="arrowUpDown">
          <Icon
            icon="fluent:chevron-down-12-filled"
            onClick={() => setOpen(!open)}
            fontSize={18}
          />
          <Icon icon="fluent:chevron-up-12-filled" fontSize={18} />
        </div>
      </>
    );
  };

  useEffect(() => {
    RPSScreeningRef.current =
      RPSScreening || RPSScreeningConsignee || RPSScreeningConsignee;
    HSCodeVerificationRef.current = HSCodeVerification;
    HSCCodeSanctionsRef.current = HSCCodeSanctions;
    DualUseGoodsRef.current = DualUseGoods;
    DangerousGoodsRef.current = DangerousGoods;
  }, [
    RPSScreening,
    HSCodeVerification,
    HSCCodeSanctions,
    DualUseGoods,
    DangerousGoods,
    RPSScreeningConsignee,
    RPSScreeningenduser,
  ]);

  return (
    <>
      <Helmet bodyAttributes={{ style: "background-color : #efefef" }} />
      <section className="py-3">
        <Container>
          <div className={`widthSmall `}>
            {apiResponse !== null && (
              <StepProgressBar
                startingStep={0}
                onSubmit={onFormSubmit}
                steps={[
                  {
                    label: "RPS",
                    name: "step 1",
                    content: <Step1Content />,
                    validator: () => RPSScreeningRef.current,
                  },
                  {
                    label: "HS Code Verification",
                    name: "step 2",
                    content: <Step2Content />,
                    validator: () => HSCodeVerificationRef.current,
                  },
                  {
                    label: "HS Code Sanctions",
                    name: "step 3",
                    content: <Step3Content />,
                    validator: () => HSCCodeSanctionsRef.current,
                  },
                  {
                    label: "Dual-Use Goods",
                    name: "step 4",
                    content: <Step4Content />,
                    validator: () => DualUseGoodsRef.current,
                  },
                  {
                    label: "Re-export Country Control",
                    name: "step 5",
                    content: <Step5Content />,
                  },
                  {
                    label: "DG HAZCHEM CLASS",
                    name: "step 6",
                    content: <Step6Content />,
                    validator: () => DangerousGoodsRef.current,
                  },
                  {
                    label: "Precursor Chemicals",
                    name: "step 7",
                    content: <Step7Content />,
                  },
                ]}
              />
            )}
            {/* Your API call logic to set apiResponse */}
            {/* Example: <button onClick={() => handleApiResponse(response)}>Get Response</button> */}
          </div>
        </Container>
      </section>
    </>
  );
};

export default AddScreening;
