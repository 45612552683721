import React, { useContext, useEffect, useState } from "react";
import {
  HashRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Login from "./component/pages/auth/login";
import ForgotPassword from "./component/pages/auth/ForgotPassword";
import ResetPassword from "./component/pages/auth/ResetPassword";
import SignUp from "./component/pages/auth/SignUp";
import NewHeader from "./component/inc/NewHeader";
import Footer from "./component/inc/Footer";
import Header from "./component/inc/Header";
import MayRenderNav from "./component/inc/MayRenderNav";
import MayRenderNavNew from "./component/inc/MayRenderNavNew";
import ScrollToTop from "./component/inc/ScrollToTop";
import Dashboard from "./component/pages/dashboard";
import ScreeningOverview from "./component/pages/ScreeningOverview";
import AddScreening from "./component/pages/AddScreening";
import AddScreeningSummary from "./component/pages/AddScreeningSummary";
import Notifications from "./component/pages/notifications";
import Subscription from "./component/pages/subscription";
import Settings from "./component/pages/Settings";
import Support from "./component/pages/Support";
import Home from "./component/pages/Home";
import ScreeningsHome from "./component/pages/ScreeningsHome";
import ScreeningOverviewRPS from "./component/pages/ScreeningOverviewRPS";
import ScreeningOverviewHSCodeSanctions from "./component/pages/ScreeningOverviewHSCodeSanctions";
import ScreeningOverviewDualUseExport from "./component/pages/ScreeningOverviewDualUseExport";
import ScreeningOverviewDangerousGoods from "./component/pages/ScreeningOverviewDangerousGoods";
import AboutUs from "./component/pages/AboutUs";
import Pricing from "./component/pages/Pricing";
import ContactUs from "./component/pages/ContactUs";
import Blogs from "./component/pages/Blogs";
import BlogDetails from "./component/pages/BlogDetails";
import TermsAndConditions from "./component/pages/TermsAndConditions";
import PrivacyPolicy from "./component/pages/PrivacyPolicy";
import { AppDataContext } from "./component/services/appData";
import UserDomain from "./component/pages/UserDomain";
import * as API from "../src/services/env";
import AddCardForm from "./component/pages/AddCardForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { AppProvider } from "./../src/component/pages/AppContext";
import SubscriptionUpdate from "./component/pages/SubscriptionUpdate";
import BatchScreening from "./component/pages/BatchScreening";
import BatchScreeningOverview from "./component/pages/BatchScreeningOverview";
import ScreeningOverviewDetails from "./component/pages/ScreeningOverviewDetails";
import ScreeningOverviewHSCodeVerification from "./component/pages/ScreeningOverviewHSCodeVerification";
import ScreeningOverviewDualUseGoods from "./component/pages/ScreeningOverviewDualUseGoods";
import ScreeningOverviewPrecursorChemicals from "./component/pages/ScreeningOverviewPrecursorChemicals";
const stripePromise = loadStripe(
  "pk_test_51OKEUESJSriTQORZY6WqIBSoLvIQWfbLuKeriZzeV4OY4ZhdpJ9Bk0ESQNS3pQugFpctxMpdDiMYIwr1fskObri500XTlBdBtj"
);

function ScrollToTopMain() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
var users = [
  { userName: "manpreet", name: "User One" },
  { userName: "user2", name: "User Two" },
  // Add more users as needed
];
function App() {
  const { isLogin ,setIsLogin} = useContext(AppDataContext);
  setIsLogin(localStorage.getItem("IsLogin"))
  const options = {
    // passing the client secret obtained from the server
    clientSecret:
      "sk_test_51OKEUESJSriTQORZ1qAcYYdQvsMJNvyJ2M7X4daq2hyazUcv7AYthvnUh3QXRJnmLwk3GhWBSYIkE30LluHns4Sl00HbWhzMJB",
  };

  const [subdomain, setSubdomain] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const host = window.location.hostname;
    const arr = host.split(".")[0];
    if (arr.length > 0) setSubdomain(arr);
  }, []);

  useEffect(() => {

    if (subdomain === "localhost") {
      // Handle the default domain content here
      setUser({ name: "Default User" });
    } else {
      if (subdomain) {
        let payload = {
          username: subdomain,
        };
        fetch(`${API.BASE_URL}fetchUserDomain`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          // mode: "cors",
          body: JSON.stringify({
            username: subdomain,
          }), // body data type must match "Content-Type" header
        })
          .then((response) => response.json())
          .then((res) => {
            if (res.status == true) {
              setUser(res.data[0].userName);
              // window.location.href = "/accel/login";
            } else {
            }
          })
          .catch((error) => {});
      }
    }
  }, [subdomain]);

  // if (!user) {
  //   return <div>Subdomain not found</div>;
  // }

  return (
    // <Router basename={"/accel"}>
    <AppProvider>
      <Router basename={"/"}>
        <Elements stripe={stripePromise}>
          <ScrollToTopMain />
          {!isLogin ? (
            <MayRenderNavNew>
              <NewHeader />
            </MayRenderNavNew>
          ) : (
            <MayRenderNav>
              <Header />
            </MayRenderNav>
          )}

          <Routes>
            <Route exact path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:id" element={<ResetPassword />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/screenings" element={<ScreeningsHome />} />
            <Route path="/screening-overview/:id" element={<ScreeningOverview />} />
            <Route path="/add-screening" element={<AddScreening />} />
            <Route path="/batch-screening" element={<BatchScreening />} />
            <Route
              path="/batch-screening-overview"
              element={<BatchScreeningOverview />}
            />
            <Route
              path="/screening-overview-details"
              element={<ScreeningOverviewDetails />}
            />
            <Route
              path="/add-screening-summary"
              element={<AddScreeningSummary />}
            />
            <Route path="/subscription" element={<Subscription />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/support" element={<Support />} />
            <Route
              path="/screeningoverview-RPS"
              element={<ScreeningOverviewRPS />}
            />
            <Route
              path="/screeningoverview-HSCodeSanctions"
              element={<ScreeningOverviewHSCodeSanctions />}
            />
            <Route
              path="/screeningoverview-DualUseExport"
              element={<ScreeningOverviewDualUseExport />}
            />
            <Route
              path="/screeningoverview-DangerousGoods"
              element={<ScreeningOverviewDangerousGoods />}
            />
            <Route
              path="/screeningoverview-HSCodeVerification"
              element={<ScreeningOverviewHSCodeVerification />}
            />
            <Route
              path="/screeningoverview-DualUseGoods"
              element={<ScreeningOverviewDualUseGoods />}
            />
            <Route
              path="/screeningoverview-PrecursorChemicals"
              element={<ScreeningOverviewPrecursorChemicals />}
            />
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blog-details" element={<BlogDetails />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/UserDomain" element={<UserDomain />} />
            <Route path="/AddCardForm" element={<AddCardForm />} />
            <Route
              path="/SubscriptionUpdate"
              element={<SubscriptionUpdate />}
            />
          </Routes>
          {!isLogin && (
            <MayRenderNavNew>
              <Footer />
            </MayRenderNavNew>
          )}
          <ScrollToTop />
        </Elements>
      </Router>
    </AppProvider>
  );
}

export default App;
