import React, { useState, useEffect } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Table from "react-bootstrap/Table";
import EditIcon from "../images/editicon.svg";
import HeaderScreen from "../inc/Header";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
// import Form from "react-bootstrap/Form"
import DatePicker from "react-datepicker";
import * as API from "../../services/env";
import moment from "moment";
const ScreeningsHome = () => {
  const [startDate, setStartDate] = useState("");

  const [secondData, setSecondData] = useState([]);
  const [userName, setuserName] = useState("");
  const formattedStartDate = moment(startDate).format("YYYY-MM-DD");

  console.log("formattedStartDate", formattedStartDate);

  const fetchViewDeatil = async (arr) => {
    localStorage.setItem("viewCount", JSON.stringify("1"));

    const userData = localStorage.getItem("userDetail");
    if (userData) {
      var data = JSON.parse(userData);
    }

    let payload = {
      userId: data._id,
    };
    console.log("payload", payload);
    fetch(`${API.BASE_URL}getScreeningUserDetail`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      // mode: "cors",
      body: JSON.stringify({
        userId: data._id,
        viewStatus: "1",
        date: formattedStartDate,
      }), // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("resapplication", res);

        if (res.status == true) {
          setSecondData(res.data);
          setuserName(data.userName);
        } else {
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    fetchViewDeatil();
  }, []);
  useEffect(() => {
    localStorage.removeItem("PerShipmentShipper");
    localStorage.removeItem("PerShipmentConsignee");
    localStorage.removeItem("PerShipmentEndUser");
    // localStorage.removeItem("hsCodeVerficationData")
  });

  const CustomDatePickerInput = ({ value, onClick }) => (
    <input
      type="text"
      value={value ? moment(value).format("YYYY-MM-DD") : ""}
      onClick={onClick}
      placeholder="Select Date"
      readOnly
    />
  );
  return (
    <>
      <Helmet bodyAttributes={{ style: "background-color : #efefef" }} />
      <section className="py-3">
        <Container fluid className="widthSmall">
          <Row className="mb-lg-3 mb-md-3 mb-3">
            <Col md={12}>
              <div className="requestFlex justify-content-end">
                <div className="mtt20 rightDiv ">
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    placeholderText="Select Date"
                    dateFormat="yyyy-MM-dd"
                    // Custom input to format the selected date using Moment.js
                    customInput={<CustomDatePickerInput />}
                  />
                  <Button
                    variant="primary"
                    size="md"
                    className="btnPad "
                    onClick={() => {
                      fetchViewDeatil();
                    }}
                  >
                    Show Requests
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="table-responsive tablebox">
                <Table bordered responsive>
                  <thead>
                    <tr>
                      <th>Screening ID</th>
                      <th style={{ minWidth: "180px" }}>Screening Name</th>
                      {/* <th style={{ minWidth: "130px" }}>Description</th> */}
                      <th style={{ minWidth: "130px" }}>User</th>
                      <th style={{ minWidth: "120px" }}>Date</th>
                      {/* <th style={{ minWidth: "100px", width: "70px" }}>
                        Action
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {secondData.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <Link to={`/screening-overview/${item._id}`}>
                              {item.screeningId && item.screeningId}
                            </Link>
                          </td>
                          <td>{item.referenceNo && item.referenceNo}</td>
                          {/* <td>Wellfare</td> */}
                          <td>{userName && userName}</td>
                          <td>
                            {item.createdAt &&
                              moment(item.createdAt).format("ll")}
                          </td>
                          {/* <td>
                            <div className="d-flex justify-content-between">
                              <Link>
                                <Image src={EditIcon} />
                                <span className="ms-2">Edit</span>
                              </Link>
                            </div>
                          </td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ScreeningsHome;
