import React, { useState, useEffect } from "react";

import { Col, Container, Row } from "react-bootstrap";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { Icon } from "@iconify/react";
import Accordion from "react-bootstrap/Accordion";
import CustomForm from "../customcomponents/CustomForm";
import CustomFormIcon from "../customcomponents/CustomFormIcon";
import CustomFileUpload from "../customcomponents/CustomFileUpload";
import { Form } from "react-bootstrap";

import * as API from "../../services/env";
import * as PIC from "../../services/env";

const ScreeningOverviewRPS = () => {
  const [ShipperData, setShipperData] = useState("");
  const [consineeData, setconsineeData] = useState("");

  const [EndUserData, setEndUserData] = useState("");
  const [screning, setscrening] = useState("");

  const [hazchemDetails, sethazchemDetails] = useState([]);
  const [hscodeData, sethscodeData] = useState("");
  const [DangerousGoods, setDangerousGoods] = useState([]);
  const getHsCodeSearch = async (req, res) => {
    const userData = localStorage.getItem("userDetail");
    const reference = localStorage.getItem("reference");
    const hscodeVerfication = localStorage.getItem("hsCodeVerficationData");

    const screeningId = localStorage.getItem("screeningId");
    if (userData) {
      var data = JSON.parse(userData);
      var dataReference = JSON.parse(reference);
      var hsCodeverfication = JSON.parse(hscodeVerfication);
      var screeningIds = JSON.parse(screeningId);

      getHsCodesearchDetail(screeningIds);
    }
  };

  const getHsCodesearchDetail = async (index) => {
    await fetch(`${API.BASE_URL}getScreenigTotalAALdeatil`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        ScreningId: index,
      }),
    })
      .then((response) => response.json())
      .then(async (res) => {
        if (res.status == true) {
          sethscodeData(res.hazchemDetails[0].hscodeType);
          setDangerousGoods(res.hscodeData);
          setscrening(res.screening[0]);
          const consineeData = res.data.find(
            (item) => item.userType === "Consignee"
          );

          if (consineeData) {
            setconsineeData([consineeData]);
          }
          const shipperData = res.data.find(
            (item) => item.userType === "Shipper"
          );


          if (shipperData) {
            setShipperData([shipperData]);
          }
          const endUserData = res.data.find(
            (item) => item.userType === "End User"
          );


          if (endUserData) {
            setEndUserData([endUserData]);
          }
          // sethscodetype(res.hscodeData?res.hscodeData.hscodeType:'')
        }
      })
      .catch((error) => {});
  };
  function filterMostDataObjects(data) {
    // Create a map to store objects by their unique "ShipperRisk"
    const map = new Map();

    // Iterate through the array
    data.forEach((obj) => {
      const shipperRisk = obj.ShipperRisk;
      // Check if object with this ShipperRisk already exists in the map
      if (map.has(shipperRisk)) {
        // Compare the number of fields in the current object with the existing object
        const existingFields = Object.keys(map.get(shipperRisk)).length;
        const currentFields = Object.keys(obj).length;

        // If current object has more fields, replace the existing object in the map
        if (currentFields > existingFields) {
          map.set(shipperRisk, obj);
        }
      } else {
        // If the ShipperRisk is not in the map, add the object
        map.set(shipperRisk, obj);
      }
    });

    // Convert map values back to array
    const filteredArray = Array.from(map.values());

    return filteredArray;
  }
  function filterMostDataObjectsConsignee(data) {
    // Create a map to store objects by their unique "ShipperRisk"
    const map = new Map();

    // Iterate through the array
    data.forEach((obj) => {
      const shipperRisk = obj.ShipperRisk;
      // Check if object with this ShipperRisk already exists in the map
      if (map.has(shipperRisk)) {
        // Compare the number of fields in the current object with the existing object
        const existingFields = Object.keys(map.get(shipperRisk)).length;
        const currentFields = Object.keys(obj).length;

        // If current object has more fields, replace the existing object in the map
        if (currentFields > existingFields) {
          map.set(shipperRisk, obj);
        }
      } else {
        // If the ShipperRisk is not in the map, add the object
        map.set(shipperRisk, obj);
      }
    });

    // Convert map values back to array
    const filteredArray = Array.from(map.values());

    return filteredArray;
  }
  function filterMostDataObjectsEndUser(data) {
    // Create a map to store objects by their unique "ShipperRisk"
    const map = new Map();

    // Iterate through the array
    data.forEach((obj) => {
      const shipperRisk = obj.ShipperRisk;
      // Check if object with this ShipperRisk already exists in the map
      if (map.has(shipperRisk)) {
        // Compare the number of fields in the current object with the existing object
        const existingFields = Object.keys(map.get(shipperRisk)).length;
        const currentFields = Object.keys(obj).length;

        // If current object has more fields, replace the existing object in the map
        if (currentFields > existingFields) {
          map.set(shipperRisk, obj);
        }
      } else {
        // If the ShipperRisk is not in the map, add the object
        map.set(shipperRisk, obj);
      }
    });

    // Convert map values back to array
    const filteredArray = Array.from(map.values());

    return filteredArray;
  }
  // Your data array

  // Filter the array
  if (ShipperData && screning.screeningType == "Batch Screening") {
    var filteredData = filterMostDataObjects(
      ShipperData &&
        screning.screeningType == "Batch Screening" &&
        ShipperData[0].Owners_name
    );
  }
  if (consineeData && screning.screeningType == "Batch Screening") {
    var filteredDataconsignee = filterMostDataObjectsConsignee(
      consineeData &&
        screning.screeningType == "Batch Screening" &&
        consineeData[0].Owners_name
    );
  }
  if (EndUserData && screning.screeningType == "Batch Screening") {
    var filteredDataEndUserData = filterMostDataObjectsEndUser(
      EndUserData &&
        screning.screeningType == "Batch Screening" &&
        EndUserData[0].Owners_name
    );
  }

  // Print the filtered array

  // Print the filtered array
  

  useEffect(() => {
    // getECCNcodesearch();
    getHsCodeSearch();

    localStorage.removeItem("hsCodeVerficationData");
  }, []);

  //   function isRiskDataPresent(array) {
  //     for (let i = 0; i < array.length; i++) {
  //         if (array[i].risk !== "") {
  //             return true; // Return true if any object has data in the "risk" field
  //         }
  //     }
  //     return false; // Return false if no object has data in the "risk" field
  // }

  // // Check if any object in the repound array has data in the "risk" field
  let riskDataPresent = isRiskDataPresent(
    ShipperData && ShipperData[0].repound
  );

  function isRiskDataPresent(array) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].risk !== ""&&array[i].status == "yes") {
        return true; // Return true if any object has data in the "risk" field
      }
      if (array[i].status == "yes") {
        return true;
      }
    }
    return false; // Return false if no object has data in the "risk" field
  }


  // // Check if any object in the repound array has data in the "risk" field
  let consgineeRiskDataPresent = isRiskDataPresents(
    consineeData && consineeData[0].repound
  );

  // console.log('EndUserData.repound',EndUserData.repound);

  function isRiskDataPresents(array) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].risk !== ""&&array[i].status == "yes") {
        return true; // Return true if any object has data in the "risk" field
      }
      if (array[i].status == "yes") {
        return true;
      }
    }
    return false; // Return false if no object has data in the "risk" field
  }

  // // Check if any object in the repound array has data in the "risk" field
  let ENdUserriskDataPresent = ENdUserriskDataPresents(
    EndUserData && EndUserData[0].repound
  );

  function ENdUserriskDataPresents(array) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].risk !== ""&&array[i].status == "yes") {
        return true; // Return true if any object has data in the "risk" field
      }
      if (array[i].status == "yes") {
        return true;
      }
    }
    return false; // Return false if no object has data in the "risk" field
  }

  return (
    <>
      <Helmet bodyAttributes={{ style: "background-color : #efefef" }} />
      <section className="py-3 overview_sec">
        <Container fluid className="widthSmall">
          <Row className="mb-lg-4 mb-md-4 mb-3">
            <Col md={12}>
              <div className="requestFlex">
                <h2 className="scheading">Screening Overview : RPS</h2>

                <Button
                  as={Link}
                  variant="warning"
                  size="md"
                  className="btnBack"
                  to={`/screening-overview/1`}
                >
                  <Icon icon="carbon:task-view" fontSize={18} />
                  Back to Screening Overview
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="tab_link">
              <Link className="active" to="/screeningoverview-RPS">
                RPS
              </Link>
              <Link to="/screeningoverview-HSCodeVerification">
                HS Code Verification
              </Link>
              <Link to="/screeningoverview-HSCodeSanctions">
                HS Code Sanctions
              </Link>
              <Link to="/screeningoverview-DualUseGoods">Dual-Use Goods</Link>
              <Link to="/screeningoverview-DualUseExport">
                Re-export Country Control
              </Link>
              <Link to="/screeningoverview-DangerousGoods">
                Dangerous Goods
              </Link>
              <Link to="/screeningoverview-PrecursorChemicals">
                Precursor Chemicals
              </Link>
            </Col>
          </Row>

          <Row>
            <Col lg={12} md={12}>
              <Accordion defaultActiveKey="0">
                {ShipperData.length > 0 ? (
                  <>
            
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Shipper</Accordion.Header>
                      <Accordion.Body>
                        {ShipperData.length > 0
                          ? ShipperData.map((item, index) => (
                              <Col md={12} key={index}>
                                {item.name &&
                                  item.name.length > 0 &&
                                  item.name
                                    .filter(
                                      (filteredItemName) =>
                                        filteredItemName[0]?.hasOwnProperty(
                                          "status"
                                        ) ||
                                        !filteredItemName[0]?.hasOwnProperty(
                                          "name"
                                        )
                                    )
                                    .map((itemName, nameIndex) =>
                                      itemName.hasOwnProperty("name") &&
                                      itemName.hasOwnProperty("status") ? (
                                        <CustomFormIcon
                                          key={nameIndex}
                                          color={"#222528"}
                                          type="text"
                                          labelText="Shipper Name"
                                          placeholderText="Enter Shipper Name"
                                          value={itemName.name} // Assuming itemName is an object with a 'name' property
                                        />
                                      ) : (
                                        <CustomFormIcon
                                          key={nameIndex}
                                          color={"#222528"}
                                          type="text"
                                          labelText="Shipper Name"
                                          placeholderText="Enter Shipper Name"
                                          value={itemName} // Assuming itemName is an object with a 'name' property
                                        />
                                      )
                                    )}
                              </Col>
                            ))
                          : null}
                        {ShipperData.map((item, index) => {

                          return (
                            <Row>
                              {riskDataPresent == true ? (
                                <>
                                  <Col md={12}>
                                    {/* <strong>
                                      {item.name[0] &&
                                      item.name.some((item) =>
                                        item.hasOwnProperty("name")
                                      )
                                        ? item.name[0].name
                                        : item.name[0]}
                                    </strong> */}
                                    <div className="my-3">
                                      <span className="rejected fw-normal me-4">
                                        Risks Found
                                      </span>

                                      {item.repound.map((item, index) => {
                                        return (
                                          <div>
                                            <span className="rejected me-4"></span>
                                            <span className="rejected me-4">
                                              {item.risk != "" && item.risk}
                                            </span>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </Col>
                                  {ShipperData.length > 0 &&
                                  item.Owners_name &&
                                  item.Owners_name.length > 0 &&
                                  item.Owners_name[0].hasOwnProperty(
                                    "ShipperRisk"
                                  ) ? (
                                    filteredData.map((items, index) => {
                                      return (
                                        <Col md={12}>
                                          ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
                                          <small className="smallText">
                                            First Screening Return Hit
                                          </small>
                                          <Row>
                                            {/* <Col md={6}>
                                              <CustomForm
                                                color={"#222528"}
                                                type="text"
                                                lableText="Shipper Name *"
                                                placeholderText="Enter Shipper Name"
                                                value={
                                                  item.hasOwnProperty(
                                                    "ShipperRisk"
                                                  ) ? (
                                                    item.ShipperRisk
                                                  ) : (
                                                    <></>
                                                  )
                                                }
                                              />
                                            </Col> */}
                                            <Col md={12} lg={6}>
                                              <div className="mb-3">
                                                <span className="form-label">
                                                  Does the shareholder owns{" "}
                                                  {">"} 51%
                                                </span>
                                                {["radio"].map((type) => (
                                                  <div
                                                    key={`inline-${type}`}
                                                    className="radioBx"
                                                  >
                                                    <Form.Check
                                                      inline
                                                      label="Yes"
                                                      name={`group70-${index}`}
                                                      type={type}
                                                      checked={
                                                        items.hasOwnProperty(
                                                          "risk"
                                                        ) ? (
                                                          items.risk == "yes"
                                                        ) : (
                                                          <></>
                                                        )
                                                      }
                                                      id={`inline-5`}
                                                    />
                                                    <Form.Check
                                                      inline
                                                      label="No"
                                                      name={`group70-${index}`}
                                                      type={type}
                                                      checked={
                                                        items.hasOwnProperty(
                                                          "risk"
                                                        ) ? (
                                                          items.risk == "no"
                                                        ) : (
                                                          <></>
                                                        )
                                                      }
                                                      id={`inline-4`}
                                                    />
                                                  </div>
                                                ))}
                                              </div>
                                            </Col>
                                            <Col lg={4}>
                                              <div className="mb-3">
                                                <h3 className="s15">
                                                  Shipper’s Business Bank Name
                                                  Optional for all country
                                                  destinations except for
                                                  <span>
                                                    Russia, Belarus, Iran, Cuba,
                                                    Myanmar, Syria, Sudan, Iraq,
                                                    Libya, Somalia, Lebanon,
                                                    Yemen, Zimbabwe
                                                  </span>
                                                  – if shipment destination
                                                  these countries then Mandatory
                                                </h3>

                                                <span className="form-label">
                                                  Country of destinations to
                                                  these countries?
                                                </span>
                                                {["radio"].map((type) => (
                                                  <div
                                                    key={`inline-${type}`}
                                                    className="radioBx"
                                                  >
                                                    <Form.Check
                                                      inline
                                                      label="Yes"
                                                      name={`group12341-${index}`}
                                                      type={type}
                                                      checked={
                                                        items.hasOwnProperty(
                                                          "bankCheck"
                                                        ) ? (
                                                          items.bankCheck ==
                                                          "Yes"
                                                        ) : (
                                                          <></>
                                                        )
                                                      }
                                                      id={`inline-111`}
                                                    />
                                                    <Form.Check
                                                      inline
                                                      label="No"
                                                      name={`group12341-${index}`}
                                                      type={type}
                                                      checked={
                                                        items.hasOwnProperty(
                                                          "bankCheck"
                                                        ) ? (
                                                          items.bankCheck == "No"
                                                        ) : (
                                                          <></>
                                                        )
                                                      }
                                                      id={`inline-22`}
                                                    />
                                                  </div>
                                                ))}
                                              </div>
                                            </Col>
                                            <Col md={6}>
                                              <CustomForm
                                                color={"#222528"}
                                                type="text"
                                                lableText="Bank Name (optional except several countries)   i"
                                                placeholderText="Enter Bank Name"
                                                value={
                                                  items.hasOwnProperty(
                                                    "bankName"
                                                  )
                                                    ? items.bankName
                                                    : ""
                                                }
                                              />
                                            </Col>
                                            <Col md={6}>
                                              <CustomForm
                                                color={"#222528"}
                                                type="text"
                                                lableText="Shipper Address *"
                                                placeholderText="Enter Shipper Address"
                                                value={
                                                  items.hasOwnProperty(
                                                    "Shipper_Owners_Address"
                                                  )
                                                    ? items.Shipper_Owners_Address
                                                    : ""
                                                }
                                              />
                                            </Col>
                                            <Col md={6}>
                                              <CustomForm
                                                color={"#222528"}
                                                type="text"
                                                lableText="Bank Address *"
                                                placeholderText="Enter Bank Address"
                                                value={
                                                  items.hasOwnProperty(
                                                    "bankAddress"
                                                  )
                                                    ? items.bankAddress
                                                    : ""
                                                }
                                              />
                                            </Col>
                                          </Row>
                                          <small className="smallText">
                                            Second Screening Return Hit
                                          </small>
                                          <Row>
                                            <Col md={6}>
                                              <CustomForm
                                                color={"#222528"}
                                                type="text"
                                                lableText="Shipper DOB *"
                                                placeholderText="Enter Shipper DOB"
                                                value={
                                                  items.hasOwnProperty(
                                                    "shipperDOBs"
                                                  )
                                                    ? items.shipperDOBs
                                                    : items.shipperDOB
                                                }
                                              />
                                            </Col>
                                            <Col md={6}>
                                              <CustomFileUpload
                                                color={"#222528"}
                                                type="file"
                                                lableText="Shipper Passport *"
                                                placeholderText="Upload Passport"
                                              />
                                            </Col>
                                          </Row>
                                        </Col>
                                      );
                                    })
                                  ) : (
                                    <Col md={12}>
                                      <small className="smallText">
                                        First Screening Return Hit
                                      </small>
                                      <Row>
                                        <Col md={12} lg={6}>
                                          <div className="mb-3">
                                            <span className="form-label">
                                              Does the shareholder owns {">"}{" "}
                                              51%
                                            </span>
                                            {["radio"].map((type) => (
                                              <div
                                                key={`inline-${type}`}
                                                className="radioBx"
                                              >
                                                <Form.Check
                                                  inline
                                                  label="Yes"
                                                  name={`group12d341-${index}`}
                                                  type={type}
                                                  checked={
                                                    ShipperData
                                                      ? ShipperData[0]
                                                          .shareholder_owns ==
                                                        "Yes"
                                                      : ""
                                                  }
                                                  id={`inline-111`}
                                                />
                                                <Form.Check
                                                  inline
                                                  label="No"
                                                  name={`group12d341-${index}`}
                                                  type={type}
                                                  checked={
                                                    ShipperData
                                                      ? ShipperData[0]
                                                          .shareholder_owns ==
                                                        "No"
                                                      : ""
                                                  }
                                                  id={`inline-22`}
                                                />
                                              </div>
                                            ))}
                                          </div>
                                        </Col>
                                        <Col lg={4}>
                                          <div className="mb-3">
                                            <h3 className="s15">
                                              Shipper’s Business Bank Name
                                              Optional for all country
                                              destinations except for
                                              <span>
                                                Russia, Belarus, Iran, Cuba,
                                                Myanmar, Syria, Sudan, Iraq,
                                                Libya, Somalia, Lebanon, Yemen,
                                                Zimbabwe
                                              </span>
                                              – if shipment destination these
                                              countries then Mandatory
                                            </h3>

                                            <span className="form-label">
                                              Country of destinations to these
                                              countries?
                                            </span>
                                            {["radio"].map((type) => (
                                              <div
                                                key={`inline-${type}`}
                                                className="radioBx"
                                              >
                                                <Form.Check
                                                  inline
                                                  label="Yes"
                                                  name="group12153"
                                                  type={type}
                                                  checked={
                                                    ShipperData
                                                      ? ShipperData[0]
                                                          .BankType == "Yes"
                                                      : ""
                                                  }
                                                  id={`inline-111`}
                                                />
                                                <Form.Check
                                                  inline
                                                  label="No"
                                                  name="group1508"
                                                  type={type}
                                                  checked={
                                                    ShipperData
                                                      ? ShipperData[0]
                                                          .BankType == "No"
                                                      : ""
                                                  }
                                                  id={`inline-22`}
                                                />
                                              </div>
                                            ))}
                                          </div>
                                        </Col>
                                        <Col md={6}>
                                          <CustomForm
                                            color={"#222528"}
                                            type="text"
                                            lableText="Bank Name (optional except several countries)   i"
                                            placeholderText="Enter Bank Name"
                                            value={
                                              ShipperData
                                                ? ShipperData[0].bankName
                                                : ""
                                            }
                                          />
                                        </Col>
                                        <Col md={6}>
                                          <CustomForm
                                            color={"#222528"}
                                            type="text"
                                            lableText="Shipper Name *"
                                            placeholderText="Enter Shipper Name"
                                            value={
                                              ShipperData
                                                ? ShipperData[0].Owners_name[0]
                                                : ""
                                            }
                                          />
                                        </Col>

                                        <Col md={6}>
                                          <CustomForm
                                            color={"#222528"}
                                            type="text"
                                            lableText="Shipper Address *"
                                            placeholderText="Enter Shipper Address"
                                            value={
                                              ShipperData
                                                ? ShipperData[0].Owners_address
                                                : ""
                                            }
                                          />
                                        </Col>

                                        <Col md={6}>
                                          <CustomForm
                                            color={"#222528"}
                                            type="text"
                                            lableText="Bank Address *"
                                            placeholderText="Enter Bank Address"
                                            value={
                                              ShipperData
                                                ? ShipperData[0].bankAddress
                                                : ""
                                            }
                                          />
                                        </Col>
                                      </Row>
                                      <small className="smallText">
                                        Second Screening Return Hit
                                      </small>
                                      <Row>
                                        <Col md={6}>
                                          <CustomForm
                                            color={"#222528"}
                                            type="text"
                                            lableText="Shipper DOB *"
                                            placeholderText="Enter Shipper DOB"
                                            value={
                                              ShipperData
                                                ? ShipperData[0].shipperDOB
                                                : ""
                                            }
                                          />
                                        </Col>
                                        <Col md={6}>
                                          <CustomFileUpload
                                            color={"#222528"}
                                            type="file"
                                            lableText="Shipper Passport *"
                                            placeholderText="Upload Passport"
                                          />
                                        </Col>
                                      </Row>
                                    </Col>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </Row>
                          );
                        })}
                      </Accordion.Body>
                    </Accordion.Item>
                  </>
                ) : (
                  <></>
                )}

                {consineeData.length > 0 ? (
                  <>
                    {" "}
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Consignee</Accordion.Header>
                      <Accordion.Body>
                        {consineeData.length > 0
                          ? consineeData.map((item, index) => (
                              <Col md={12} key={index}>
                                {item.name &&
                                  item.name.length > 0 &&
                                  item.name
                                    .filter(
                                      (filteredItemName) =>
                                        filteredItemName[0]?.hasOwnProperty(
                                          "status"
                                        ) ||
                                        !filteredItemName[0]?.hasOwnProperty(
                                          "name"
                                        )
                                    )
                                    .map((itemName, nameIndex) =>
                                      itemName.hasOwnProperty("name") &&
                                      itemName.hasOwnProperty("status") ? (
                                        <CustomFormIcon
                                          key={nameIndex}
                                          color={"#222528"}
                                          type="text"
                                          labelText="Shipper Name"
                                          placeholderText="Enter Shipper Name"
                                          value={itemName.name} // Assuming itemName is an object with a 'name' property
                                        />
                                      ) : (
                                        <CustomFormIcon
                                          key={nameIndex}
                                          color={"#222528"}
                                          type="text"
                                          labelText="Shipper Name"
                                          placeholderText="Enter Shipper Name"
                                          value={itemName} // Assuming itemName is an object with a 'name' property
                                        />
                                      )
                                    )}
                              </Col>
                            ))
                          : null}
                        {consineeData.map((item, index) => {

                          return (
                            <Row>
                              {consgineeRiskDataPresent == true ? (
                                <>
                                  <Col md={12}>
                                    {/* <strong>
                                      {item.name[0] &&
                                      item.name.some((item) =>
                                        item.hasOwnProperty("name")
                                      )
                                        ? item.name[0].name
                                        : item.name[0]}
                                    </strong> */}
                                    <div className="my-3">
                                      <span className="rejected fw-normal me-4">
                                        Risks Found
                                      </span>

                                      {item.repound.map((item, index) => {
                                        return (
                                          <div>
                                            {" "}
                                            <span className="rejected me-4"></span>
                                            <span className="rejected me-4">
                                              {item.risk != "" && item.risk}
                                            </span>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </Col>
                                  {item.Owners_name &&
                                  item.Owners_name.length > 0 &&
                                  item.Owners_name[0].hasOwnProperty(
                                    "ShipperRisk"
                                  ) ? (
                                    filteredDataconsignee.map((items, index) => {
                                      return (
                                        <Col md={12}>
                                          ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
                                          <small className="smallText">
                                            First Screening Return Hit
                                          </small>
                                          <Row>
                                            {/* <Col md={6}>
                                              <CustomForm
                                                color={"#222528"}
                                                type="text"
                                                lableText="Shipper Name *"
                                                placeholderText="Enter Shipper Name"
                                                value={
                                                  item.hasOwnProperty(
                                                    "ShipperRisk"
                                                  ) ? (
                                                    item.ShipperRisk
                                                  ) : (
                                                    <></>
                                                  )
                                                }
                                              />
                                            </Col> */}
                                            <Col md={12} lg={6}>
                                              <div className="mb-3">
                                                <span className="form-label">
                                                  Does the shareholder owns{" "}
                                                  {">"} 51%
                                                </span>
                                                {["radio"].map((type) => (
                                                  <div
                                                    key={`inline-${type}`}
                                                    className="radioBx"
                                                  >
                                                    <Form.Check
                                                      inline
                                                      label="Yes"
                                                      name={`group11-${index}`}
                                                      type={type}
                                                      checked={
                                                        items.hasOwnProperty(
                                                          "risk"
                                                        )
                                                          ? items.risk == "yes"
                                                          : ""
                                                      }
                                                      id={`inline-1121`}
                                                    />
                                                    <Form.Check
                                                      inline
                                                      label="No"
                                                      name={`group11-${index}`}
                                                      type={type}
                                                      checked={
                                                        items.hasOwnProperty(
                                                          "risk"
                                                        )
                                                          ? items.risk == "no"
                                                          : ""
                                                      }
                                                      id={`inline-222`}
                                                    />
                                                  </div>
                                                ))}
                                              </div>
                                            </Col>
                                            <Col lg={4}>
                                              <div className="mb-3">
                                                <h3 className="s15">
                                                  Shipper’s Business Bank Name
                                                  Optional for all country
                                                  destinations except for
                                                  <span>
                                                    Russia, Belarus, Iran, Cuba,
                                                    Myanmar, Syria, Sudan, Iraq,
                                                    Libya, Somalia, Lebanon,
                                                    Yemen, Zimbabwe
                                                  </span>
                                                  – if shipment destination
                                                  these countries then Mandatory
                                                </h3>

                                                <span className="form-label">
                                                  Country of destinations to
                                                  these countries?
                                                </span>
                                                {["radio"].map((type) => (
                                                  <div
                                                    key={`inline-${type}`}
                                                    className="radioBx"
                                                  >
                                                    <Form.Check
                                                      inline
                                                      label="Yes"
                                                      name={`group133421-${index}`}
                                                      type={type}
                                                      checked={
                                                        items.hasOwnProperty(
                                                          "bankCheck"
                                                        )
                                                          ? items.bankCheck ==
                                                            "Yes"
                                                          : ""
                                                      }
                                                      id={`inline-111`}
                                                    />
                                                    <Form.Check
                                                      inline
                                                      label="No"
                                                      name={`group133421-${index}`}
                                                      type={type}
                                                      checked={
                                                        items.hasOwnProperty(
                                                          "bankCheck"
                                                        )
                                                          ? items.bankCheck ==
                                                            "No"
                                                          : ""
                                                      }
                                                      id={`inline-22`}
                                                    />
                                                  </div>
                                                ))}
                                              </div>
                                            </Col>
                                            <Col md={6}>
                                              <CustomForm
                                                color={"#222528"}
                                                type="text"
                                                lableText="Shipper Name *"
                                                placeholderText="Enter Shipper Name"
                                                value={
                                                  items.hasOwnProperty(
                                                    "ShipperRisk"
                                                  )
                                                    ? items.ShipperRisk
                                                    : ""
                                                }
                                              />
                                            </Col>
                                            <Col md={6}>
                                              <CustomForm
                                                color={"#222528"}
                                                type="text"
                                                lableText="Bank Name (optional except several countries)   i"
                                                placeholderText="Enter Bank Name"
                                                value={
                                                  item.hasOwnProperty(
                                                    "bankName"
                                                  )
                                                    ? item.bankName
                                                    : ""
                                                }
                                              />
                                            </Col>
                                            <Col md={6}>
                                              <CustomForm
                                                color={"#222528"}
                                                type="text"
                                                lableText="Shipper Address *"
                                                placeholderText="Enter Shipper Address"
                                                value={
                                                  items.hasOwnProperty(
                                                    "Shipper_Owners_Address"
                                                  )
                                                    ? items.Shipper_Owners_Address
                                                    : ""
                                                }
                                              />
                                            </Col>
                                            <Col md={6}>
                                              <CustomForm
                                                color={"#222528"}
                                                type="text"
                                                lableText="Bank Address *"
                                                placeholderText="Enter Bank Address"
                                                value={
                                                  items.hasOwnProperty(
                                                    "bankAddress"
                                                  )
                                                    ? items.bankAddress
                                                    : ""
                                                }
                                              />
                                            </Col>
                                          </Row>
                                          <small className="smallText">
                                            Second Screening Return Hit
                                          </small>
                                          <Row>
                                            <Col md={6}>
                                              <CustomForm
                                                color={"#222528"}
                                                type="text"
                                                lableText="Shipper DOB *"
                                                placeholderText="Enter Shipper DOB"
                                                value={
                                                  items.hasOwnProperty(
                                                    "shipperDOBs"
                                                  )
                                                    ? items.shipperDOBs
                                                    : items.shipperDOB
                                                }
                                              />
                                            </Col>
                                            <Col md={6}>
                                              <CustomFileUpload
                                                color={"#222528"}
                                                type="file"
                                                lableText="Shipper Passport *"
                                                placeholderText="Upload Passport"
                                              />
                                            </Col>
                                          </Row>
                                        </Col>
                                      );
                                    })
                                  ) : (
                                    <Col md={12}>
                                      <small className="smallText">
                                        First Screening Return Hit
                                      </small>
                                      <Row>
                                        <Col md={12} lg={6}>
                                          <div className="mb-3">
                                            <span className="form-label">
                                              Does the shareholder owns {">"}{" "}
                                              51%
                                            </span>
                                            {["radio"].map((type) => (
                                              <div
                                                key={`inline-${type}`}
                                                className="radioBx"
                                              >
                                                <Form.Check
                                                  inline
                                                  label="Yes"
                                                  name={`group134021-${index}`}
                                                  type={type}
                                                  checked={
                                                    consineeData
                                                      ? consineeData[0]
                                                          .shareholder_owns ==
                                                        "Yes"
                                                      : ""
                                                  }
                                                  id={`inline-111`}
                                                />
                                                <Form.Check
                                                  inline
                                                  label="No"
                                                  name={`group134021-${index}`}
                                                  type={type}
                                                  checked={
                                                    consineeData
                                                      ? consineeData[0]
                                                          .shareholder_owns ==
                                                        "No"
                                                      : ""
                                                  }
                                                  id={`inline-22`}
                                                />
                                              </div>
                                            ))}
                                          </div>
                                        </Col>
                                        <Col lg={4}>
                                          <div className="mb-3">
                                            <h3 className="s15">
                                              Shipper’s Business Bank Name
                                              Optional for all country
                                              destinations except for
                                              <span>
                                                Russia, Belarus, Iran, Cuba,
                                                Myanmar, Syria, Sudan, Iraq,
                                                Libya, Somalia, Lebanon, Yemen,
                                                Zimbabwe
                                              </span>
                                              – if shipment destination these
                                              countries then Mandatory
                                            </h3>

                                            <span className="form-label">
                                              Country of destinations to these
                                              countries?
                                            </span>
                                            {["radio"].map((type) => (
                                              <div
                                                key={`inline-${type}`}
                                                className="radioBx"
                                              >
                                                <Form.Check
                                                  inline
                                                  label="Yes"
                                                  name="group1221"
                                                  type={type}
                                                  checked={
                                                    consineeData
                                                      ? consineeData[0]
                                                          .BankType == "Yes"
                                                      : ""
                                                  }
                                                  id={`inline-111`}
                                                />
                                                <Form.Check
                                                  inline
                                                  label="No"
                                                  name="group156"
                                                  type={type}
                                                  checked={
                                                    consineeData
                                                      ? consineeData[0]
                                                          .BankType == "No"
                                                      : ""
                                                  }
                                                  id={`inline-22`}
                                                />
                                              </div>
                                            ))}
                                          </div>
                                        </Col>
                                        <Col md={6}>
                                          <CustomForm
                                            color={"#222528"}
                                            type="text"
                                            lableText="Shipper Name *"
                                            placeholderText="Enter Shipper Name"
                                            value={
                                              consineeData
                                                ? consineeData[0].Owners_name[0]
                                                : ""
                                            }
                                          />
                                        </Col>
                                        <Col md={6}>
                                          <CustomForm
                                            color={"#222528"}
                                            type="text"
                                            lableText="Bank Name (optional except several countries)   i"
                                            placeholderText="Enter Bank Name"
                                            value={
                                              consineeData
                                                ? consineeData[0].bankName
                                                : ""
                                            }
                                          />
                                        </Col>
                                        <Col md={6}>
                                          <CustomForm
                                            color={"#222528"}
                                            type="text"
                                            lableText="Shipper Address *"
                                            placeholderText="Enter Shipper Address"
                                            value={
                                              consineeData
                                                ? consineeData[0].Owners_address
                                                : ""
                                            }
                                          />
                                        </Col>
                                        <Col md={6}>
                                          <CustomForm
                                            color={"#222528"}
                                            type="text"
                                            lableText="Bank Address *"
                                            placeholderText="Enter Bank Address"
                                            value={
                                              consineeData
                                                ? consineeData[0].bankAddress
                                                : ""
                                            }
                                          />
                                        </Col>
                                      </Row>
                                      <small className="smallText">
                                        Second Screening Return Hit
                                      </small>
                                      <Row>
                                        <Col md={6}>
                                          <CustomForm
                                            color={"#222528"}
                                            type="text"
                                            lableText="Shipper DOB *"
                                            placeholderText="Enter Shipper DOB"
                                            value={
                                              consineeData
                                                ? consineeData[0].shipperDOB
                                                : ""
                                            }
                                          />
                                        </Col>
                                        <Col md={6}>
                                          <CustomFileUpload
                                            color={"#222528"}
                                            type="file"
                                            lableText="Shipper Passport *"
                                            placeholderText="Upload Passport"
                                          />
                                        </Col>
                                      </Row>
                                    </Col>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </Row>
                          );
                        })}
                      </Accordion.Body>
                    </Accordion.Item>
                  </>
                ) : (
                  <></>
                )}
                {EndUserData.length > 0 ? (
                  <>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>End User</Accordion.Header>
                      <Accordion.Body>
                        {EndUserData.length > 0
                          ? EndUserData.map((item, index) => (
                              <Col md={12} key={index}>
                                {item.name &&
                                  item.name.length > 0 &&
                                  item.name
                                    .filter(
                                      (filteredItemName) =>
                                        filteredItemName[0]?.hasOwnProperty(
                                          "status"
                                        ) ||
                                        !filteredItemName[0]?.hasOwnProperty(
                                          "name"
                                        )
                                    )
                                    .map((itemName, nameIndex) =>
                                      itemName.hasOwnProperty("name") &&
                                      itemName.hasOwnProperty("status") ? (
                                        <CustomFormIcon
                                          key={nameIndex}
                                          color={"#222528"}
                                          type="text"
                                          labelText="Shipper Name"
                                          placeholderText="Enter Shipper Name"
                                          value={itemName.name} // Assuming itemName is an object with a 'name' property
                                        />
                                      ) : (
                                        <CustomFormIcon
                                          key={nameIndex}
                                          color={"#222528"}
                                          type="text"
                                          labelText="Shipper Name"
                                          placeholderText="Enter Shipper Name"
                                          value={itemName} // Assuming itemName is an object with a 'name' property
                                        />
                                      )
                                    )}
                              </Col>
                            ))
                          : null}
                        {EndUserData.map((item, index) => {

                          return (
                            <Row>
                              {ENdUserriskDataPresent == true ? (
                                <>
                                  <Col md={12}>
                                    {/* <strong>
                                      {item.name[0] &&
                                      item.name.some((item) =>
                                        item.hasOwnProperty("name")
                                      )
                                        ? item.name[0].name
                                        : item.name[0]}
                                    </strong> */}
                                    <div className="my-3">
                                      <span className="rejected fw-normal me-4">
                                        Risks Found
                                      </span>

                                      {item.repound.map((item, index) => {
                                        return (
                                          <div>
                                            <span className="rejected me-4"></span>
                                            <span className="rejected me-4">
                                              {item.risk != "" && item.risk}
                                            </span>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </Col>
                                  {item.Owners_name &&
                                  item.Owners_name.length > 0 &&
                                  item.Owners_name[0].hasOwnProperty(
                                    "ShipperRisk"
                                  )? (
                                    filteredDataEndUserData.map(
                                      (items, index) => {
                                        return (
                                          <Col md={12}>
                                            ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
                                            <small className="smallText">
                                              First Screening Return Hit
                                            </small>
                                            <Row>
                                              {/* <Col md={6}>
                                              <CustomForm
                                                color={"#222528"}
                                                type="text"
                                                lableText="Shipper Name *"
                                                placeholderText="Enter Shipper Name"
                                                value={
                                                  item.hasOwnProperty(
                                                    "ShipperRisk"
                                                  ) ? (
                                                    item.ShipperRisk
                                                  ) : (
                                                    <></>
                                                  )
                                                }
                                              />
                                            </Col> */}
                                              <Col lg={6}>
                                                <div className="mb-3">
                                                  <span className="form-label">
                                                    Does the shareholder owns{" "}
                                                    {">"} 51%
                                                  </span>
                                                  {["radio"].map((type) => (
                                                    <div
                                                      key={`inline-${type}`}
                                                      className="radioBx"
                                                    >
                                                      <Form.Check
                                                        inline
                                                        label="Yes"
                                                        name={`group1531-${index}`}
                                                        type={type}
                                                        checked={
                                                          items.hasOwnProperty(
                                                            "risk"
                                                          ) ? (
                                                            items.risk == "yes"
                                                          ) : (
                                                            <></>
                                                          )
                                                        }
                                                        id={`inline-111`}
                                                      />
                                                      <Form.Check
                                                        inline
                                                        label="No"
                                                        name={`group161-${index}`}
                                                        type={type}
                                                        checked={
                                                          items.hasOwnProperty(
                                                            "risk"
                                                          ) ? (
                                                            items.risk == "no"
                                                          ) : (
                                                            <></>
                                                          )
                                                        }
                                                        id={`inline-22`}
                                                      />
                                                    </div>
                                                  ))}
                                                </div>
                                              </Col>
                                              <Col lg={4}>
                                                <div className="mb-3">
                                                  <h3 className="s15">
                                                    Shipper’s Business Bank Name
                                                    Optional for all country
                                                    destinations except for
                                                    <span>
                                                      Russia, Belarus, Iran,
                                                      Cuba, Myanmar, Syria,
                                                      Sudan, Iraq, Libya,
                                                      Somalia, Lebanon, Yemen,
                                                      Zimbabwe
                                                    </span>
                                                    – if shipment destination
                                                    these countries then
                                                    Mandatory
                                                  </h3>

                                                  <span className="form-label">
                                                    Country of destinations to
                                                    these countries?
                                                  </span>
                                                  {["radio"].map((type) => (
                                                    <div
                                                      key={`inline-${type}`}
                                                      className="radioBx"
                                                    >
                                                      <Form.Check
                                                        inline
                                                        label="Yes"
                                                        name={`group1671-${index}`}
                                                        type={type}
                                                        checked={
                                                          items.hasOwnProperty(
                                                            "bankCheck"
                                                          ) ? (
                                                            items.bankCheck ==
                                                            "Yes"
                                                          ) : (
                                                            <></>
                                                          )
                                                        }
                                                        id={`inline-111`}
                                                      />
                                                      <Form.Check
                                                        inline
                                                        label="No"
                                                        name={`group16781-${index}`}
                                                        type={type}
                                                        checked={
                                                          items.hasOwnProperty(
                                                            "bankCheck"
                                                          )
                                                            ? items.bankCheck ==
                                                              "No"
                                                            : ""
                                                        }
                                                        id={`inline-22`}
                                                      />
                                                    </div>
                                                  ))}
                                                </div>
                                              </Col>
                                              <Col md={6}>
                                                <CustomForm
                                                  color={"#222528"}
                                                  type="text"
                                                  lableText="Shipper Name *"
                                                  placeholderText="Enter Shipper Name"
                                                  value={
                                                    items.hasOwnProperty(
                                                      "ShipperRisk"
                                                    )
                                                      ? items.ShipperRisk
                                                      : ""
                                                  }
                                                />
                                              </Col>
                                              <Col md={6}>
                                                <CustomForm
                                                  color={"#222528"}
                                                  type="text"
                                                  lableText="Bank Name (optional except several countries)   i"
                                                  placeholderText="Enter Bank Name"
                                                  value={
                                                    items.hasOwnProperty(
                                                      "bankName"
                                                    )
                                                      ? items.bankName
                                                      : ""
                                                  }
                                                />
                                              </Col>
                                              <Col md={6}>
                                                <CustomForm
                                                  color={"#222528"}
                                                  type="text"
                                                  lableText="Shipper Address *"
                                                  placeholderText="Enter Shipper Address"
                                                  value={
                                                    items.hasOwnProperty(
                                                      "Shipper_Owners_Address"
                                                    )
                                                      ? items.Shipper_Owners_Address
                                                      : ""
                                                  }
                                                />
                                              </Col>
                                              <Col md={6}>
                                                <CustomForm
                                                  color={"#222528"}
                                                  type="text"
                                                  lableText="Bank Address *"
                                                  placeholderText="Enter Bank Address"
                                                  value={
                                                    items.hasOwnProperty(
                                                      "bankAddress"
                                                    )
                                                      ? items.bankAddress
                                                      : ""
                                                  }
                                                />
                                              </Col>
                                            </Row>
                                            <small className="smallText">
                                              Second Screening Return Hit
                                            </small>
                                            <Row>
                                              <Col md={6}>
                                                <CustomForm
                                                  color={"#222528"}
                                                  type="text"
                                                  lableText="Shipper DOB *"
                                                  placeholderText="Enter Shipper DOB"
                                                  value={
                                                    items.hasOwnProperty(
                                                      "shipperDOBs"
                                                    )
                                                      ? items.shipperDOBs
                                                      : items.shipperDOB
                                                  }
                                                />
                                              </Col>
                                              <Col md={6}>
                                                <CustomFileUpload
                                                  color={"#222528"}
                                                  type="file"
                                                  lableText="Shipper Passport *"
                                                  placeholderText="Upload Passport"
                                                />
                                              </Col>
                                            </Row>
                                          </Col>
                                        );
                                      }
                                    )
                                  ) : (
                                    <Col md={12}>
                                      <small className="smallText">
                                        First Screening Return Hit
                                      </small>
                                      <Row>
                                        <Col lg={6}>
                                          <div className="mb-3">
                                            <span className="form-label">
                                              Does the shareholder owns {">"}{" "}
                                              51%
                                            </span>
                                            {["radio"].map((type) => (
                                              <div
                                                key={`inline-${type}`}
                                                className="radioBx"
                                              >
                                                <Form.Check
                                                  inline
                                                  label="Yes"
                                                  name={`group1091-${index}`}
                                                  type={type}
                                                  checked={
                                                    EndUserData
                                                      ? EndUserData[0]
                                                          .shareholder_owns ==
                                                        "Yes"
                                                      : ""
                                                  }
                                                  id={`inline-111`}
                                                />
                                                <Form.Check
                                                  inline
                                                  label="No"
                                                  name={`group13131-${index}`}
                                                  type={type}
                                                  checked={
                                                    EndUserData
                                                      ? EndUserData[0]
                                                          .shareholder_owns ==
                                                        "No"
                                                      : ""
                                                  }
                                                  id={`inline-22`}
                                                />
                                              </div>
                                            ))}
                                          </div>
                                        </Col>
                                        <Col lg={4}>
                                          <div className="mb-3">
                                            <h3 className="s15">
                                              Shipper’s Business Bank Name
                                              Optional for all country
                                              destinations except for
                                              <span>
                                                Russia, Belarus, Iran, Cuba,
                                                Myanmar, Syria, Sudan, Iraq,
                                                Libya, Somalia, Lebanon, Yemen,
                                                Zimbabwe
                                              </span>
                                              – if shipment destination these
                                              countries then Mandatory
                                            </h3>

                                            <span className="form-label">
                                              Country of destinations to these
                                              countries?
                                            </span>
                                            {["radio"].map((type) => (
                                              <div
                                                key={`inline-${type}`}
                                                className="radioBx"
                                              >
                                                <Form.Check
                                                  inline
                                                  label="Yes"
                                                  name={`group123091-${index}`}
                                                  type={type}
                                                  checked={
                                                    EndUserData
                                                      ? EndUserData[0]
                                                          .BankType == "Yes"
                                                      : ""
                                                  }
                                                  id={`inline-111`}
                                                />
                                                <Form.Check
                                                  inline
                                                  label="No"
                                                  name={`group123091-${index}`}
                                                  type={type}
                                                  checked={
                                                    EndUserData
                                                      ? EndUserData[0]
                                                          .BankType == "No"
                                                      : ""
                                                  }
                                                  id={`inline-22`}
                                                />
                                              </div>
                                            ))}
                                          </div>
                                        </Col>
                                        <Col md={6}>
                                          <CustomForm
                                            color={"#222528"}
                                            type="text"
                                            lableText="Shipper Name *"
                                            placeholderText="Enter Shipper Name"
                                            value={
                                              EndUserData
                                                ? EndUserData[0].Owners_name[0]
                                                : ""
                                            }
                                          />
                                        </Col>
                                        <Col md={6}>
                                          <CustomForm
                                            color={"#222528"}
                                            type="text"
                                            lableText="Bank Name (optional except several countries)   i"
                                            placeholderText="Enter Bank Name"
                                            value={
                                              EndUserData
                                                ? EndUserData[0].bankName
                                                : ""
                                            }
                                          />
                                        </Col>
                                        <Col md={6}>
                                          <CustomForm
                                            color={"#222528"}
                                            type="text"
                                            lableText="Shipper Address *"
                                            placeholderText="Enter Shipper Address"
                                            value={
                                              EndUserData
                                                ? EndUserData[0].Owners_address
                                                : ""
                                            }
                                          />
                                        </Col>
                                        <Col md={6}>
                                          <CustomForm
                                            color={"#222528"}
                                            type="text"
                                            lableText="Bank Address *"
                                            placeholderText="Enter Bank Address"
                                            value={
                                              EndUserData
                                                ? EndUserData[0].bankAddress
                                                : ""
                                            }
                                          />
                                        </Col>
                                      </Row>
                                      <small className="smallText">
                                        Second Screening Return Hit
                                      </small>
                                      <Row>
                                        <Col md={6}>
                                          <CustomForm
                                            color={"#222528"}
                                            type="text"
                                            lableText="Shipper DOB *"
                                            placeholderText="Enter Shipper DOB"
                                            value={
                                              EndUserData
                                                ? EndUserData[0].shipperDOB
                                                : ""
                                            }
                                          />
                                        </Col>
                                        <Col md={6}>
                                          <CustomFileUpload
                                            color={"#222528"}
                                            type="file"
                                            lableText="Shipper Passport *"
                                            placeholderText="Upload Passport"
                                          />
                                        </Col>
                                      </Row>
                                    </Col>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </Row>
                          );
                        })}
                      </Accordion.Body>
                    </Accordion.Item>
                  </>
                ) : (
                  <></>
                )}

                {/* {consineeData ? <> <Accordion.Item eventKey="1">
                  <Accordion.Header>Consignee</Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col md={12}>
                        <CustomFormIcon
                          color={"#222528"}
                          type="text"
                          lableText="Shipper Name"
                          placeholderText="Enter Shipper Name"
                          value={consineeData.name[0]}
                        />
                      </Col>


                      {consgineeRiskDataPresent==true? <>
                        <Col md={12}>
                          <strong>{consineeData.name[0]}</strong>
                          <div className="my-3">
                            <span className="rejected fw-normal me-4">
                              Risks Found
                            </span>

                            {consineeData.repound.map((item, index) => {

                              return (<div> <span className="rejected me-4">

                              </span>
                                <span className="rejected me-4">
                                  {item.risk != '' && item.risk}
                                </span>
                              </div>)


                            })}

                          </div>
                        </Col>
                        <Col md={12}>
                          <small className="smallText">
                            First Screening Return Hit
                          </small>
                          <Row>
                            <Col md={6}>
                              <CustomForm
                                color={"#222528"}
                                type="text"
                                lableText="Shipper Name *"
                                placeholderText="Enter Shipper Name"
                                value={consineeData.Owners_name.length>0&&consineeData.Owners_name[0]}
                              />
                            </Col>
                            <Col md={6}>
                              <CustomForm
                                color={"#222528"}
                                type="text"
                                lableText="Bank Name (optional except several countries)   i"
                                placeholderText="Enter Bank Name"
                                value={consineeData.bankName
                                 &&consineeData.bankName
                                 }
                              />
                            </Col>
                            <Col md={6}>
                              <CustomForm
                                color={"#222528"}
                                type="text"
                                lableText="Shipper Address *"
                                placeholderText="Enter Shipper Address"
                                value={consineeData.Owners_address
                                  &&consineeData.Owners_address 
                                  }
                              />
                            </Col>
                            <Col md={6}>
                              <CustomForm
                                color={"#222528"}
                                type="text"
                                lableText="Bank Address *"
                                placeholderText="Enter Bank Address"
                                value={consineeData.bankAddress

                                  &&consineeData.bankAddress
 
                                  }
                                
                              />
                            </Col>
                          </Row>
                          <small className="smallText">
                            Second Screening Return Hit
                          </small>
                          <Row>
                            <Col md={6}>
                              <CustomForm
                                color={"#222528"}
                                type="text"
                                lableText="Shipper DOB *"
                                placeholderText="Enter Shipper DOB"
                                value={consineeData.shipperDOB


                                  &&consineeData.shipperDOB

 
                                  }
                              />
                            </Col>
                            <Col md={6}>
                              <CustomFileUpload
                                color={"#222528"}
                                type="file"
                                lableText="Shipper Passport *"
                                placeholderText="Upload Passport"
                              />
                            </Col>
                          </Row>
                        </Col></> : <></>
                      }

                    </Row>
                  </Accordion.Body>
                </Accordion.Item></> : <></>}
                {EndUserData ? <> <Accordion.Item eventKey="2">
                  <Accordion.Header>End User</Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col md={12}>
                        <CustomFormIcon
                          color={"#222528"}
                          type="text"
                          lableText="Shipper Name"
                          placeholderText="Enter Shipper Name"
                          value={EndUserData.name[0]}
                        />
                      </Col>


                      {ENdUserriskDataPresent==true ? <>
                        <Col md={12}>
                          <strong>{EndUserData.name[0]}</strong>
                          <div className="my-3">
                            <span className="rejected fw-normal me-4">
                              Risks Found
                            </span>

                            {EndUserData.repound.map((item, index) => {

                              return (<div> <span className="rejected me-4">

                              </span>
                                <span className="rejected me-4">
                                  {item.risk != '' && item.risk}
                                </span>
                              </div>)


                            })}

                          </div>
                        </Col>
                        <Col md={12}>
                          <small className="smallText">
                            First Screening Return Hit
                          </small>
                          <Row>
                            <Col md={6}>
                              <CustomForm
                                color={"#222528"}
                                type="text"
                                lableText="Shipper Name *"
                                placeholderText="Enter Shipper Name"
                                value={EndUserData.Owners_name.length>0&&EndUserData.Owners_name[0]}
                              />
                            </Col>
                            <Col md={6}>
                              <CustomForm
                                color={"#222528"}
                                type="text"
                                lableText="Bank Name (optional except several countries)   i"
                                placeholderText="Enter Bank Name"
                                value={EndUserData.bankName
                                 &&EndUserData.bankName
                                 }
                              />
                            </Col>
                            <Col md={6}>
                              <CustomForm
                                color={"#222528"}
                                type="text"
                                lableText="Shipper Address *"
                                placeholderText="Enter Shipper Address"
                                value={EndUserData.Owners_address
                                  &&EndUserData.Owners_address 
                                  }
                              />
                            </Col>
                            <Col md={6}>
                              <CustomForm
                                color={"#222528"}
                                type="text"
                                lableText="Bank Address *"
                                placeholderText="Enter Bank Address"
                                value={EndUserData.bankAddress

                                  &&EndUserData.bankAddress
 
                                  }
                                
                              />
                            </Col>
                          </Row>
                          <small className="smallText">
                            Second Screening Return Hit
                          </small>
                          <Row>
                            <Col md={6}>
                              <CustomForm
                                color={"#222528"}
                                type="text"
                                lableText="Shipper DOB *"
                                placeholderText="Enter Shipper DOB"
                                value={EndUserData.shipperDOB


                                  &&EndUserData.shipperDOB

 
                                  }
                              />
                            </Col>
                            <Col md={6}>
                              <CustomFileUpload
                                color={"#222528"}
                                type="file"
                                lableText="Shipper Passport *"
                                placeholderText="Upload Passport"
                              />
                            </Col>
                          </Row>
                        </Col></> : <></>
                      }

                    </Row>
                  </Accordion.Body>
                </Accordion.Item></> : <></>} */}

                {/* <Accordion.Item eventKey="1">
                  <Accordion.Header>Consignee</Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col md={12}>
                        <CustomFormIcon
                          color={"#222528"}
                          type="text"
                          lableText="Consignee Name"
                          placeholderText="Enter Consignee Name"
                        />
                      </Col>
                      <Col md={12}>
                        <strong>West Coast Shipping Co.</strong>
                        <div className="my-3">
                          <span className="rejected fw-normal me-4">
                            Risks Found
                          </span>
                          <span className="rejected me-4">
                            UK Sanctioned List
                          </span>
                          <span className="rejected me-4">
                            EU Sanctioned List
                          </span>
                          <span className="rejected me-4">
                            USA Sanctioned List
                          </span>
                          <span className="rejected">UN Sanctioned List</span>
                        </div>
                      </Col>
                      <Col md={12}>
                        <small className="smallText">
                          First Screening Return Hit
                        </small>
                        <Row>
                          <Col md={6}>
                            <CustomForm
                              color={"#222528"}
                              type="text"
                              lableText="Consignee Name (Optional) i"
                              placeholderText="Enter Consignee Name (Optional) i"
                            />
                          </Col>
                          <Col md={6}>
                            <CustomForm
                              color={"#222528"}
                              type="text"
                              lableText="Bank Name (optional except several countries) i"
                              placeholderText="Enter Bank Name"
                            />
                          </Col>
                          <Col md={6}>
                            <CustomForm
                              color={"#222528"}
                              type="text"
                              lableText="Consignee Address *"
                              placeholderText="Enter Consignee Address"
                            />
                          </Col>
                          <Col md={6}>
                            <CustomForm
                              color={"#222528"}
                              type="text"
                              lableText="Bank Address *"
                              placeholderText="Enter Bank Address"
                            />
                          </Col>
                        </Row>
                        <small className="smallText">
                          Second Screening Return Hit
                        </small>
                        <Row>
                          <Col md={6}>
                            <CustomForm
                              color={"#222528"}
                              type="text"
                              lableText="Consignee DOB *"
                              placeholderText="Enter Consignee DOB"
                            />
                          </Col>
                          <Col md={6}>
                            <CustomFileUpload
                              color={"#222528"}
                              type="file"
                              lableText="Consignee Passport *"
                              placeholderText="Upload Passport"
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>End User</Accordion.Header>
                  <Accordion.Body>
                    <Form.Check // prettier-ignore
                      type="switch"
                      id="custom-switch"
                      checked
                      label="End user is different from consignee"
                    />

                    <Row className="mt-3">
                      <Col md={12}>
                        <CustomFormIcon
                          color={"#222528"}
                          type="text"
                          lableText="End User Name"
                          placeholderText="Enter End User Name"
                        />
                      </Col>
                      <Col md={12}>
                        <small className="smallText">
                          Screening Return Hit
                        </small>
                        <Row>
                          <Col md={6}>
                            <CustomForm
                              color={"#222528"}
                              type="text"
                              lableText="End User Name (optional) i"
                              placeholderText="Enter User Name"
                            />
                          </Col>
                          <Col md={6}>
                            <CustomForm
                              color={"#222528"}
                              type="text"
                              lableText="Bank Name (optional except several countries) i"
                              placeholderText="Enter Bank Name"
                            />
                          </Col>
                          <Col md={6}>
                            <CustomForm
                              color={"#222528"}
                              type="text"
                              lableText="End User Address *"
                              placeholderText="Enter User Address"
                            />
                          </Col>
                          <Col md={6}>
                            <CustomForm
                              color={"#222528"}
                              type="text"
                              lableText="Bank Address *"
                              placeholderText="Enter Bank Address"
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item> */}
              </Accordion>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ScreeningOverviewRPS;
