import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
// import Iframe from "react-iframe"
import Form from "react-bootstrap/Form";
import { Icon } from "@iconify/react";
import BreadCrumb from "../customcomponents/BreadCrumb";
import BackgroundImg from "../images/contactbg.jpg";

function ContactUs() {
  return (
    <>
      {/* <Iframe
        url="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=925%20S%20Chugach+(Test)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        width="100%"
        height="400px"
        id=""
        className=""
        display="block"
        position="relative"
      /> */}
      <BreadCrumb
        BackgroundImg={BackgroundImg}
        link={"/"}
        homeTitle={"Home"}
        ActiveLink={"Contact Us"}
        mainTitle={"Contact Us"}
      />
      <section className="py-5 contact_usbox">
        <Container fluid className="widthSmall">
          <Row>
            <Col lg={6} md={6}>
              <h2>Get in Touch</h2>
              <Form>
                <Row>
                  <Col lg={6} md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Name</Form.Label>
                      <Form.Control type="text" placeholder="Enter Name" />
                    </Form.Group>
                  </Col>
                  <Col lg={6} md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Phone</Form.Label>
                      <Form.Control type="text" placeholder="Enter Phone" />
                    </Form.Group>
                  </Col>
                  <Col lg={6} md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Phone</Form.Label>
                      <Form.Control type="email" placeholder="Enter Email" />
                    </Form.Group>
                  </Col>
                  <Col lg={6} md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Subject</Form.Label>
                      <Form.Control type="text" placeholder="Enter Subject" />
                    </Form.Group>
                  </Col>
                  <Col lg={12} md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Message</Form.Label>
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Message"
                        rows={4}
                      />
                    </Form.Group>

                    <Button className="btn btn-primary maxWidthAuto">
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col lg={6} md={6}>
              <h2 className="mobileTop">Contact Us</h2>
              <p>
                Claritas est etiam processus dynamicus, qui sequitur mutationem
                consuetudium lectorum. Mirum est notare quam littera gothica,
                quam nunc putamus parum claram anteposuerit litterarum formas
                human quam nunc putamus parum claram anteposuerit litterarum
                formas human.
              </p>
              <ul>
                <li>
                  <Icon
                    icon="mdi:address-marker"
                    fontSize={18}
                    color="#f7ce46"
                  />{" "}
                  Address : No 40 Baria Sreet 133/2 NewYork City
                </li>
                <li>
                  <Icon
                    icon="ic:baseline-email"
                    fontSize={18}
                    color="#f7ce46"
                  />{" "}
                  E-mail: info@yourdomain.com
                </li>
                <li>
                  <Icon icon="ic:round-phone" color="#f7ce46" fontSize={18} />{" "}
                  +88013245657
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
export default ContactUs;