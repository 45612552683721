import React from "react"
import Form from "react-bootstrap/Form"

const CustomFileUpload = ({
  controlText,
  color,
  lableText,
  placeholderText,
  type,
  value,
  onChange
}) => {
  return (
    <Form.Group className="mb-3" controlId={controlText}>
      <Form.Label style={{ color: color }}>{lableText}</Form.Label>
      <Form.Control
        onChange={onChange}
        value={value}
        placeholder={placeholderText}
        type={type}
      />
    </Form.Group>
  )
}
export default CustomFileUpload
